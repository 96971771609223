.region-filter-intro {
    margin-bottom: 3rem;
}

.region-filter-intro__header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.region-filter-intro__title {
    flex: 1 1 auto;
    margin: 0;
}

.region-filter-intro__image {
    flex: 0 0 4rem;
}

@media (--screen-from-sm) {
    .region-filter-intro__image {
        flex: 0 0 6rem;
    }
}
