*, ::after, ::before {
    box-sizing: border-box;
}

@mixin body-base {
    margin: 0;
    font-family: var(--default-font-family);
    color: var(--default-text-color);
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: var(--font-weight-light);

    -webkit-font-smoothing: antialiased;

    @media (--screen-from-sm) {
        font-size: 1.8rem;
        line-height: 1.6;
    }
}

body {
    @include body-base;
}

/* stylelint-disable plugin/selector-tag-no-without-class */
div, section, article {
    margin: 0;
    padding: 0;
}
/* stylelint-enable plugin/selector-tag-no-without-class */

@media (--screen-from-sm) {
    body {
        font-size: 1.8rem;
        line-height: 1.6;
    }
}
