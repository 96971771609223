@import '../../atoms/button/_reset';

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0;

    border-radius: 1rem;
    background-color: var(--color-white);

    &[open] {
        display: block;
    }
}

.popup__body {
    max-height: 100%;
    margin: auto;

    overflow-y: auto;
}

.popup__header {
    padding: 2rem 5.5rem 2rem 2rem;

    position: relative;

    border-bottom: 1px solid var(--color-grey-5);
}

.popup__title {
    margin: 0;

    color: var(--color-red-1);

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
    line-height: 3rem;
}

.popup__close {
    @extend %button-reset;

    position: absolute;
    right: 2rem;
    top: 2.75rem;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        color: var(--color-grey-4);
    }
}

.popup__content {
    padding: 2rem;
}

.popup__footer {
    padding: 0 2rem 2rem;
}
