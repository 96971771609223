.filter-minmax {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-minmax__label {
    font-size: 1.3rem;
    line-height: 1;
}
input.filter-minmax__input {
    margin: 0 .5rem;
    padding: .8rem;
}

.filter-minmax__button {
    padding: 1rem 1.35rem;
    border-radius: .4rem;
    background-color: var(--color-green-1);
    height: 3.6rem;
    width: 3.6rem;
    color: var(--color-white);

    svg {
        height: 1.6rem;
        width: 1rem;
    }
}
