.products-overview-producer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 2rem;
    background-color: var(--color-grey-10);
    border-radius: 2rem 2rem 0 0;

    &.-first {
        border-radius: 0;
    }
}

.products-overview-producer__title {
    flex: 0 0 auto;
}

.products-overview-producer__title-label {
    display: block;
    color: var(--color-grey-4);
    font-size: 1.2rem;
    line-height: 2rem;
}

.products-overview-producer__name {
    margin: 0;

    font-size: 1.9rem;
    line-height: 2rem;
    color: var(--color-red-background);

    a {
        color: var(--color-red-background);
    }
}

.products-overview-producer__city {
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--color-green-1);
}

.products-overview-producer__types {
    margin-top: 1rem;
}

@media (--screen-from-sm) {
    .products-overview-producer {
        flex-direction: row;
        justify-content: flex-end;

        &.-first {
            padding-top: 0;
        }
    }

    .products-overview-producer__types {
        margin: 0 0 0 1.5rem;
        padding-left: 1rem;
        position: relative;

        &::before {
            content: '';

            border-left: 1px solid var(--color-grey-4);

            position: absolute;
            left: 0;
            top: .7rem;
            bottom: .3rem;
        }
    }
}

