.product-title {}

.product-title__name {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 3.5rem;
    color: var(--color-green-1);
    margin: 0 0 1rem;
}

.product-title__year {
    font-size: 2.8rem;
}

.product-title__subheader, .product-title__subheader a {
    margin: 0 0 .9rem 0;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: var(--color-grey-6);

    > span, a {
        color: var(--color-grey-3);
    }
}

.product-title__article-code {
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    color: var(--color-grey-4);
    margin: 1rem 0;
}

.product-title__professional-reviews {
    white-space: nowrap;
}

@media (--screen-from-md) {
    .product-title__name {
        line-height: 4.5rem;
        font-size: 4rem
    }

    .product-title__article-code {
        margin: 1rem 0 0;
    }

    .product-title__year {
        font-size: 4rem;
    }

    .product-title__subheader {
        margin-bottom: .5rem;
    }
}
