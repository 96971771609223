.products-overview-grid {
    display: grid;
    margin: 0;
    padding: 0;
    list-style: none;
    grid-column-gap: 2rem;
    grid-row-gap: 3.2rem;
}

.products-overview-grid__item {
    margin: 0;
    padding: 0;

    > * {
        height: 100%;
    }
}

@media (--screen-from-sm) {
    .products-overview-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (--screen-from-lg) {
    .products-overview-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
