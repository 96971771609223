.products-overview__filters {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    max-width: 26.5rem;
    z-index: 9998;
    overflow-x: hidden;
    overscroll-behavior: contain;

    background-color: var(--color-white);

    .products-overview.-filter-open & {
        width: 100%;
    }
}

.products-overview__filters-backdrop {
    display: none;
    background: rgba(#46534A, .5); // var(--color-green-1)
    backdrop-filter: blur(2.2rem);

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9997;

    &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4.7rem;

        background-color: var(--color-green-1);
    }

    svg {
        color: var(--color-white);
        width: 1.7rem;
        height: 1.7rem;

        position: absolute;
        right: 1.8rem;
        top: 1.5rem;
    }

    .products-overview.-filter-open & {
        display: block;
    }
}

.products-overview__filters-scroll-lock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;

    overflow-y: scroll;
    overscroll-behavior: contain;
    scrollbar-width: none;

    &::after {
        content: '';
        display: block;
        height: calc(100% + 1px);
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.products-overview__filters-title {
    margin: 0;
    padding: 1.5rem 1.6rem 1.2rem;
    height: 4.6rem;

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-medium);
    line-height: 2rem;
    font-size: 1.4rem;
    letter-spacing: .025rem;
    color: var(--color-grey-3);
}

.products-overview__results {
    flex: 1 1 auto;
    position: relative;

    &:before {
        display: none;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
                        -45deg,
                        rgba(255, 255, 255, .0),
                        rgba(255, 255, 255, .0) 25%,
                        rgba(255, 255, 255, .8) 25%,
                        rgba(255, 255, 255, .8) 50%,
                        rgba(255, 255, 255, .0) 50%,
                        rgba(255, 255, 255, .0) 75%,
                        rgba(255, 255, 255, .8) 75%
        );
        background-size: 100px 100px;
        z-index: 1;
        animation: move .5s infinite linear;
    }

    .products-overview.-loading &:before {
        display: block;
    }
}

.products-overview__filter-toggle {
    margin: 0 0 2rem;
}

@media (--screen-from-md) {
    .products-overview {
        display: flex;
        column-gap: 3rem;
    }

    .products-overview__filters-backdrop {
        display: none;
    }

        .products-overview__filters {
        position: static;
        width: 21.8rem;
        height: auto;
        flex: 0 0 21.8rem;
        background: transparent;
        backdrop-filter: none;
        z-index: inherit;
    }

    .products-overview__filters-title {
        display: none;
    }

    .products-overview__filter-toggle {
        display: none;
    }

    .products-overview__filters-backdrop {
        .products-overview.-filter-open & {
            display: none;
        }
    }
}
