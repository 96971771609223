.product-style {
    background-color: var(--color-white);
    border-top: .1rem solid var(--color-grey-5);
    border-bottom: .1rem solid var(--color-grey-5);
    padding: 6rem 2rem;
}

.product-style__inner {
    max-width: 94rem;
    margin: auto;
}

.product-style__textual {
    margin-bottom: 4.2rem;
}

.product-style__textual-title {
    font-size: 3.4rem;
    line-height: 4rem;
    color: var(--color-red-1);
    margin: 0;
}

.product-style__textual-content {
    font-size: 1.8rem;
    line-height: 3rem;
    color: var(--color-grey-3);
}

.product-style__graphical {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1.7rem;
    grid-auto-rows: 3.3rem;
    row-gap: .3rem;
    align-items: center;
    padding: 0 2rem;
}

.product-style__graphical-name {
    font-size: 1.6rem;
    line-height: 1;
}

.style-chart {
    background-color: var(--color-grey-8);
    height: .5rem;
    border-radius: .3rem;
    overflow: hidden;
}

.style-chart__inner-bar {
    display: block;
    height: 100%;
    background-color: var(--color-red-3);
}

@media  (--screen-from-sm) {
    .product-style__graphical {
        column-gap: 5rem;
    }
}

@media  (--screen-from-md) {
    .product-style__inner {
        display: flex;
        flex-direction: row-reverse;
    }

    .product-style__textual {
        max-width: 28.5rem;
        margin: 0;
    }

    .product-style__graphical {
        flex: 1;
        column-gap: 1.7rem;
        padding-right: 9rem;
    }
}
