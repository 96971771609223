*, ::after, ::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--default-font-family);
  color: var(--default-text-color);
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: var(--font-weight-light);
  -webkit-font-smoothing: antialiased;
}
@media (--screen-from-sm) {
  body {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

/* stylelint-disable plugin/selector-tag-no-without-class */
div, section, article {
  margin: 0;
  padding: 0;
}

/* stylelint-enable plugin/selector-tag-no-without-class */
@media (--screen-from-sm) {
  body {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}
/*
 * Resetting everything to it's initial value,
 * so we don't have to bother handling the original style property-by-property
 *
 * Also we're including the styling for the body, as the context is our pseudo-body
 */
.transition-context, .transition-context:before, .transition-context:after {
  all: initial;
  margin: 0;
  font-family: var(--default-font-family);
  color: var(--default-text-color);
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: var(--font-weight-light);
  -webkit-font-smoothing: antialiased;
}
@media (--screen-from-sm) {
  .transition-context, .transition-context:before, .transition-context:after {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.transition-context {
  display: contents;
}

.transition-context *, .transition-context *:before, .transition-context *:after {
  all: revert;
  box-sizing: border-box;
  line-height: inherit;
}

p {
  max-width: initial;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --font-wotfard: "Wotfard", sans-serif;
  --font-belleza: "Belleza", sans-serif;
  --font-icons: "licatacons";
  --font-weight-normal: 400;
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --default-font-family: var(--font-wotfard);
  --color-black: #000000;
  --color-white-darker: #F1F1F1;
  --color-white: #FFFFFF;
  --color-orange-1: #FF6D00;
  --color-orange-1-opaque: rgba(255, 109, 0, 0.1);
  --color-orange-2: #FF8A33;
  --color-orange-2-opaque: rgba(255, 138, 51, 0.1);
  --color-orange-3: #E56200;
  --color-red: #FF0000;
  --color-red-1: #40171C;
  --color-red-2: #CBBEBB;
  --color-red-3: #6C0E29;
  --color-red-4: #FF5961;
  --color-red-5: #ECEAF8;
  --color-red-6: #D52F34;
  --color-red-7: #EDEBEE;
  --color-red-8: #7D2B3B;
  --color-red-9: #9A0443;
  --color-red-background: #41161D;
  --color-green-1: #46534A;
  --color-green-2: #CED7CA;
  --color-green-3: #37B24D;
  --color-green-4: #E8F4E9;
  --color-green-5: #8F9B11;
  --color-green-6: #626828;
  --color-blue-1: #0E7FD2;
  --color-blue-2: #2A7BE4;
  --color-grey-1: #ECE8E8;
  --color-grey-2: #FAFAFA;
  --color-grey-3: #3A3A3A;
  --color-grey-4: #8A8686;
  --color-grey-5: #E6E6E6;
  --color-grey-6: #C7C9CD;
  --color-grey-7: #9DA8BA;
  --color-grey-8: #EEEAEC;
  --color-grey-9: #AAB2B5;
  --color-grey-10: #F1F1F1;
  --nieuwe-grijs: #EEEEEE;
  --default-text-color: var(--color-grey-3);
  --side-padding: 1.6rem;
  --default-content-width: 94rem;
  --z-index-page: 8900;
  --z-index-masthead-nav: 9000;
  --z-index-masthead: 9001;
  --z-index-modal: 9100;
  --z-index-account-nav: 9500;
  --z-index-places-automcomplete: 10000;
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./open-sans/open-sans-v28-latin-regular.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./open-sans/open-sans-v28-latin-600.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./open-sans/open-sans-v28-latin-700.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("./open-sans/open-sans-v28-latin-italic.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("./open-sans/open-sans-v28-latin-600italic.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-600italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("./open-sans/open-sans-v28-latin-700italic.woff2") format("woff2"), url("./open-sans/open-sans-v28-latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-condensed-700 - latin */
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./open-sans-condensed/open-sans-condensed-latin-700.woff2") format("woff2");
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-regular.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-italic.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-600.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-600italic.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-600italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-700.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("./source-sans/source-sans-pro-v21-latin-700italic.woff2") format("woff2"), url("./source-sans/source-sans-pro-v21-latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-bolditalic-webfont.eot");
  src: url("./wotfard/wotfard-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-bolditalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-bolditalic-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-extralightitalic-webfont.eot");
  src: url("./wotfard/wotfard-extralightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-extralightitalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-extralightitalic-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-lightitalic-webfont.eot");
  src: url("./wotfard/wotfard-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-lightitalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-lightitalic-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-mediumitalic-webfont.eot");
  src: url("./wotfard/wotfard-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-mediumitalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-mediumitalic-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-regularitalic-webfont.eot");
  src: url("./wotfard/wotfard-regularitalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-regularitalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-regularitalic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-semibolditalic-webfont.eot");
  src: url("./wotfard/wotfard-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-semibolditalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-semibolditalic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-thinitalic-webfont.eot");
  src: url("./wotfard/wotfard-thinitalic-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-thinitalic-webfont.woff2") format("woff2"), url("./wotfard/wotfard-thinitalic-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-bold-webfont.eot");
  src: url("./wotfard/wotfard-bold-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-bold-webfont.woff2") format("woff2"), url("./wotfard/wotfard-bold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-extralight-webfont.eot");
  src: url("./wotfard/wotfard-extralight-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-extralight-webfont.woff2") format("woff2"), url("./wotfard/wotfard-extralight-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-light-webfont.eot");
  src: url("./wotfard/wotfard-light-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-light-webfont.woff2") format("woff2"), url("./wotfard/wotfard-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-medium-webfont.eot");
  src: url("./wotfard/wotfard-medium-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-medium-webfont.woff2") format("woff2"), url("./wotfard/wotfard-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-regular-webfont.eot");
  src: url("./wotfard/wotfard-regular-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-regular-webfont.woff2") format("woff2"), url("./wotfard/wotfard-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-semibold-webfont.eot");
  src: url("./wotfard/wotfard-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-semibold-webfont.woff2") format("woff2"), url("./wotfard/wotfard-semibold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Wotfard";
  src: url("./wotfard/wotfard-thin-webfont.eot");
  src: url("./wotfard/wotfard-thin-webfont.eot?#iefix") format("embedded-opentype"), url("./wotfard/wotfard-thin-webfont.woff2") format("woff2"), url("./wotfard/wotfard-thin-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
/* belleza-regular - latin */
@font-face {
  font-family: "Belleza";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./belleza/belleza-v14-latin-regular.woff2") format("woff2"), url("./belleza/belleza-v14-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/**
 * This generates a number of media queries:
 * TODO: Explain this.
 */
@custom-media --screen-from-xs (width >= 0);
@custom-media --screen-up-to-xs (width < 0);
@custom-media --screen-xs-xs (width >= 0) and (width < 0);
@custom-media --screen-xs-sm (width >= 0) and (width < 576px);
@custom-media --screen-xs-md (width >= 0) and (width < 768px);
@custom-media --screen-xs-lg (width >= 0) and (width < 992px);
@custom-media --screen-xs-xl (width >= 0) and (width < 1200px);
@custom-media --screen-xs-xxl (width >= 0) and (width < 1400px);
@custom-media --screen-from-sm (width >= 576px);
@custom-media --screen-up-to-sm (width < 576px);
@custom-media --screen-sm-xs (width >= 576px) and (width < 0);
@custom-media --screen-sm-sm (width >= 576px) and (width < 576px);
@custom-media --screen-sm-md (width >= 576px) and (width < 768px);
@custom-media --screen-sm-lg (width >= 576px) and (width < 992px);
@custom-media --screen-sm-xl (width >= 576px) and (width < 1200px);
@custom-media --screen-sm-xxl (width >= 576px) and (width < 1400px);
@custom-media --screen-from-md (width >= 768px);
@custom-media --screen-up-to-md (width < 768px);
@custom-media --screen-md-xs (width >= 768px) and (width < 0);
@custom-media --screen-md-sm (width >= 768px) and (width < 576px);
@custom-media --screen-md-md (width >= 768px) and (width < 768px);
@custom-media --screen-md-lg (width >= 768px) and (width < 992px);
@custom-media --screen-md-xl (width >= 768px) and (width < 1200px);
@custom-media --screen-md-xxl (width >= 768px) and (width < 1400px);
@custom-media --screen-from-lg (width >= 992px);
@custom-media --screen-up-to-lg (width < 992px);
@custom-media --screen-lg-xs (width >= 992px) and (width < 0);
@custom-media --screen-lg-sm (width >= 992px) and (width < 576px);
@custom-media --screen-lg-md (width >= 992px) and (width < 768px);
@custom-media --screen-lg-lg (width >= 992px) and (width < 992px);
@custom-media --screen-lg-xl (width >= 992px) and (width < 1200px);
@custom-media --screen-lg-xxl (width >= 992px) and (width < 1400px);
@custom-media --screen-from-xl (width >= 1200px);
@custom-media --screen-up-to-xl (width < 1200px);
@custom-media --screen-xl-xs (width >= 1200px) and (width < 0);
@custom-media --screen-xl-sm (width >= 1200px) and (width < 576px);
@custom-media --screen-xl-md (width >= 1200px) and (width < 768px);
@custom-media --screen-xl-lg (width >= 1200px) and (width < 992px);
@custom-media --screen-xl-xl (width >= 1200px) and (width < 1200px);
@custom-media --screen-xl-xxl (width >= 1200px) and (width < 1400px);
@custom-media --screen-from-xxl (width >= 1400px);
@custom-media --screen-up-to-xxl (width < 1400px);
@custom-media --screen-xxl-xs (width >= 1400px) and (width < 0);
@custom-media --screen-xxl-sm (width >= 1400px) and (width < 576px);
@custom-media --screen-xxl-md (width >= 1400px) and (width < 768px);
@custom-media --screen-xxl-lg (width >= 1400px) and (width < 992px);
@custom-media --screen-xxl-xl (width >= 1400px) and (width < 1200px);
@custom-media --screen-xxl-xxl (width >= 1400px) and (width < 1400px);
html {
  font-family: var(--default-font-family);
  font-size: 62.5%;
  min-width: 320px;
}

.gallery-lightbox__close, button, .button {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
  border-radius: 0;
  outline: none;
  font-family: var(--default-font-family);
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}
.gallery-lightbox__close:hover, button:hover, .button:hover, .gallery-lightbox__close:focus, button:focus, .button:focus {
  text-decoration: none;
}

.button {
  --theme: var(--color-orange-1);
  --text-color: var(--color-white);
  --icon-margin: 1.6rem;
  box-sizing: border-box;
  font-family: var(--default-font-family);
  font-size: 1.7rem;
  line-height: 1.9rem;
  font-weight: 300;
  padding: 1.45rem 3rem;
  background-color: var(--theme);
  color: var(--text-color);
  fill: var(--text-color);
  border-radius: 0.4rem;
  outline: var(--theme) solid 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
.button.-icon.-link > .button__icon {
  margin-top: 0.1rem;
}
.button.-icon-only {
  position: relative;
  font-size: 1.8rem;
  padding: 0;
  width: 4.8rem;
  height: 4.8rem;
}
.button.-icon-only > .button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.button.-icon-only.-small {
  width: 2.9rem;
  height: 2.9rem;
}
.button.-icon-only.-large {
  width: 5.5rem;
  height: 5.5rem;
  padding: 1.8rem 3rem;
}
.button.-rounded {
  border-radius: 3rem;
}
.button.-large {
  font-size: 1.9rem;
}
.button.-small {
  font-size: 1.4rem;
  padding: 0.5rem 1.3rem;
  --icon-margin: 1rem;
}
.button.-white {
  --theme: var(--color-white);
  --text-color: var(--color-black);
}
.button.-dark {
  --theme: var(--color-green-1);
  --text-color: var(--color-white);
}
.button.-dark:hover, .button.-dark:focus {
  --theme: var(--color-green-2);
}
.button.-dark:hover.-outline, .button.-dark:focus.-outline {
  --theme: var(--color-green-1);
  background-color: var(--color-green-2);
}
.button.-dark[disabled] {
  background-color: var(--color-grey-4);
}
.button.-red {
  --theme: var(--color-red-1);
  --text-color: var(--color-white);
}
.button.-red:hover, .button.-red:focus {
  --theme: var(--color-red-3);
}
.button.-blue {
  --theme: var(--color-blue-1);
  --text-color: var(--color-white);
}
.button.-red-2 {
  --theme: var(--color-red);
  --text-color: var(--color-red);
}
.button.-red-2:hover, .button.-red-2:focus, .button.-red-2:active {
  --theme: var(--color-red);
  --text-color: var(--color-white);
}
.button.-red-2:hover.-outline, .button.-red-2:focus.-outline, .button.-red-2:active.-outline {
  background-color: var(--color-red);
}
.button.-outline {
  outline-width: 0.1rem;
  outline-offset: -0.1rem;
  --text-color: var(--theme);
  background-color: transparent;
}
.button.-link {
  --text-color: var(--theme);
  --icon-margin: 1rem;
  padding: 0;
  background-color: transparent;
}
.button.-link .button__text {
  text-decoration: none;
}
.button.-link:hover, .button.-link:focus {
  --text-color: var(--color-blue-1);
  background-color: transparent;
}
.button.-link:hover .button__text, .button.-link:focus .button__text {
  text-decoration: underline;
}
.button.-full-width {
  width: 100%;
}
.button:hover, .button:focus {
  --theme: var(--color-orange-3);
  background-color: var(--theme);
  text-underline: none;
}

.button__icon {
  margin-right: var(--icon-margin);
}
.button__icon .svg-icon {
  vertical-align: -0.125em;
  width: 1em;
  height: 1em;
}
.-icon-right > .button__icon {
  margin-right: 0;
  margin-left: var(--icon-margin);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-belleza);
  font-weight: 400;
}

a {
  --anchor-theme: var(--default-text-color);
  text-decoration: underline;
  text-underline-position: under;
  color: var(--anchor-theme);
}
a:hover {
  text-decoration: none;
}
a.-blue {
  --anchor-theme: var(--color-blue-1);
}

svg * {
  fill: currentColor;
}

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

img {
  width: 100%;
}

.wine-labels {
  --size: 1.5rem;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  font-size: calc(var(--size));
  font-weight: 500;
  text-transform: uppercase;
  line-height: var(--size);
  color: var(--color-green-5);
}
.wine-labels.-compact {
  --size: 1.1rem;
}
.wine-labels > *:not(:last-child) {
  margin-right: px-2-rem(5);
}

.wine-labels__item {
  display: flex;
}
.wine-labels__item a {
  color: var(--color-green-5);
  text-decoration: none;
  border: none;
}
.wine-labels__item a:hover {
  color: var(--color-green-1);
}

.wine-labels__type-icon {
  height: var(--size);
  margin-right: 0.3rem;
  width: auto;
  float: left;
}

.wine-labels__info-icon {
  font-size: 1.2rem;
  color: var(--color-black);
  border-bottom: none;
  align-self: center;
  margin-left: 0.5rem;
}

.customer-rating__star {
  --star-full: var(--color-red-3);
  --star-empty: var(--color-red-2);
}
.customer-rating__star.-full {
  color: var(--star-full);
}
.customer-rating__star.-empty {
  color: var(--star-empty);
}

form {
  width: 100%;
  accent-color: var(--color-blue-2);
  --column-gap: 1rem;
}

.form__row {
  display: flex;
  flex-direction: column;
  column-gap: var(--column-gap);
  width: 100%;
}

@media (--screen-from-sm) {
  .form__row {
    flex-direction: row;
  }
  .form__row > * {
    flex-basis: 30rem;
  }
  .form__row.-two > * {
    flex: 0 0 calc(50% - var(--column-gap) / 2);
  }
}
.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.form-widget.-text,
.form-widget.-textarea,
.form-widget.-select {
  font-weight: 300;
  font-family: var(--font-wotfard);
  border: 0.1rem solid var(--color-grey-6);
  border-radius: 0.4rem;
  padding: 0.6rem 1.3rem;
  background-color: var(--color-white);
  margin: 0;
  box-shadow: none;
  height: 3.6rem;
}
.form-widget.-text::placeholder,
.form-widget.-textarea::placeholder,
.form-widget.-select::placeholder {
  color: var(--color-grey-9);
}
.-invalid .form-widget.-text,
.-invalid .form-widget.-textarea,
.-invalid .form-widget.-select {
  border-color: var(--color-red-6);
}

[type=checkbox] {
  vertical-align: text-bottom;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.form-label {
  margin-bottom: 0.9rem;
  line-height: 1.2;
  font-size: 1.4rem;
  font-weight: var(--font-weight-light);
  color: var(--color-black);
}
.-invalid .form-label {
  color: var(--color-red-6);
}

.form-error {
  margin: 0.2rem 0 0;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-red-6);
}

.form-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.form-compound {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.form-compound.-inline {
  column-gap: 1.5rem;
  flex-direction: row;
}

.form-compound__option {
  position: relative;
  padding-left: 2rem;
  line-height: 0;
}
.form-compound__option input[type=radio], .form-compound__option input[type=checkbox] {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0.1rem;
}
.form-compound__option .form-label {
  margin-bottom: 0;
}

select {
  padding: 0 3.2rem 0 1rem;
  appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAgCAQAAACrvNDJAAAAjElEQVR42u3T0RFAMAwG4N8GRugIRrAJo2QSTGYENlAX5cqRNvrEXf+8NG2/twTI+VxK1Oq/FYw/zrDoFahAD4sRFTdmY1ZBHeOamA57E6OecXVAe2plemULGr6kKL0yCzoewlRkYRpkMo2yZ6piT1TJ7lTNZEqaMaY0dqf0ZtEojXlKKett3N7l/CAry+CvLHOHi9gAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-size: 1.4rem 1.6rem;
  background-position: calc(100% - 0.9rem) 50%;
}

textarea {
  padding: 1.6rem 1.3rem;
  width: 100%;
  min-height: 13.7rem;
  box-sizing: border-box;
  appearance: none;
  border: 1px solid var(--color-grey-6);
  border-radius: 0.3rem;
  font-size: 1.6rem;
  line-height: 1.4;
}

dialog {
  border: none;
}
dialog::backdrop {
  background: rgba(70, 83, 74, 0.7);
  backdrop-filter: blur(2rem);
}

hr:not([class]), .hr {
  margin: 5rem 0;
  border-top: 1px solid var(--color-grey-9);
}

.food-recommendations {
  background: var(--color-white) url("./images/background.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left 50% top 0;
  padding: 6rem 2rem;
}

.food-recommendations__inner {
  max-width: 140rem;
  margin: auto;
}

.food-recommendations__text {
  font-size: 1.8rem;
  line-height: 3rem;
}
.food-recommendations__text > h2 {
  color: var(--color-red-1);
  font-size: 4rem;
  line-height: 5rem;
  margin: 0 0 2.3rem;
}
.food-recommendations__text > p {
  clear: left;
}

.food-recommendations__bottle-image {
  max-height: 25rem;
  float: left;
  width: auto;
  margin: 0 20px 20px 0;
}

.food-recommendations__dishes {
  clear: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 3rem;
  justify-content: center;
  margin-top: 3.8rem;
}

.dish {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 0.6rem;
}

.dish__icon-wrapper {
  display: flex;
  width: 9rem;
  height: 9rem;
  background-color: var(--color-red-1);
  color: var(--color-grey-8);
  border-radius: 9rem;
}

.dish__icon {
  object-fit: contain;
  width: 3rem;
  height: 3rem;
  margin: auto;
}

.dish__text {
  font-size: 1.3rem;
  line-height: 1.3;
  color: var(--color-red-3);
  text-transform: uppercase;
}

@media (--screen-from-sm) {
  .food-recommendations__bottle-image {
    max-height: 35rem;
  }

  .food-recommendations__dishes {
    grid-template-columns: repeat(4, 12.2rem);
    gap: 4rem 1.5rem;
  }

  .food-recommendations__text > p {
    clear: none;
  }
}
@media (--screen-from-md) {
  .food-recommendations {
    background-position: left 0 top 0;
  }

  .food-recommendations__inner {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;
  }

  .food-recommendations__text {
    flex: 0 1 56.3rem;
    order: 3;
    margin-top: 8rem;
  }

  .food-recommendations__bottle-image {
    float: none;
    margin: 0;
    max-width: 16.8rem;
    max-height: none;
    flex: 0 0 16.8rem;
    order: 2;
  }

  .food-recommendations__dishes {
    grid-template-columns: repeat(2, 12.8rem);
    justify-content: end;
    gap: 4rem 3rem;
    flex: 0 1 44.8rem;
    order: 1;
    margin-top: 6rem;
    max-width: 45rem;
  }
  .food-recommendations__dishes.-col-1 {
    grid-template-columns: 12.8rem;
  }
}
@media (--screen-from-lg) {
  .food-recommendations__inner {
    gap: 5rem;
  }

  .food-recommendations__dishes {
    grid-template-columns: repeat(3, 12.8rem);
  }
  .food-recommendations__dishes.-col-2 {
    grid-template-columns: repeat(2, 12.8rem);
  }
}
@media (--screen-from-xl) {
  .food-recommendations__text > h2 {
    width: 50%;
  }
}
.product-guarantees {
  font-size: 1.5rem;
  line-height: 1.955rem;
  font-weight: 400;
  text-transform: capitalize;
  border: 0 solid var(--color-grey-5);
  border-top-width: 0.1rem;
}

.product-guarantees__list {
  padding: 0;
}

.product-guarantees__list-item {
  margin: 1.6rem 0;
  padding: 0 0 0 2.9rem;
  color: var(--color-green-1);
  list-style: none;
  position: relative;
}
.product-guarantees__list-item > b {
  font-weight: inherit;
  color: var(--color-green-3);
}

.product-guarantees__list-item-marker {
  width: 1.3rem;
  height: 1.3rem;
  color: var(--color-green-3);
  position: absolute;
  left: 1rem;
  top: 0.125rem;
}

@media (--screen-from-md) {
  .product-guarantees {
    font-size: 1.6rem;
    font-weight: 300;
  }
}
.product-information {
  max-width: calc(var(--default-content-width) + var(--side-padding) * 2);
  margin: auto;
  padding: 6.2rem var(--side-padding);
}

.product-information__toggle-bar {
  border-bottom: 0.1rem solid var(--color-grey-5);
}
.product-information__toggle-bar:first-child {
  border-top: 0.1rem solid var(--color-grey-5);
}

.product-information__title {
  font-size: 2.4rem;
  list-style: none;
  cursor: pointer;
  padding: 1.9rem 0;
  color: var(--color-green-1);
}
.product-information__title::-webkit-details-marker {
  display: none;
}

.product-information__title-inner {
  display: inline-block;
  padding-right: 3rem;
  position: relative;
}
.product-information__title-inner svg {
  width: 1.8rem;
  height: 2.1rem;
  color: var(--color-black);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 200ms ease-in-out;
}
[open] .product-information__title-inner svg {
  transform: translateY(-50%) rotate(180deg);
}

.product-information__content {
  border: 0.1rem solid var(--color-white);
  margin-bottom: 1.9rem;
  border-radius: 0.4rem;
}

.product-information__other {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1.5rem 0;
}

.product-information__other-item {
  margin-bottom: 1rem;
}
.product-information__other-item:last-child {
  margin-bottom: 0;
}

.product-information__temperature-info, .product-information__print {
  font-size: 1.4rem;
  color: var(--color-black);
  text-decoration: none;
}
.product-information__temperature-info span, .product-information__print span {
  text-decoration: underline;
}
.product-information__temperature-info svg, .product-information__print svg {
  color: var(--color-red-8);
  vertical-align: text-bottom;
}
.product-information__temperature-info:hover span, .product-information__temperature-info:focus span, .product-information__temperature-info:active span, .product-information__print:hover span, .product-information__print:focus span, .product-information__print:active span {
  text-decoration: none;
}

.product-specifications {
  width: 100%;
  border-collapse: collapse;
}
.product-specifications tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-red-7);
}
.product-specifications tr > td {
  width: 50%;
  background-color: var(--color-white);
  padding: 1.6rem 1.8rem;
}
.product-specifications tr:nth-child(2n) > td {
  background-color: transparent;
}

.product-specifications__note {
  padding: 1.5rem;
}

@media (--screen-from-sm) {
  .product-information__other {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-information__other-item {
    margin: 0 2rem 0 0;
  }
  .product-information__other-item:last-child {
    margin-right: 0;
  }
}
@media (--screen-from-lg) {
  .product-information {
    padding: 6.2rem 0;
  }
}
.product-order-form {
  display: flex;
  flex-wrap: wrap;
  gap: 0.9rem 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  align-items: center;
}

.product-order-form__text {
  font-size: 1.6rem;
  font-weight: 300;
}

.product-order-form__footer-text {
  font-size: 1.3rem;
  font-weight: 300;
}

.product-order-form__gift-option {
  display: none;
  font-size: 1.6rem;
  font-weight: 300;
  flex-basis: 100%;
}
.product-order-form:not(.-compact) > .product-order-form__gift-option {
  display: initial;
}

.product-order-form__amount-label {
  flex-basis: 100%;
  display: none;
}

.product-order-form__amount-input {
  box-shadow: none;
  box-sizing: content-box;
  text-align: center;
  width: 100%;
  max-width: 4.2rem;
  height: auto;
  margin: 0;
  padding-right: 0.1rem;
  align-self: stretch;
}

.product-order-form__button {
  padding: 1.45rem 3rem;
}

@media not all and (any-hover: hover) {
  .product-order-form__amount-input {
    padding-right: 1.6rem;
  }
}
@media (--screen-xs-sm) {
  .product-order-form__button {
    padding: 1.4rem 1.55rem;
  }
}
@media (--screen-from-md) {
  .product-order-form__button {
    padding: 1.45rem 2rem;
  }
}
@media (--screen-from-lg) {
  .product-order-form__amount-label {
    flex-basis: auto;
    display: block;
  }
}
.product-pricing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.product-pricing__price {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 500;
  color: var(--color-red-3);
  white-space: nowrap;
}
.product-pricing.-compact .product-pricing__price {
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.product-pricing__unit {
  font-size: 1.7rem;
  font-weight: 300;
  color: var(--color-grey-3);
  vertical-align: bottom;
  margin-left: 0.5rem;
}
.product-pricing.-compact .product-pricing__unit {
  font-size: 1.3rem;
}

.product-pricing__discount {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--color-red);
}

.product-pricing__original-price {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--color-grey-4);
  vertical-align: bottom;
  text-decoration: line-through;
}
.product-pricing.-compact .product-pricing__original-price {
  font-size: 1.4rem;
}

.product-pricing__subtitle {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 400;
  margin: 0.5rem 0;
  color: var(--color-white);
  background-color: var(--color-red-4);
  padding: 0.4rem 0.8rem 0.5rem;
  border-radius: 1.1rem;
}
.product-pricing__subtitle.-alt {
  font-weight: 500;
  color: var(--color-red-6);
  background-color: var(--color-red-7);
}

.product-pricing__on-request {
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--color-red-3);
}

.product-detail__producer {
  background-color: var(--color-red-7);
}

.product-producer {
  max-width: 140rem;
  margin: auto;
  padding: 7rem 2rem;
}

.product-producer__media {
  flex: 1;
}
.product-producer__media .gallery-item-video {
  max-width: 100%;
  padding: 0;
}

.product-producer__images-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.product-producer__images-grid > img {
  width: 100%;
}
.product-producer__images-grid > img:first-child {
  grid-column: 1/5;
  grid-row: 1/4;
}
.product-producer__images-grid > img:last-child {
  grid-column: 3/7;
  grid-row: 2/5;
}

.product-producer__text {
  text-align: justify;
}
.product-producer__text > .button {
  display: inline-block;
  margin-top: 3rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}

.product-producer__title {
  text-align: initial;
  font-size: 4rem;
  line-height: 7rem;
  color: var(--color-red-1);
  margin: 1.8rem 0 2.3rem;
}

.product-producer__intro {
  font-size: 2.1rem;
  line-height: 3rem;
  color: var(--color-red-3);
  margin: 2.3rem 0;
}

.product-producer__description {
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--color-grey-3);
}

@media (--screen-from-md) {
  .product-producer__title {
    font-size: 6.5rem;
  }

  .product-producer__media {
    min-width: 40rem;
    flex: 1;
  }
}
@media (--screen-from-lg) {
  .product-producer {
    display: flex;
    gap: 6%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .product-producer__title {
    margin-top: 0;
  }

  .product-producer__media {
    max-width: 78.7rem;
  }

  .product-producer__text {
    max-width: 54rem;
  }
}
.product-reviews {
  color: var(--color-white);
  background-color: var(--color-green-1);
  padding: 6rem 1.5rem;
  background-image: url("image/background.svg");
  background-repeat: no-repeat;
  background-size: auto 87.2rem;
  background-position: top 10rem left 6.5rem;
}

.product-reviews__professionals-header {
  padding: 0 0.5rem;
}

.professionals-header__text {
  grid-area: professional-header-text;
}

.professionals-header__text-title {
  font-size: 4rem;
  margin: 0 0 2.3rem;
}

.professionals-header__text-paragraph {
  font-size: 1.8rem;
  color: var(--color-green-2);
}
.professionals-header__text-paragraph > a {
  --anchor-theme: var(--color-green-2);
}

.professionals-header__badges {
  --row-gap: 1rem;
  --col-gap: 2rem;
  grid-area: professional-header-badges;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: var(--row-gap) var(--col-gap);
  margin-top: 7rem;
}
.professionals-header__badges > * {
  text-align: center;
}

.professional-badge {
  width: 8.4rem;
  height: 8.4rem;
  background-color: var(--color-black);
  border-radius: 14rem;
  margin: 0 auto 1.5rem;
}

.professional-badge__score {
  color: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.professional-badge__score-top {
  font-size: 3.3rem;
  font-weight: 400;
  line-height: 2.3rem;
  margin-bottom: 0.6rem;
  letter-spacing: -0.25rem;
  margin-left: -0.25rem;
}

.professional-badge__score-bottom {
  font-size: 2.3rem;
  line-height: 1.8rem;
  border-top: 0.1rem solid var(--color-white);
  padding-top: 0.3rem;
}

.professional-logo {
  width: 100%;
  max-width: 12rem;
}

.product-reviews__customer-header {
  text-align: center;
}

.customer-header__title {
  font-size: 4rem;
  margin: 0 0 2.3rem;
}

.customer-header__subtitle {
  font-size: 1.8rem;
  color: var(--color-green-2);
}

.product-reviews__reviews {
  margin-top: 4rem;
}

.review-section__desktop-trigger {
  cursor: pointer;
  padding: 0.9rem 2.2rem;
  border-top: 0.4rem solid transparent;
}
.review-section__desktop-trigger.-active-section {
  color: var(--color-green-1);
  background-color: var(--color-white);
  border-top-color: var(--color-green-2);
}
.review-section__desktop-trigger.-active-section > b {
  font-weight: 300;
}

.review-section__desktop-tabs {
  display: none;
}

.review-section__mobile-trigger {
  font-size: 1.8rem;
  width: 100%;
  background-color: var(--color-green-2);
  color: var(--color-green-1);
  padding: 1.5rem 2rem;
  border-top: 0.4rem solid transparent;
}
.review-section__mobile-trigger > b {
  font-weight: 500;
}
.review-section__mobile-trigger.-active-section {
  color: var(--color-red-3);
  background-color: var(--color-white);
  border-top-color: var(--color-red-3);
}
.review-section__mobile-trigger.-active-section > .review-section__chevron {
  color: var(--color-green-1);
  transform: rotate(180deg);
}

.review-section__chevron {
  font-size: 1.9rem;
  margin-left: 2rem;
  transition: transform 300ms linear;
}

.review-section__content {
  color: var(--color-black);
  background-color: var(--color-grey-2);
  max-height: 200rem;
  overflow: auto;
  padding: 3rem 1rem 4rem;
  box-shadow: 0 1.2rem 0.9rem 1rem rgba(65, 88, 65, 0.19), inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.09);
}
.review-section__content.-hidden {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
.review-section__content.-is-animating {
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-property: max-height, padding-top, padding-bottom;
}
.review-section__content > .review {
  padding: 2rem;
}

.review:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey-5);
}
.review > .customer-rating__star {
  font-size: 1.8rem;
}

.professional-review__badge {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: space-around;
}
.professional-review__badge .professional-badge {
  width: 6.4rem;
  height: 6.4rem;
  margin: 0;
}
.professional-review__badge .professional-badge__score-top {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 2.3rem;
  margin-bottom: 0.1rem;
}
.professional-review__badge .professional-badge__score-bottom {
  font-size: 1.8rem;
  line-height: 1.8rem;
  border-top: 0.1rem solid var(--color-white);
  padding: 0 0.4rem;
}

.review__title {
  font-size: 2.4rem;
  line-height: 3.5rem;
  font-family: var(--font-wotfard);
  margin: 0 0 1rem;
  font-weight: 300;
}

.review__rating {
  margin: 1rem 0;
}

.review__content {
  font-size: 1.6rem;
  line-height: 3rem;
  color: var(--color-grey-3);
  margin: 0 0 0.8rem;
}

.review__info {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-grey-4);
}

.review-section__review-button {
  display: block;
  padding: 1.5rem 4rem;
  margin: 3rem auto 0;
}
.review-section__review-button.-icon {
  padding: 1.5rem 3rem;
}

.review-section__customer-title {
  text-align: center;
}
.review-section__customer-title > .customer-rating__star {
  font-size: 1.8rem;
}

.review-section__title-no-reviews {
  font-size: 2.4rem;
  line-height: 4rem;
  color: var(--color-green-1);
  margin-bottom: 1.3rem;
}

.review-dialog {
  position: fixed;
  width: 100%;
  max-width: 80rem;
  height: 100%;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 0 0 auto;
  padding: 0;
  z-index: 9002;
  overflow-y: auto;
}

dialog[open] + .review-dialog__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9001;
  background: rgba(70, 83, 74, 0.7);
  backdrop-filter: blur(2rem);
}

.review-dialog__header {
  display: flex;
  background-color: var(--color-red-1);
  color: var(--color-red-7);
  padding: 2rem;
}
.review-dialog__header > h1 {
  font-size: 2.4rem;
  line-height: 1;
  text-transform: uppercase;
  flex: 1;
  margin: 0;
}

.review-dialog__close {
  cursor: pointer;
}
.review-dialog__close svg {
  width: 2.5rem;
  height: 2.5rem;
}

.review-dialog__subject-product {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  padding: 1rem 1.5rem;
}
.review-dialog__subject-product > img {
  max-width: 4.5rem;
}

.review-dialog__subject-info {
  font-size: 1.9rem;
  color: var(--color-grey-3);
  display: flex;
  flex-direction: column;
}

.review-dialog__subject-title {
  font-weight: 500;
}

.review-dialog__separator {
  border-top: 0;
  border-color: var(--color-red-2);
  margin: 4rem 0 2.5rem;
}

.review-dialog__form-container {
  padding: 0 1.5rem 1.5rem;
}
.review-dialog__form-container .g-recaptcha {
  transform: scale(0.95);
  transform-origin: 0 0;
}
.review-dialog__form-container .g-recaptcha iframe[title=reCAPTCHA] {
  border: none;
  width: 100%;
}
.review-dialog__form-container button[type=submit] {
  --theme: var(--color-red-3);
}
.review-dialog__form-container button[type=submit]:hover {
  --theme: var(--color-red-8);
}

@media (--screen-from-sm) {
  .review.-professional {
    display: flex;
    gap: 1rem;
  }
  .review.-professional .professional-review__badge {
    flex: 0 0 15rem;
  }

  .professionals-header__badges {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .review-section__content {
    padding: 4rem;
  }

  .review-section__review-button.-bottom {
    margin: 3rem 0 0;
  }
}
@media (--screen-from-md) {
  .product-reviews__professionals-header {
    max-width: 140rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "professional-header-badges professional-header-text";
  }

  .review.-professional .professional-review__badge {
    flex: 0 0 20rem;
  }

  .professionals-header__text-title {
    width: 50%;
    font-size: 5rem;
    line-height: 6rem;
  }

  .professionals-header__badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
  }
  .professionals-header__badges > * {
    flex: 0 0 calc(50% - var(--col-gap) / 2);
  }
}
@media (--screen-from-lg) {
  .product-reviews {
    background-size: auto;
    background-position: top 41rem left 20rem;
    padding: 10rem 1.5rem 26rem;
  }

  .product-reviews__reviews {
    padding-top: 11rem;
  }

  .professionals-header__badges {
    padding-right: 6.5rem;
  }
  .professionals-header__badges > * {
    flex: 0 0 calc(25% - 3 * var(--col-gap) / 4);
  }

  .professionals-header__text-title {
    font-size: 6.5rem;
    line-height: 7rem;
  }

  .customer-header__title {
    font-size: 6.5rem;
    line-height: 7rem;
  }

  .review-section {
    max-width: 94rem;
    margin: auto;
  }

  .review-section__mobile-trigger {
    display: none;
  }

  .review-section__desktop-tabs {
    display: flex;
    justify-content: center;
  }

  .review-section__content {
    border-radius: 0.6rem;
  }
}
@media screen and (min-width: 1800px) {
  .product-reviews {
    background-position: top 41rem right 0;
  }
}
.professional-review-badge {
  --size: 5rem;
  padding: 0 var(--size) 0 0;
  gap: 0;
  cursor: default;
  display: flex;
  align-items: center;
  max-width: fit-content;
  width: 0;
  height: var(--size);
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 14rem;
  transition-property: width, gap, padding;
  transition-duration: 300ms, 100ms, 300ms;
  transition-delay: 0ms;
  transition-timing-function: ease-in, ease-out;
  justify-content: space-between;
  position: relative;
}
.professional-review-badge.-large {
  --size: 8.4rem;
}
.professional-review-badge.-has-name:hover {
  width: 100%;
  gap: 2.5rem;
  transition-delay: 0ms, 300ms;
  padding: 0 var(--size) 0 calc(var(--size) / 2);
}
.professional-review-badge:not(.-has-name) {
  padding: 0;
  min-width: var(--size);
  width: 100%;
  justify-content: center;
}
.professional-review-badge:not(.-has-name) > .professional-review-badge__score {
  width: auto;
}

.professional-review-badge__name {
  overflow: hidden;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
}
.professional-review-badge:hover .professional-review-badge__name {
  opacity: 1;
}

.professional-review-badge__score {
  font-weight: 400;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: 2.5rem;
}

.professional-review-badge__score-top {
  font-size: 2.1rem;
  line-height: 2rem;
  letter-spacing: -0.25rem;
  margin-left: -0.25rem;
}
.professional-review-badge.-large .professional-review-badge__score-top {
  font-size: 3.3rem;
}

.professional-review-badge__score-bottom {
  font-size: 1.3rem;
  line-height: 1rem;
  font-weight: 300;
}
.professional-review-badge.-large .professional-review-badge__score-bottom {
  font-size: 2.3rem;
  line-height: 2rem;
}

.product-short-details {
  display: grid;
  grid-template-columns: 4.1rem auto;
  grid-gap: 0.9rem;
  align-items: start;
  padding: 0.9rem 0;
  border: 0 solid var(--color-grey-5);
  border-top-width: 0.1rem;
  border-bottom-width: 0.1rem;
}

.product-short-details__icon-wrapper {
  align-self: flex-start;
  margin: 0.55rem auto 0;
}

.product-short-details__icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-grey-4);
  text-align: center;
  margin: auto;
}

.product-short-details__content {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.95rem;
  color: var(--color-green-1);
  text-transform: capitalize;
}
.product-short-details__content > label {
  display: block;
  font-size: 1.3rem;
  line-height: 1.58rem;
  color: var(--color-grey-4);
}

.grape-type {
  white-space: nowrap;
  line-height: 1.3;
}
.grape-type > a {
  color: var(--color-green-1);
}

@media (--screen-from-md) {
  .product-short-details {
    max-width: 40rem;
  }
}
.product-stock {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: 300;
  gap: 1rem;
}

.product-stock__label {
  display: inline-block;
  padding: 0.5rem 1.3rem;
  margin: 0;
  border-radius: 0.1rem;
  position: relative;
}
.product-stock__label > .svg-icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
  position: absolute;
  top: calc(0.5rem + 0.2em);
  left: 1.3rem;
}
.product-stock__label.-ok {
  padding: 0.5rem 1.3rem 0.5rem 3.4rem;
  color: var(--color-green-3);
  background-color: var(--color-green-4);
}
.product-stock__label.-warning {
  padding: 0.5rem 1.3rem 0.5rem 3.4rem;
  color: var(--color-orange-1);
  background-color: var(--color-orange-2-opaque);
}
.product-stock__label.-error {
  color: var(--color-red);
  background-color: var(--color-grey-1);
}
.product-stock__label.-temp-error {
  color: var(--color-red);
  background-color: var(--color-orange-2-opaque);
}

.product-stock__deliverable-from {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.85rem;
  text-align: center;
}
.product-stock__deliverable-from svg {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 0.2em;
}

.product-style {
  background-color: var(--color-white);
  border-top: 0.1rem solid var(--color-grey-5);
  border-bottom: 0.1rem solid var(--color-grey-5);
  padding: 6rem 2rem;
}

.product-style__inner {
  max-width: 94rem;
  margin: auto;
}

.product-style__textual {
  margin-bottom: 4.2rem;
}

.product-style__textual-title {
  font-size: 3.4rem;
  line-height: 4rem;
  color: var(--color-red-1);
  margin: 0;
}

.product-style__textual-content {
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--color-grey-3);
}

.product-style__graphical {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.7rem;
  grid-auto-rows: 3.3rem;
  row-gap: 0.3rem;
  align-items: center;
  padding: 0 2rem;
}

.product-style__graphical-name {
  font-size: 1.6rem;
  line-height: 1;
}

.style-chart {
  background-color: var(--color-grey-8);
  height: 0.5rem;
  border-radius: 0.3rem;
  overflow: hidden;
}

.style-chart__inner-bar {
  display: block;
  height: 100%;
  background-color: var(--color-red-3);
}

@media (--screen-from-sm) {
  .product-style__graphical {
    column-gap: 5rem;
  }
}
@media (--screen-from-md) {
  .product-style__inner {
    display: flex;
    flex-direction: row-reverse;
  }

  .product-style__textual {
    max-width: 28.5rem;
    margin: 0;
  }

  .product-style__graphical {
    flex: 1;
    column-gap: 1.7rem;
    padding-right: 9rem;
  }
}
.product-title__name {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 3.5rem;
  color: var(--color-green-1);
  margin: 0 0 1rem;
}

.product-title__year {
  font-size: 2.8rem;
}

.product-title__subheader, .product-title__subheader a {
  margin: 0 0 0.9rem 0;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: var(--color-grey-6);
}
.product-title__subheader > span, .product-title__subheader a, .product-title__subheader a > span, .product-title__subheader a a {
  color: var(--color-grey-3);
}

.product-title__article-code {
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  color: var(--color-grey-4);
  margin: 1rem 0;
}

.product-title__professional-reviews {
  white-space: nowrap;
}

@media (--screen-from-md) {
  .product-title__name {
    line-height: 4.5rem;
    font-size: 4rem;
  }

  .product-title__article-code {
    margin: 1rem 0 0;
  }

  .product-title__year {
    font-size: 4rem;
  }

  .product-title__subheader {
    margin-bottom: 0.5rem;
  }
}
.product-years {
  background-color: var(--color-white);
}

.product-years__inner {
  max-width: 94rem;
  margin: auto;
  padding: 4.5rem 0.2rem 6rem;
}

.product-years__title {
  font-size: 2.4rem;
  line-height: 3rem;
  padding: 0 3.3rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-green-1);
  margin: 0 0 2rem;
}

.product-years__product {
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 2rem 0.9rem;
  grid-template-areas: "grid-image grid-name" "grid-image grid-year" "grid-image grid-content" "grid-image grid-price" "grid-image grid-link";
  grid-template-rows: auto auto auto 1fr;
  justify-content: center;
}

.product-years__product-image {
  grid-area: grid-image;
  max-height: 16rem;
}
.product-years__product-image > img {
  width: 5rem;
  margin-right: 1.8rem;
  height: auto;
}

.product-years__product-name {
  grid-area: grid-name;
  font-size: 1.9rem;
}

.product-years__product-subtitle {
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  color: var(--color-grey-4);
}

.product-years__product-year {
  grid-area: grid-year;
  font-size: 1.9rem;
  line-height: 2.4rem;
}

.product-years__product-content {
  grid-area: grid-content;
  font-size: 1.9rem;
  line-height: 2.4rem;
  text-align: center;
}

.product-years__product-price {
  grid-area: grid-price;
  margin: 1rem 0;
  align-self: center;
}
.product-years__product-price .product-price__price {
  font-size: 2rem;
  line-height: 2.8rem;
}
.product-years__product-price .product-price__subtitle {
  margin: 0.3rem 0;
}
.product-years__product-price .product-price__on-request {
  font-size: 1.8rem;
}
.product-years__product-price .product-price__discount {
  font-size: 1.6rem;
}

.product-years__product-link {
  grid-area: grid-link;
  width: fit-content;
}

.product-years__product-separator {
  margin: 0.2rem 0;
  border-style: solid;
  border-color: var(--color-red-2);
  border-bottom-width: 0;
}

.product-years__subscribe-form {
  text-align: center;
  margin-top: 2rem;
}
.product-years__subscribe-form * {
  font-family: var(--font-belleza);
  text-transform: uppercase;
}

.product-years-subscribe-button {
  font-size: 1.4rem;
  line-height: 2.2rem;
  cursor: pointer;
  background-color: var(--color-white-darker);
  border: none;
  border-radius: 6rem;
  padding: 0.1rem 2rem;
}
.product-years-subscribe-button:hover {
  background-color: var(--color-grey-4);
  text-decoration: none;
}
.product-years-subscribe-button:hover > .product-years-subscribe-button__content-text {
  text-decoration: none;
  color: var(--color-white-darker);
}
.product-years-subscribe-button.-is-subscribed {
  background-color: var(--color-green-4);
  padding: 0.4rem 1.2rem;
}
.product-years-subscribe-button.-is-subscribed > .product-years-subscribe-button__content {
  color: var(--color-green-1);
}
.product-years-subscribe-button.-is-subscribed > .product-years-subscribe-button__content > .product-years-subscribe-button__content-text {
  text-decoration: none;
}
.product-years-subscribe-button.-is-subscribed > .product-years-subscribe-button__content > .svg-icon {
  color: var(--color-green-3);
  margin-right: 0.5rem;
}
.product-years-subscribe-button.-is-subscribed > .product-years-subscribe-button__content.-hover > .svg-icon {
  color: var(--color-red-3);
}
.product-years-subscribe-button.-is-subscribed:hover {
  background-color: var(--color-grey-8);
}
.product-years-subscribe-button.-is-subscribed:hover > .product-years-subscribe-button__content.-hover {
  display: initial;
}
.product-years-subscribe-button.-is-subscribed:hover > .product-years-subscribe-button__content:not(.-hover) {
  display: none;
}

.product-years-subscribe-button__content-text {
  text-decoration: underline;
}

.product-years-subscribe-button__content.-hover {
  display: none;
}

@media (--screen-from-sm) {
  .product-years__product {
    column-gap: 2.7rem;
    grid-template-columns: auto auto auto;
    grid-template-areas: "grid-image grid-name grid-link" "grid-image grid-year grid-link" "grid-image grid-content grid-link" "grid-image grid-price grid-link";
    grid-template-rows: auto auto 1fr;
  }

  .product-years__product-price {
    margin-bottom: 0;
  }
}
@media (--screen-sm-md) {
  .product-years__product-image {
    max-height: 13rem;
  }

  .product-years__product-link {
    align-self: center;
  }
}
@media (--screen-from-md) {
  .product-years__inner {
    padding: 6rem 0.2rem;
  }

  .products-year__title {
    line-height: 6.4rem;
    margin: 0;
  }

  .product-years__product {
    padding: 2rem 0;
    grid-template-columns: 4.5rem auto min-content auto 15rem;
    grid-template-areas: "grid-image grid-name grid-year grid-content grid-price grid-link";
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: unset;
  }

  .product-years__product-subtitle {
    line-height: 1rem;
  }

  .product-years__product-image > img {
    width: 100%;
    height: auto;
  }

  .product-years__product-price {
    justify-self: flex-end;
    margin: 0;
  }
  .product-years__product-price .product-price__subtitle {
    margin-left: auto;
  }
}
.related-products {
  --slider-max-width: 144.5rem;
  margin: auto;
  padding: 4rem 2rem 2rem;
  background-color: var(--color-white);
}
.related-products > * {
  max-width: var(--slider-max-width);
}

.related-products__title {
  font-size: 2.1rem;
  line-height: 2.4;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-red-1);
  margin: 0 auto 1.9rem;
}

.related-products__slider {
  margin: 0 auto;
  max-width: 140rem;
}

@media (--screen-from-md) {
  .related-products {
    padding: 5rem 0 6rem;
  }

  .related-products__title {
    font-size: 2.4rem;
    line-height: 1;
    margin: 0 auto 3.9rem;
  }

  .related-products__slider {
    gap: 4rem;
  }
}
.suggested-products {
  --slider-max-width: 116.7rem;
  margin: auto;
  padding: 4rem 2rem 4.5rem;
  background-color: var(--color-red-7);
}
.suggested-products > * {
  max-width: var(--slider-max-width);
}

.suggested-products__title {
  font-size: 2.1rem;
  line-height: 2.4;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-red-3);
  margin: 0 auto 1.9rem;
}

.suggested-products__slider {
  margin: 0 auto;
  max-width: 140rem;
}

@media (--screen-from-md) {
  .suggested-products {
    padding: 5rem 0 6rem;
  }

  .suggested-products__title {
    font-size: 2.4rem;
    line-height: 1;
    margin: 0 auto 3.9rem;
  }

  .suggested-products__slider {
    gap: 3.6rem;
  }
}
.wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--side-padding);
  max-width: none !important;
}

.breadcrumbs {
  margin: 0 auto 1.3rem;
}

.breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumbs__item {
  display: none;
  align-items: center;
  margin: 0;
  padding: 0 0 0 1.5rem;
  position: relative;
}
.breadcrumbs__item svg {
  display: inline-block;
  width: 0.8rem;
  height: 1.6rem;
  position: absolute;
  left: 0;
  top: calc(50% + 0.1rem);
  transform: translateY(-50%) rotate(180deg);
  color: var(--color-grey-4);
}
.breadcrumbs__item.-for-mobile {
  display: block;
}

.breadcrumbs__item__label {
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-regular);
  font-size: 1.6rem;
  line-height: 2.7rem;
  color: var(--color-grey-3);
  text-decoration: none;
}

a.breadcrumbs__item__label {
  text-decoration: underline;
  text-underline-position: auto;
  text-underline-offset: 0.3rem;
}
a.breadcrumbs__item__label:hover, a.breadcrumbs__item__label:focus {
  text-decoration: none;
}

@media (--screen-from-lg) {
  .breadcrumbs {
    margin: 2.3rem 0;
  }

  .breadcrumbs__item {
    display: inline;
    padding: 0;
    margin: 0 0.6rem 0.4rem 0;
  }
  .breadcrumbs__item svg {
    margin-left: 0.6rem;
    vertical-align: -0.2em;
    position: static;
    transform: none;
  }
  .breadcrumbs__item:last-child svg {
    display: none;
    margin-right: 0;
  }
  .breadcrumbs__item.-for-mobile {
    display: inline;
  }
}
.container {
  margin: 0 auto;
  max-width: 94rem;
}
.container.-wide {
  max-width: 131.1rem;
}

.add-to-cart-popup-base__close {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
  border-radius: 0;
  outline: none;
  font-family: var(--default-font-family);
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}
.add-to-cart-popup-base__close:hover, .add-to-cart-popup-base__close:focus {
  text-decoration: none;
}

.add-to-cart-popup-base {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
  width: 100vw;
  height: 100%;
}
.add-to-cart-popup-base.-open {
  display: flex;
}

.add-to-cart-popup-base__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(70, 83, 74, 0.7);
  backdrop-filter: blur(2rem);
}

.add-to-cart-popup-base__inner {
  position: relative;
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: auto;
  display: none;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 100vh;
  background: var(--color-white);
  overflow-y: auto;
}
.add-to-cart-popup-base.-has-content .add-to-cart-popup-base__inner {
  display: flex;
}

.add-to-cart-popup-base__close {
  padding: 1rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.add-to-cart-popup-base__close svg {
  width: 2rem;
  height: 2rem;
  color: var(--color-grey-4);
}

.add-to-cart-popup-base__loader {
  position: relative;
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
}
.add-to-cart-popup-base.-has-content .add-to-cart-popup-base__loader {
  display: none;
}

@media (--screen-from-sm) {
  .add-to-cart-popup-base {
    padding: 0 6rem;
  }

  .add-to-cart-popup-base__inner {
    max-width: 71.6rem;
    border-radius: 0.8rem;
    height: fit-content;
  }
}
.add-to-cart-popup__content {
  margin: 2rem 0;
}

.add-to-cart-popup__header {
  background-color: var(--color-green-4);
  padding: 1rem 3rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.add-to-cart-popup__header.-error {
  background: var(--color-red-7);
}
.add-to-cart-popup__header.-error .add-to-cart-popup__title-text {
  color: var(--color-red-1);
}
.add-to-cart-popup__header.-error .svg-icon.add-to-cart-popup__check-icon {
  fill: var(--color-red-1);
}

.add-to-cart-popup__title {
  display: flex;
  color: var(--color-green-3);
  font-size: 1.7rem;
  line-height: 2rem;
  position: relative;
}

.add-to-cart-popup__title-text {
  flex: 1;
}

.add-to-cart-popup__check-icon {
  position: absolute;
  left: -2rem;
}

.add-to-cart-popup__item {
  display: flex;
  align-items: center;
}

.add-to-cart-popup__item-img {
  display: none;
}

.add-to-cart-popup__item-title {
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--color-grey-3);
  margin-bottom: 0.4rem;
}

.add-to-cart-popup__item-producer {
  font-size: 1.2rem;
  line-height: 1rem;
  color: var(--color-grey-4);
  text-transform: uppercase;
}

.add-to-cart-popup__actions {
  width: 100%;
}
.add-to-cart-popup__actions > .button {
  padding: 1.25rem;
  width: 100%;
}
.add-to-cart-popup__actions > .button:not(:first-child) {
  margin-top: 1rem;
}

.add-to-cart-popup__related {
  padding: 0 2rem;
}

.add-to-cart-popup__related-title {
  font-family: var(--font-wotfard);
  margin: 0 0 1rem;
}

.add-to-cart-popup__related-slider {
  margin: 0;
}

.add-to-cart-popup__packages {
  padding: 0 2rem;
}

.add-to-cart-popup__packages-title {
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-red-3);
}

.add-to-cart-popup__packages-subtitle {
  font-size: 2rem;
  font-weight: 400;
}

.add-to-cart-popup__luigibanner {
  padding: 1.5rem;
  margin: 1.5rem 3rem;
}

.add-to-cart-popup__loader {
  display: flex;
  align-content: center;
  justify-content: center;
}
.add-to-cart-popup__loader img {
  max-height: 25rem;
}

@media (--screen-from-sm) {
  .add-to-cart-popup {
    padding: 0 6rem;
  }

  .add-to-cart-popup__inner {
    max-width: 71.6rem;
    border-radius: 0.8rem;
    height: fit-content;
  }
}
@media (--screen-from-md) {
  .add-to-cart-popup__header {
    grid-template-columns: auto 22.3rem;
    padding: 2rem;
  }

  .add-to-cart-popup__title {
    grid-column: 1/3;
    font-size: 2rem;
    line-height: 1;
    gap: 1rem;
  }

  .add-to-cart-popup__actions {
    max-width: 22.3rem;
  }

  .add-to-cart-popup__item-img {
    display: block;
    width: 5.2rem;
    height: 9.8rem;
  }
  .add-to-cart-popup__item-img img {
    max-width: 6.4rem;
    max-height: 10rem;
  }

  .add-to-cart-popup__item-content {
    margin-left: 2.7rem;
  }

  .add-to-cart-popup__check-icon {
    position: static;
  }
}
.add-to-cart-popup-package {
  margin-top: 2rem;
}

.add-to-cart-popup-package {
  display: grid;
  grid-template-areas: "package-image package-details" "package-image package-order";
  padding: 2rem;
  border: 0.1rem solid var(--color-grey-5);
}

.add-to-cart-popup-package__image-block {
  grid-area: package-image;
  justify-self: center;
  align-self: center;
}
.add-to-cart-popup-package__image-block > img {
  max-width: 4.5rem;
}

.add-to-cart-popup-package__details-block {
  grid-area: package-details;
}

.add-to-cart-popup-package__name {
  font-size: 1.8rem;
}

.add-to-cart-popup-package__details {
  font-size: 1.6rem;
  color: var(--color-grey-4);
}

.add-to-cart-popup-package__order-block {
  grid-area: package-order;
}
.add-to-cart-popup-package__order-block .product-order-form__button {
  --theme: var(--color-red-1);
  font-size: 1.8rem;
  padding: 1.1rem 2.4rem;
}

.add-to-cart-popup-package__price {
  font-size: 1.9rem;
  font-weight: 500;
  color: var(--color-red-3);
}

@media (--screen-from-sm) {
  .add-to-cart-popup-package {
    display: flex;
    gap: 1.2rem;
  }

  .add-to-cart-popup-package__details-block {
    flex: 1;
  }

  .add-to-cart-popup-package__price {
    text-align: right;
  }
}
.popup__close {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
  border-radius: 0;
  outline: none;
  font-family: var(--default-font-family);
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}
.popup__close:hover, .popup__close:focus {
  text-decoration: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  border-radius: 1rem;
  background-color: var(--color-white);
}
.popup[open] {
  display: block;
}

.popup__body {
  max-height: 100%;
  margin: auto;
  overflow-y: auto;
}

.popup__header {
  padding: 2rem 5.5rem 2rem 2rem;
  position: relative;
  border-bottom: 1px solid var(--color-grey-5);
}

.popup__title {
  margin: 0;
  color: var(--color-red-1);
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-medium);
  font-size: 2rem;
  line-height: 3rem;
}

.popup__close {
  position: absolute;
  right: 2rem;
  top: 2.75rem;
}
.popup__close svg {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-grey-4);
}

.popup__content {
  padding: 2rem;
}

.popup__footer {
  padding: 0 2rem 2rem;
}

.loader.-bottle {
  margin-bottom: -20px;
  width: 40px;
  height: 98px;
  display: inline-block;
  position: relative;
  border: 2px solid #fff;
  box-sizing: border-box;
  color: var(--color-red-1);
  border-radius: 20px 20px 4px 4px;
  background: #fff;
  animation: fill 2s linear infinite alternate;
}

.loader.-bottle::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -95%);
  border: 2px solid #FFF;
  border-bottom: none;
  background: #fff;
  width: 15px;
  height: 35px;
  animation: fillNeck 2s linear infinite alternate;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 inset;
  }
  50%, 100% {
    box-shadow: 0 -98px inset;
  }
}
@keyframes fillNeck {
  0%, 50% {
    box-shadow: 0 0 inset;
  }
  100% {
    box-shadow: 0 -20px inset;
  }
}
.loader-container {
  padding: 15px;
  background-color: rgb(70, 84, 58);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter__fieldset {
  margin: 0;
  padding: 0;
}

@media (--screen-from-md) {
  .filter__fieldset {
    margin: 0 0 2rem;
  }
  .filter__fieldset:last-child {
    margin-bottom: 0;
  }
}
.filter-block__title {
  position: relative;
  padding: 1.45rem 1.6rem;
  margin: 0;
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-light);
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-grey-3);
  cursor: pointer;
}
.filter-block__title svg {
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  height: 2.2em;
  width: 1.9rem;
  margin: 0;
  transition: transform 150ms linear;
}
.filter-block__title[aria-expanded=true] {
  background-color: var(--color-red-7);
  color: var(--color-red-3);
}
.filter-block__title[aria-expanded=true] svg {
  transform: translateY(-50%) rotate(180deg);
  color: var(--color-grey-3);
}

.filter-block__content {
  display: block;
  padding: 0 1.6rem 1.2rem;
  margin: 0;
  background-color: var(--color-red-7);
  border-top: 1px solid transparent;
}
.filter-block__content[hidden] {
  display: none;
}
.filter-block.-group > .filter-block__content {
  background-color: transparent;
  padding: 0;
}
.filter-block.-group > .filter-block__content .filter-block {
  margin-left: 2rem;
}

.filter-block__option {
  display: block;
  margin: 0;
  padding: 0.3rem 0 0.3rem 2.4rem;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: var(--color-grey-3);
  cursor: pointer;
}
.filter-block__option .count {
  color: var(--color-grey-4);
}
.filter-block__option[hidden] {
  display: none;
}
.filter-block__option.-disabled {
  color: var(--color-grey-6);
  cursor: initial;
}
.filter-block__option.-disabled .count {
  color: var(--color-grey-6);
}

.filter-block__option-input {
  margin: 0 0 0.1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-grey-7);
  position: absolute;
  left: 0;
  top: 0.3rem;
}

.filter-block__more {
  display: flex;
  margin: 1.2rem 0 0;
  font-weight: var(--font-weight-normal);
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: var(--color-blue-1);
}
.filter-block__more svg {
  margin-right: 0.7rem;
  position: relative;
  top: 0.35rem;
  height: 1.3rem;
  width: 1.1rem;
}
.filter-block__more:hover span, .filter-block__more:focus span, .filter-block__more:active span {
  text-decoration: underline;
}
.filter-block__more.-open svg {
  transform: rotate(180deg);
}

@media (--screen-from-md) {
  .filter-block__title {
    padding: 0 0 1.1rem;
    border-bottom: 0.1rem solid var(--color-grey-5);
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
  }
  .filter-block__title[aria-expanded=true] {
    background-color: transparent;
    color: var(--color-black);
  }
  .filter-block__title svg {
    top: calc(50% - 0.55rem);
    right: 0;
    height: 1.4rem;
    width: 1.2rem;
  }

  .filter-block__content {
    padding: 0;
    margin-top: 1.1rem;
    background-color: transparent;
  }
  .filter-block.-group > .filter-block__content {
    padding-left: 2rem;
    margin-top: 2rem;
  }
  .filter-block.-group > .filter-block__content .filter-block {
    margin-left: 0;
  }
  .filter-block.-alt .filter-block__content {
    margin-top: 0;
  }

  .filter-block.-alt .filter-block__title {
    border-bottom: none;
  }

  .filter-block.-alt .filter-block__option {
    margin-bottom: 0.7rem;
    padding: 0.7rem 0.7rem 0.6rem 3.1rem;
    background-color: var(--color-grey-8);
  }

  .filter-block.-alt .filter-block__option-input {
    left: 0.7rem;
    top: 0.7rem;
  }
}
.filter-minmax {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-minmax__label {
  font-size: 1.3rem;
  line-height: 1;
}

input.filter-minmax__input {
  margin: 0 0.5rem;
  padding: 0.8rem;
}

.filter-minmax__button {
  padding: 1rem 1.35rem;
  border-radius: 0.4rem;
  background-color: var(--color-green-1);
  height: 3.6rem;
  width: 3.6rem;
  color: var(--color-white);
}
.filter-minmax__button svg {
  height: 1.6rem;
  width: 1rem;
}

.filter-layout-options {
  display: flex;
  gap: 1rem;
  position: relative;
}

.filter-layout-options__item {
  display: block;
  cursor: pointer;
}
.filter-layout-options__item:hover > .filter-layout-options__text {
  display: block;
}

.filter-layout-options__text {
  font-size: 1.1rem;
  line-height: 1.7rem;
  color: var(--color-white);
  background-color: var(--color-red-1);
  padding: 0 0.8rem 0.2rem;
  border-radius: 0.3rem;
  position: absolute;
  width: max-content;
  top: -2.1rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.filter-layout-options__text:after {
  content: "";
  position: absolute;
  bottom: -0.6rem;
  border-top: solid 0.9rem var(--color-red-1);
  border-left: solid 0.5rem transparent;
  border-right: solid 0.5rem transparent;
  left: 1.05rem;
}
.filter-layout-options__item:nth-child(2) .filter-layout-options__text:after {
  left: 3.95rem;
}
.filter-layout-options__item:nth-child(3) .filter-layout-options__text:after {
  left: 7.3rem;
}

.filter-layout-options__input {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.filter-layout-options__input:checked ~ .filter-layout-options__icon {
  color: var(--color-blue-1);
}

.filter-layout-options__icon {
  display: block;
  height: 1.6rem;
  width: 2rem;
}

.active-filters-list__item {
  display: inline-block;
  margin-right: 1rem;
  font-weight: var(--font-weight-medium);
  color: var(--color-grey-3);
  font-size: 1.4rem;
  line-height: 1.7rem;
}
.active-filters-list__item:hover span, .active-filters-list__item:focus span, .active-filters-list__item:active span {
  text-decoration: underline;
  text-underline-position: under;
}
.active-filters-list__item svg {
  cursor: pointer;
  width: 1.1rem;
  height: 1.1rem;
  color: var(--color-grey-4);
  position: relative;
  top: 0.05rem;
}

.active-filters-list__clear {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: var(--font-weight-normal);
  color: var(--color-blue-1);
  text-decoration: underline;
  text-underline-position: under;
}

.filter-seo {
  margin-bottom: 2rem;
}

.filter-seo__title {
  font-size: 3rem;
  color: var(--color-green-1);
}

.filter-seo__intro-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: -0.7rem 0 2rem;
}
.filter-seo__intro-text b {
  font-size: 1.5rem;
  font-weight: 500;
}
.filter-seo__intro-text > *:last-child {
  margin: 0;
}

.gallery-base {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.gallery-base__main {
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
  position: relative;
}

.gallery-base__main__items {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-base__main__navigation {
  width: 2.9rem;
  height: 2.9rem;
  border: 1px solid var(--color-red-2);
  border-radius: 50%;
  background-color: var(--color-grey-2);
  color: var(--color-red-1);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.gallery-base__main__navigation svg {
  height: 1.4rem;
  width: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery-base__main__navigation:hover, .gallery-base__main__navigation:active, .gallery-base__main__navigation:focus {
  background-color: var(--color-grey-5);
}
.gallery-base__main__navigation.-prev {
  left: 0;
}
.gallery-base__main__navigation.-prev svg {
  transform: translate(-55%, -50%);
}
.gallery-base__main__navigation.-next {
  right: 0;
}
.gallery-base__main__navigation.-next svg {
  transform: translate(-40%, -50%);
}
.gallery-base.-hide-controls .gallery-base__main__navigation {
  display: none;
}

.gallery-base__thumbnails {
  width: 100%;
  margin-top: 2rem;
}

@media (--screen-from-sm) {
  .gallery-base[data-type-id=main] {
    flex-direction: row-reverse;
  }

  .gallery-base__thumbnails {
    flex: 1;
    margin-top: 0;
  }
}
.gallery-lightbox {
  display: none;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-modal);
  background: rgba(70, 83, 74, 0.7);
  backdrop-filter: blur(2rem);
  overflow: auto;
}
.gallery-lightbox.-open {
  display: flex;
}

.gallery-lightbox__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
  margin: auto;
  width: 55rem;
  height: 85rem;
  max-width: 100%;
  max-height: 100%;
  min-height: 32rem;
  box-sizing: border-box;
  background-color: var(--color-white);
  border-radius: 1rem;
  position: relative;
  font-family: var(--font-asap);
  font-weight: var(--font-weight-semi-bold);
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--color-gray-cool-1);
}

.gallery-lightbox__title {
  flex: 0 0 auto;
  margin: 0 0 1rem;
}

.gallery-lightbox__gallery {
  flex: 0 1 auto;
  min-height: 0;
  height: 100%;
}
.gallery-lightbox__gallery > .gallery-base {
  height: 100%;
}

.gallery-lightbox__close {
  display: block;
  padding: 0.5rem;
  font-size: 2.3rem;
  color: var(--color-grey-4);
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.gallery-lightbox__close svg {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
}

@media (--screen-from-lg) {
  .gallery-lightbox {
    padding: 1.5rem;
  }

  .gallery-lightbox__container {
    padding: 1.5rem;
  }

  .gallery-lightbox__close {
    right: 1rem;
    top: 1rem;
  }
  .gallery-lightbox__close svg {
    width: 2rem;
    height: 2rem;
  }
}
.gallery-plus-tile {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.gallery-plus-tile__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid var(--color-gray-cool-6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gallery-plus-tile__inner span {
  display: block;
  font-family: var(--font-asap);
  font-weight: var(--font-weight-bold);
  font-size: 3.3rem;
  line-height: 1;
  text-align: center;
  color: var(--color-gray-cool-4);
}

.gallery-items {
  display: flex;
  flex-wrap: nowrap;
  width: 5000%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform 0.3s ease;
}

.gallery-items__item {
  display: flex;
  width: 2%;
  flex-shrink: 0;
  flex-grow: 0;
}
.gallery-items.-fit-screen .gallery-items__item {
  height: calc(100vh - 30rem);
  min-height: 35rem;
  max-height: 64rem;
}

@media (--screen-from-md) {
  .gallery-items.-fit-screen .gallery-items__item {
    height: auto;
    min-height: 0;
  }
}
.gallery-item-image {
  display: flex;
  margin: 0 1.45rem;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-grey-5);
  cursor: pointer;
}

.gallery-item-image__inner {
  display: flex;
  margin: auto;
  width: auto;
  height: 100%;
  max-width: 29.5rem;
}

.gallery-item-image__inner__image {
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  cursor: zoom-in;
}

@media (--screen-from-lg) {
  .gallery-item-image__inner__image {
    max-height: 60rem;
  }
}
.gallery-item-video {
  width: 100%;
  padding: 2%;
  max-width: 59rem;
  margin: auto;
  box-sizing: border-box;
}
.gallery-item-video.-lightbox {
  height: 100%;
  max-width: 100%;
}

.gallery-item-video__container {
  width: 100%;
  height: 100%;
}

.gallery-item-video__inner {
  margin: auto;
  width: 100%;
  height: 100%;
}

.gallery-item-video__ratio {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  cursor: pointer;
}

.gallery-item-video__placeholder {
  object-fit: cover;
}

.gallery-item-video__iframe, .gallery-item-video__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-item-video__iframe {
  display: none;
}
.gallery-item-video.-player-loaded .gallery-item-video__iframe {
  display: block;
}

.gallery-item-video.-player-loaded .gallery-item-video__placeholder {
  visibility: hidden;
}

.gallery-item-video__icon {
  display: block;
  width: 5rem;
  height: 5rem;
  content: "";
  color: var(--color-white);
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  transition: background-color 0.2s ease;
}
.gallery-item-video__icon svg {
  display: block;
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-44%, -47%);
  color: var(--color-blue-light-1);
}
.gallery-item-video.-player-loaded .gallery-item-video__icon {
  display: none;
}
.gallery-item-video__inner:hover .gallery-item-video__icon, .gallery-item-video__inner:focus .gallery-item-video__icon, .gallery-item-video__inner:active .gallery-item-video__icon {
  background-color: var(--color-blue-light-4);
}

.gallery-item-video__not_permitted {
  text-align: center;
  overflow: visible;
  position: absolute;
  padding: 15px;
  background-color: var(--color-red-2);
  color: var(--color-red-1);
  /* margin: 75px; */
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 15px;
  bottom: 15%;
  font-size: 15px;
}

div[data-permitted-container] {
  display: none;
}

div[data-blocked-container] {
  display: none;
}

.gallery-item-zoomable {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  touch-action: none;
}
.gallery-item-zoomable.-zoomed::after {
  background-image: url("../images/zoom-out.png");
}
.gallery-item-zoomable::after {
  display: none;
  width: 2.5rem;
  height: 2.5rem;
  content: "";
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 1;
  background-image: url("../images/zoom-in.png");
  background-size: 100%;
}
.gallery-item-zoomable.-zoomable {
  cursor: zoom-in;
}
.gallery-item-zoomable.-zoomable.-zoomed {
  cursor: zoom-out;
}
.gallery-item-zoomable.-zoomable::after {
  display: block;
}

.gallery-item-zoomable__content {
  width: auto;
  height: auto;
  max-width: 98%;
  max-height: 98%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  -webkit-touch-callout: none;
}
.gallery-item-zoomable.-zoomed .gallery-item-zoomable__content {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  max-width: none;
  max-height: none;
}

.gallery-thumbnail-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.gallery-thumbnail-list__items {
  --thumbs-gap: .6rem;
  display: flex;
  gap: var(--thumbs-gap);
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform 0.3s ease;
}

.gallery-thumbnail-list__items__item {
  width: 9.7rem;
  height: 9.7rem;
  box-sizing: border-box;
  padding: 0.5rem;
  flex-shrink: 0;
  border: 0.1rem solid var(--color-grey-5);
  border-radius: 0.5rem;
  background-color: var(--color-white);
  transition: border-color 0.2s ease;
  position: relative;
}
.gallery-thumbnail-list__items__item:hover {
  cursor: pointer;
}
.gallery-thumbnail-list.-fit-items .gallery-thumbnail-list__items__item {
  --items-shown: 5;
  --gap-sum: calc(var(--thumbs-gap) * (var(--items-shown) - 1));
  width: calc((100% - var(--gap-sum)) / var(--items-shown));
}
.gallery-thumbnail-list__items__item.-active:not(.-overflow-info) {
  border-color: var(--color-red-8);
}
.gallery-thumbnail-list__items__item.-overflow-info::after {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "+" attr(data-remaining);
  border-radius: 0.5rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(33, 41, 52, 0.7);
  font-family: var(--font-belleza);
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--color-white);
}

@media (--screen-from-sm) {
  .gallery-thumbnail-list {
    max-height: 60rem;
  }

  .gallery-thumbnail-list__items {
    --thumbs-gap: .8rem;
  }
  .gallery-base[data-type-id=main] .gallery-thumbnail-list__items {
    flex-direction: column;
    width: 6.7rem;
  }

  .gallery-thumbnail-list__items__item.-overflow-info::after {
    font-size: 1.8rem;
  }
  .gallery-thumbnail-list.-fit-items .gallery-thumbnail-list__items__item {
    --items-shown: 6;
    height: calc((60rem - var(--gap-sum)) / var(--items-shown));
    width: auto;
  }
}
.gallery-thumbnail-image {
  position: relative;
  height: 100%;
  width: 100%;
}

.gallery-thumbnail-image__image {
  display: block;
  margin: auto;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gallery-thumbnail-video {
  height: 100%;
  width: 100%;
  position: relative;
}

.gallery-thumbnail-video__icon {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  content: "";
  border-radius: 50%;
  background-color: var(--color-blue-light-5);
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
}
.gallery-thumbnail-video__icon svg {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-44%, -49%);
  color: var(--color-blue-light-1);
}

.pagination {
  font-size: 1.5rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 3.8rem;
  width: 100%;
  margin-top: 2rem;
  color: var(--color-green-1);
}
.pagination a {
  color: var(--color-green-1);
  text-decoration: none;
}
.pagination a[href]:hover, .pagination a[href]:focus, .pagination a[href]:active {
  border-color: var(--color-green-1);
  background-color: var(--color-grey-8);
  z-index: 1;
}

.pagination__summary {
  width: auto;
  padding: 0 0.5rem;
  margin: 0;
  align-self: center;
  text-align: center;
  font-size: 1.5rem;
}
.pagination__summary span {
  color: var(--color-green-1);
}

.pagination__list {
  display: none;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
}

.pagination__previous, .pagination__next {
  display: flex;
  align-items: center;
  padding: 0 1.3rem;
  border: 0.1rem solid var(--color-grey-6);
  border-radius: 0.4rem;
}
.pagination__previous .svg-icon, .pagination__next .svg-icon {
  margin: auto;
  font-size: 1.3rem;
  flex: 1 1 1.3rem;
}
.pagination__previous span, .pagination__next span {
  flex: 1 0 auto;
}

.pagination__previous {
  padding: 0 1.3rem 0 1.2rem;
  border-radius: 0.4rem 0 0 0.4rem;
}
.pagination__previous .svg-icon {
  margin-right: 1.5rem;
}

.pagination__next {
  padding: 0 1.2rem 0 1.3rem;
  margin-left: -0.1rem;
  border-radius: 0 0.4rem 0.4rem 0;
}
.pagination__next .svg-icon {
  margin-left: 1.5rem;
  transform: scaleX(-1);
}

.pagination-item {
  display: flex;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pagination-item__link, .pagination-item__spacer {
  display: inline-block;
  box-sizing: border-box;
  margin-left: -0.1rem;
  width: 4rem;
  text-align: center;
  line-height: 3.5rem;
  border: 0.1rem solid var(--color-grey-6);
}
.pagination-item.-current .pagination-item__link, .pagination-item.-current .pagination-item__spacer {
  background-color: var(--color-green-2);
  border-color: var(--color-green-6);
  z-index: 2;
}

@media (--screen-from-md) {
  .pagination {
    width: auto;
    margin: 2rem auto 0;
    justify-content: center;
  }

  .pagination__summary {
    display: none;
  }

  .pagination__list {
    display: flex;
  }

  .pagination__previous, .pagination__next {
    width: auto;
  }
  .pagination__previous span, .pagination__next span {
    display: none;
  }
  .pagination__previous .svg-icon, .pagination__next .svg-icon {
    margin: 0;
  }
  .pagination__previous:not([href]), .pagination__next:not([href]) {
    display: none;
  }
}
.products-overview__filters {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  max-width: 26.5rem;
  z-index: 9998;
  overflow-x: hidden;
  overscroll-behavior: contain;
  background-color: var(--color-white);
}
.products-overview.-filter-open .products-overview__filters {
  width: 100%;
}

.products-overview__filters-backdrop {
  display: none;
  background: rgba(70, 83, 74, 0.5);
  backdrop-filter: blur(2.2rem);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9997;
}
.products-overview__filters-backdrop:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4.7rem;
  background-color: var(--color-green-1);
}
.products-overview__filters-backdrop svg {
  color: var(--color-white);
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  right: 1.8rem;
  top: 1.5rem;
}
.products-overview.-filter-open .products-overview__filters-backdrop {
  display: block;
}

.products-overview__filters-scroll-lock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  overflow-y: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;
}
.products-overview__filters-scroll-lock::after {
  content: "";
  display: block;
  height: calc(100% + 1px);
}
.products-overview__filters-scroll-lock::-webkit-scrollbar {
  display: none;
}

.products-overview__filters-title {
  margin: 0;
  padding: 1.5rem 1.6rem 1.2rem;
  height: 4.6rem;
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-medium);
  line-height: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.025rem;
  color: var(--color-grey-3);
}

.products-overview__results {
  flex: 1 1 auto;
  position: relative;
}
.products-overview__results:before {
  display: none;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0.8) 75%);
  background-size: 100px 100px;
  z-index: 1;
  animation: move 0.5s infinite linear;
}
.products-overview.-loading .products-overview__results:before {
  display: block;
}

.products-overview__filter-toggle {
  margin: 0 0 2rem;
}

@media (--screen-from-md) {
  .products-overview {
    display: flex;
    column-gap: 3rem;
  }

  .products-overview__filters-backdrop {
    display: none;
  }

  .products-overview__filters {
    position: static;
    width: 21.8rem;
    height: auto;
    flex: 0 0 21.8rem;
    background: transparent;
    backdrop-filter: none;
    z-index: inherit;
  }

  .products-overview__filters-title {
    display: none;
  }

  .products-overview__filter-toggle {
    display: none;
  }

  .products-overview.-filter-open .products-overview__filters-backdrop {
    display: none;
  }
}
.products-overview-grid {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  grid-column-gap: 2rem;
  grid-row-gap: 3.2rem;
}

.products-overview-grid__item {
  margin: 0;
  padding: 0;
}
.products-overview-grid__item > * {
  height: 100%;
}

@media (--screen-from-sm) {
  .products-overview-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--screen-from-lg) {
  .products-overview-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.products-overview-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.products-overview-list__item {
  width: 100%;
  margin: 0 0 2rem;
}
.products-overview-list__item:last-child {
  margin-bottom: 0;
}

.products-overview-grouped__producer {
  margin-top: 2rem;
}
.products-overview-grouped__producer.-first {
  margin-top: 0;
}

.products-overview-grouped__products {
  margin-bottom: 2rem;
}

.filter-results .banner {
  margin: 2.5rem 0;
}

.filter-results__settings {
  display: grid;
  grid-template-areas: "settings-count settings-layout";
  grid-template-columns: 1fr auto;
  gap: 2.5rem 1rem;
  padding-bottom: 2.2rem;
  margin: 0 0 2.2rem;
  border-bottom: solid 0.1rem var(--color-grey-5);
}
.filter-results.-has-sort .filter-results__settings {
  grid-template-areas: "settings-count settings-layout" "settings-sorting settings-sorting";
}

.filter-results__count {
  font-size: 1.9rem;
  font-weight: 400;
  color: var(--color-red-1);
  grid-area: settings-count;
  margin: 0;
  flex: 1 0 auto;
}

.filter-results__layout-options {
  grid-area: settings-layout;
}

.filter-results__active-filters {
  margin-bottom: 2rem;
}

.results-sorting {
  grid-area: settings-sorting;
}

.results-sorting__label {
  display: inline-block;
  font-size: 1.3rem;
  margin: 0;
}

.results-sorting__select {
  width: 100%;
  min-width: 18rem;
}

@media (--screen-from-sm) {
  .filter-results .banner {
    height: auto;
    min-height: 5.4rem;
  }
  .filter-results .banner__image {
    max-width: 15rem;
    max-height: 4.5rem;
  }

  .filter-results__settings {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .filter-results.-has-sort .filter-results__settings {
    padding-top: 2.5rem;
  }

  .results-sorting__select {
    width: auto;
  }
}
@media (--screen-xs-lg) {
  .results-sorting {
    position: relative;
  }

  .results-sorting__label {
    position: absolute;
    top: -2.2rem;
  }
}
.simple-order-block__form {
  display: flex;
}

input.simple-order-block__amount-input {
  width: 7.9rem;
  height: 3.8rem;
  margin-right: 1rem;
  margin-bottom: 0;
  text-align: center;
  appearance: textfield;
}
input.simple-order-block__amount-input::-webkit-outer-spin-button, input.simple-order-block__amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.simple-order-block__button {
  padding: 0.975rem 1.95rem;
  height: 3.8rem;
  line-height: 0;
  color: var(--color-white);
  background-color: var(--color-red-1);
  transition: background-color 0.2s linear;
  border-radius: 0.4rem;
}
.simple-order-block__button svg {
  width: 2.1rem;
  height: 1.85rem;
}
.simple-order-block__button:hover, .simple-order-block__button:focus, .simple-order-block__button:active {
  background-color: var(--color-red-3);
}

.simple-order-block__text {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.product-search-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.7rem 1rem 3rem;
  border: 1px solid var(--color-grey-8);
  word-break: break-word;
  background-color: var(--color-white);
  position: relative;
}

.product-search-card__label {
  padding: 0.4rem 0.6rem;
  font-family: var(--font-belleza);
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: var(--font-weight-normal);
  letter-spacing: 0.1rem;
  color: var(--color-red-7);
  text-transform: uppercase;
  background-color: var(--color-red-3);
  border-radius: 0.3rem;
  position: absolute;
  left: 1.3rem;
  top: 1.7rem;
}
.product-search-card.-superpromo .product-search-card__label {
  background-color: var(--color-green-1);
}

.product-search-card__reviews {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 1.3rem;
  top: 1.7rem;
}

.product-search-card__review-item {
  margin: 0 0 0.2rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.03rem;
  color: var(--color-green-1);
  text-align: right;
}
.product-search-card__review-item abbr {
  border: none;
  text-decoration: none;
}
.product-search-card__review-item:last-child {
  margin-bottom: 0;
}

.product-search-card__review-score {
  display: inline-block;
  margin-left: 0.2rem;
  color: var(--color-grey-3);
  font-weight: var(--font-weight-normal);
}

.product-search-card__anchor {
  text-decoration: none;
}

.product-search-card__image {
  margin: 0 auto 1.4rem;
  max-width: 14.9rem;
}

.product-search-card__title, .product-search-card__subtitle, .product-search-card__subtitle a {
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-light);
  text-align: center;
  font-size: 1.7rem;
  line-height: 2.1rem;
  color: var(--color-black);
}

.product-search-card__title {
  margin: 0.7rem 0 0;
}
.product-search-card__title .product-search-card__title-year {
  font-size: 1.7rem;
  line-height: 2.1rem;
  font-weight: var(--font-weight-medium);
}

.product-search-card__subtitle, .product-search-card__subtitle a {
  margin: 0.7rem 0 0;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--color-grey-4);
  letter-spacing: -0.03rem;
}

.product-search-card__labels {
  display: flex;
  justify-content: center;
  margin: 0.7rem 0 0;
}

.product-search-card__footer {
  padding-top: 2rem;
  margin-top: auto;
}

.product-search-card__pricing {
  display: flex;
  justify-content: center;
}

.product-search-card__order {
  display: flex;
  justify-content: center;
  margin: 1.1rem 0 0;
}

.product-search-card__stock {
  display: flex;
  justify-content: center;
  margin: 1.1rem 0 0;
}

.product-search-card-extended {
  display: grid;
  grid-template-areas: "psce-image psce-body" "psce-footer psce-footer";
  grid-column-gap: 1rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 9.5rem 1fr;
  width: 100%;
  padding: 1.7rem 0.5rem 3rem;
  border: 1px solid var(--color-grey-8);
  word-break: break-word;
  background-color: var(--color-white);
  position: relative;
}

.product-search-card-extended__label {
  padding: 0.4rem 0.6rem;
  font-family: var(--font-belleza);
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: var(--font-weight-normal);
  letter-spacing: 0.1rem;
  color: var(--color-red-7);
  text-transform: uppercase;
  background-color: var(--color-red-3);
  border-radius: 0.3rem;
  position: absolute;
  left: 1.3rem;
  top: 1.7rem;
}
.product-search-card.-superpromo .product-search-card-extended__label {
  background-color: var(--color-green-1);
}

.product-search-card-extended__reviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.3rem 0 0;
  padding: 0;
  list-style: none;
}

.product-search-card-extended__review-item {
  margin: 0 0.5rem 0.2rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.03rem;
  color: var(--color-green-1);
  text-align: right;
}
.product-search-card-extended__review-item abbr {
  border: none;
  text-decoration: none;
}
.product-search-card-extended__review-item:last-child {
  margin-bottom: 0;
}

.product-search-card-extended__review-score {
  display: inline-block;
  margin-left: 0.2rem;
  color: var(--color-grey-3);
  font-weight: var(--font-weight-normal);
}

.product-search-card-extended__anchor {
  text-decoration: none;
}

.product-search-card-extended__top {
  grid-area: psce-image;
  margin: 0 auto 1.4rem;
  max-width: 14.9rem;
}

.product-search-card-extended__body {
  grid-area: psce-body;
}

.product-search-card-extended__title, .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-light);
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black);
}

.product-search-card-extended__title {
  margin: 0.7rem 0 0;
}
.product-search-card-extended__title .product-search-card-extended__title-year {
  font-size: 1.7rem;
  line-height: 2.1rem;
  font-weight: var(--font-weight-medium);
}

.product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
  margin: 0.7rem 0 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--color-grey-4);
  letter-spacing: -0.03rem;
}

.product-search-card-extended__labels {
  display: flex;
  justify-content: flex-start;
  margin: 0.7rem 0 0;
}
.product-search-card-extended__labels > .wine-labels {
  justify-content: flex-start;
}

.product-search-card-extended__info-toggle {
  display: inline-block;
  margin: 1.1rem 0 0;
  font-weight: var(--font-weight-light);
  color: var(--color-blue-1);
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.product-search-card-extended__short-description {
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: var(--color-grey-4);
}

.product-search-card-extended__short-details {
  margin: 1.1rem 0 0;
  padding-top: 0.5rem;
  border-top: 1px solid var(--color-grey-5);
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--color-green-1);
}

.product-search-card-extended__short-detail-item {
  margin-bottom: 0.5rem;
}
.product-search-card-extended__short-detail-item label {
  color: var(--color-grey-4);
}
.product-search-card-extended__short-detail-item:last-child {
  margin-bottom: 0;
}

.product-search-card-extended__footer {
  grid-area: psce-footer;
  padding-top: 2rem;
  margin-top: auto;
}

.product-search-card-extended__pricing {
  display: flex;
  justify-content: center;
}

.product-search-card-extended__order {
  display: flex;
  justify-content: center;
  margin: 1.1rem 0 0;
}

.product-search-card-extended__stock {
  display: flex;
  justify-content: center;
  margin: 1.1rem 0 0;
}

@media (--screen-from-sm) {
  .product-search-card-extended {
    display: grid;
    grid-template-columns: 13.8rem;
    grid-column-gap: 1rem;
    grid-row-gap: 2.5rem;
    grid-template-areas: "psce-image psce-body" "psce-image psce-footer";
    grid-template-rows: min-content 1fr;
    align-items: flex-start;
    padding: 2.1rem 1rem;
  }

  .product-search-card-extended__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .product-search-card-extended__image {
    display: inline-block;
    margin: 0 auto;
  }
  .product-search-card-extended__image img {
    margin: auto;
    max-height: 20.2rem;
  }

  .product-search-card-extended__reviews {
    margin: 0.3rem -0.5rem 0;
  }

  .product-search-card-extended__title, .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
    margin: 0;
    font-size: 1.7rem;
    line-height: 2.1rem;
  }

  .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  .product-search-card-extended__labels {
    margin: 0.5rem 0 0;
  }

  .product-search-card-extended__short-description {
    margin: 1.1rem 0 0;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .product-search-card-extended__short-details {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .product-search-card-extended__info {
    display: block;
  }

  .product-search-card-extended__footer {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-areas: "psce-footer-price psce-footer-order" "psce-footer-stock psce-footer-stock";
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .product-search-card-extended__pricing {
    grid-area: psce-footer-price;
  }

  .product-search-card-extended__order {
    grid-area: psce-footer-order;
    margin: 0;
  }

  .product-search-card-extended__stock {
    grid-area: psce-footer-stock;
    justify-content: flex-start;
    margin: 0;
  }
}
@media (--screen-from-sm) {
  .product-search-card-extended {
    padding-right: 2.4rem;
    grid-row: 1.1rem;
    grid-template-areas: "psce-image psce-body psce-footer";
    grid-template-rows: min-content;
  }

  .product-search-card-extended__footer {
    grid-template-areas: "psce-footer-price" "psce-footer-order" "psce-footer-stock";
    justify-content: flex-end;
  }

  .product-search-card-extended__pricing {
    justify-content: flex-end;
  }

  .product-search-card-extended__order {
    justify-content: flex-end;
  }
}
.products-overview-producer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2rem;
  background-color: var(--color-grey-10);
  border-radius: 2rem 2rem 0 0;
}
.products-overview-producer.-first {
  border-radius: 0;
}

.products-overview-producer__title {
  flex: 0 0 auto;
}

.products-overview-producer__title-label {
  display: block;
  color: var(--color-grey-4);
  font-size: 1.2rem;
  line-height: 2rem;
}

.products-overview-producer__name {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  color: var(--color-red-background);
}
.products-overview-producer__name a {
  color: var(--color-red-background);
}

.products-overview-producer__city {
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--color-green-1);
}

.products-overview-producer__types {
  margin-top: 1rem;
}

@media (--screen-from-sm) {
  .products-overview-producer {
    flex-direction: row;
    justify-content: flex-end;
  }
  .products-overview-producer.-first {
    padding-top: 0;
  }

  .products-overview-producer__types {
    margin: 0 0 0 1.5rem;
    padding-left: 1rem;
    position: relative;
  }
  .products-overview-producer__types::before {
    content: "";
    border-left: 1px solid var(--color-grey-4);
    position: absolute;
    left: 0;
    top: 0.7rem;
    bottom: 0.3rem;
  }
}
.products-overview-region {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: 4.4rem 1fr;
  grid-template-areas: "por-image por-title" "por-more-info por-more-info";
  padding: 1rem 2rem 0;
  background-color: var(--color-grey-10);
  border-radius: 2rem 2rem 0 0;
}

.products-overview-region__image {
  grid-area: por-image;
}

.products-overview-region__title {
  grid-area: por-title;
  align-self: center;
}

.products-overview-region__title-label {
  display: block;
  color: var(--color-grey-4);
  font-size: 1.2rem;
  line-height: 2rem;
}

.products-overview-region__name {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  color: var(--color-red-background);
}

.products-overview-region__more {
  grid-area: por-more-info;
  align-self: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

@media (--screen-from-sm) {
  .products-overview-region {
    grid-template-areas: "por-image por-title por-more-info";
    grid-template-columns: 4.4rem 1fr auto;
  }
}
.products-overview-types {
  padding-left: 2.3rem;
  font-family: var(--font-belleza);
  position: relative;
}

.products-overview-types__icon {
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  left: 0;
  top: 0.7rem;
}

.products-overview-types__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.products-overview-types__list-item {
  line-height: 2.2rem;
  margin-right: 1rem;
}
.products-overview-types__list-item:last-child {
  margin-right: 0;
}

.products-overview-types__label, .products-overview-types__anchor {
  font-size: 1.2rem;
  line-height: 2.2rem;
  text-transform: uppercase;
}

.products-overview-types__label {
  color: var(--color-red-background);
}

.product-card {
  box-sizing: border-box;
  word-break: break-word;
  display: grid;
  gap: 1rem;
  grid-template-columns: 5.7rem auto;
  grid-template-rows: auto min-content;
  grid-template-areas: "card-grid-image card-grid-info" "card-grid-order card-grid-order";
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-red-2);
  padding: 2rem 1rem 3rem;
  position: relative;
}
.product-card.-portrait {
  text-align: center;
  grid-template-columns: auto;
  gap: 1.4rem;
  grid-template-areas: "card-grid-image" "card-grid-info" "card-grid-order ";
  grid-template-rows: min-content auto min-content;
  padding: 1.7rem 1.6rem 3rem;
  position: relative;
}

.product-card__anchor {
  text-decoration: none;
}
.product-card__anchor img {
  width: auto;
}

.product-card__image {
  grid-area: card-grid-image;
}
.product-card.-portrait .product-card__image {
  height: 25.2rem;
}
.product-card__image img {
  max-width: 100%;
}
.product-card.-portrait .product-card__image img {
  max-height: 25.2rem;
}

.product-card__info {
  grid-area: card-grid-info;
  margin-top: 3.9rem;
}
.product-card.-portrait .product-card__info {
  margin: 0;
}

.product-card__reviews {
  position: absolute;
  right: 1.4rem;
  top: 0.95rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  justify-content: flex-end;
}
.product-card.-portrait .product-card__reviews {
  top: 1.4rem;
  flex-direction: column;
  align-items: flex-end;
}

.product-card__review-item {
  font-size: 1.3rem;
  font-weight: 300;
}

.product-card__review-score {
  font-weight: 400;
}

.product-card__main-title {
  margin-bottom: 0.7rem;
  font-size: 1.8rem;
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-light);
  line-height: 1.2;
}

.product-card__title-year {
  font-weight: var(--font-weight-medium);
}

.product-card__subtitle {
  font-size: 1.6rem;
  line-height: 1.3;
  color: var(--color-grey-4);
  margin: 1rem 0 0 0;
}

.product-card__order-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: card-grid-order;
  justify-self: flex-start;
}
.product-card.-portrait .product-card__order-info {
  justify-self: center;
  justify-content: center;
}

.product-card__pricing {
  line-height: 1;
}

.product-card__order {
  margin-top: 1.2rem;
}

.product-card__labels {
  display: flex;
  margin-top: 1rem;
}
.product-card.-portrait .product-card__labels {
  justify-content: center;
}
.product-card__labels .product-order-form__amount-input {
  height: auto;
}

@media (--screen-from-md) {
  .product-card {
    padding: 1rem 1rem 4rem;
    grid-template-areas: "card-grid-image card-grid-info" "card-grid-image card-grid-order";
    grid-template-columns: 10.6rem auto;
  }
  .product-card.-portrait {
    padding: 1.4rem 1rem 4rem;
  }

  .product-card__reviews {
    left: 0;
    top: -4.5rem;
    justify-content: start;
  }
  .product-card.-portrait .product-card__reviews {
    position: absolute;
  }

  .product-card__info {
    margin-top: 7.4rem;
    position: relative;
  }
  .product-card.-portrait .product-card__info {
    position: static;
  }

  .product-card__main-title {
    font-size: 1.9rem;
  }
}
.product-card-pricing {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.product-card-pricing__price {
  font-size: 2.1rem;
  line-height: 2.1rem;
  font-weight: 500;
  color: var(--color-red-3);
  white-space: nowrap;
}
.product-card-pricing.-grey-3 .product-card-pricing__price {
  color: var(--color-grey-3);
}

.product-card-pricing__unit {
  font-size: 1.7rem;
  font-weight: 300;
  color: var(--color-grey-3);
  vertical-align: bottom;
  margin-left: 0.5rem;
}

.product-card-pricing__discount {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: var(--color-red);
}

.product-card-pricing__original-price {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--color-grey-4);
  vertical-align: bottom;
  text-decoration: line-through;
}

.product-card-pricing__subtitle {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 400;
  margin: 0.5rem 0 0;
  color: var(--color-white);
  background-color: var(--color-red-4);
  padding: 0.4rem 0.8rem 0.5rem;
  border-radius: 1.1rem;
}
.product-card-pricing__subtitle.-alt {
  font-weight: 500;
  color: var(--color-red-6);
  background-color: var(--color-red-7);
}

.product-card-pricing__on-request {
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-red-3);
}

@media (--screen-from-sm) {
  .product-card-pricing.-extended {
    align-items: flex-end;
  }
}
.product-card-stock {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: 300;
}

.product-card-stock__label {
  display: inline-block;
  padding: 0.5rem 1.3rem;
  width: fit-content;
  border-radius: 0.1rem;
  position: relative;
}
.product-card-stock__label > .svg-icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
  position: absolute;
  top: calc(0.5rem + 0.2em);
  left: 1.3rem;
}
.product-card-stock__label.-ok {
  padding: 0.5rem 1.3rem 0.5rem 3.4rem;
  color: var(--color-green-3);
  background-color: var(--color-green-4);
}
.product-card-stock__label.-warning {
  padding: 0.5rem 1.3rem 0.5rem 3.4rem;
  color: var(--color-orange-1);
  background-color: var(--color-orange-2-opaque);
}
.product-card-stock__label.-error {
  color: var(--color-red);
  background-color: var(--color-grey-1);
}
.product-card-stock__label.-temp-error {
  color: var(--color-red);
  background-color: var(--color-orange-2-opaque);
}

.product-card-stock__deliverable-from {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.85rem;
  margin: 0 0 0.5rem;
  text-align: center;
}
.product-card-stock__deliverable-from svg {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 0.2em;
}

@media (--screen-from-sm) {
  .product-card-stock.-extended {
    align-items: flex-end;
  }

  .product-card-stock.-extended .product-card-stock__deliverable-from {
    text-align: right;
  }
}
.products-filter-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem;
  margin-bottom: 2rem;
  background-color: var(--color-red-7);
  border-radius: 1rem;
}

.products-filter-banner__image {
  margin: 0 0 1rem;
  width: 100%;
}
.products-filter-banner__image img {
  display: block;
}

.products-filter-banner__title {
  margin: 0;
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-light);
  font-size: 1.7rem;
  line-height: 2rem;
  color: var(--color-red-3);
}

.products-filter-banner__description {
  margin: 0.4rem 0 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-red-1);
  text-transform: uppercase;
}
.products-filter-banner__description > *:first-child {
  margin-top: 0;
}
.products-filter-banner__description > *:last-child {
  margin-bottom: 0;
}

.products-filter-banner__link {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-red-9);
}

@media (--screen-from-sm) {
  .products-filter-banner {
    padding: 1rem 2rem;
    align-items: center;
    flex-direction: row;
  }

  .products-filter-banner__image {
    flex: 0 0 11.1rem;
    margin: 0 2.2rem 0 0;
  }

  .products-filter-banner__text {
    margin: 0;
    flex: 1 1 auto;
  }

  .products-filter-banner__link {
    flex: 0 0 auto;
    margin: 0 0 0 4rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.product-detail__header {
  max-width: calc(var(--default-content-width) + var(--side-padding) * 2);
  margin: auto;
  padding: 0 var(--side-padding);
}

.product-detail-header__grid-gallery {
  grid-area: grid-gallery;
  position: relative;
}

.product-detail-header__grid-title {
  grid-area: grid-title;
}

.product-title__customer-reviews-link {
  color: blue;
}

.product-title__reviews {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.product-detail-header__grid-details {
  grid-area: grid-details;
}
.product-detail-header__grid-details > .wine-labels {
  margin-top: 2.15rem;
}
.product-detail-header__grid-details > .product-short-details {
  margin: 3rem 0;
}

.product-detail-header__favorite-button.-desktop > .button {
  --theme: var(--color-red-7);
}
.product-detail-header__favorite-button.-desktop > .button > .button__icon {
  top: calc(50% + 0.1rem);
}
.product-detail-header__favorite-button.-desktop > .button:hover, .product-detail-header__favorite-button.-desktop > .button:focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 512 512'><path fill='%239A0443' d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'></path></svg>");
  background-repeat: no-repeat;
  background-position: center;
  color: inherit;
}
.product-detail-header__favorite-button.-desktop > .button:hover.-is-favorite, .product-detail-header__favorite-button.-desktop > .button:focus.-is-favorite {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 512 512'><path fill='%239A0443' d='M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z'></path></svg>");
}
.product-detail-header__favorite-button.-desktop > .button:hover.-is-favorite > .button__icon, .product-detail-header__favorite-button.-desktop > .button:focus.-is-favorite > .button__icon {
  display: none;
}
.product-detail-header__favorite-button.-mobile > .button {
  --theme: var(--color-grey-3);
  font-weight: 300;
  margin: 0 auto 2.25rem;
}
.product-detail-header__favorite-button.-mobile > .button > .button__text {
  text-decoration: none;
  border-bottom: 0.1rem solid transparent;
}
.product-detail-header__favorite-button.-mobile > .button:hover, .product-detail-header__favorite-button.-mobile > .button:focus {
  background-color: inherit;
}
.product-detail-header__favorite-button.-mobile > .button:hover > .button__text, .product-detail-header__favorite-button.-mobile > .button:focus > .button__text {
  border-bottom-color: var(--text-color);
}
.product-detail-header__favorite-button > .button {
  --theme: var(--color-red-7);
}
.product-detail-header__favorite-button .button__icon {
  color: var(--color-red-9);
}

.product-detail-header__professional-reviews {
  position: absolute;
  top: 1rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  z-index: var(--z-index-page);
  align-content: flex-end;
  align-items: flex-end;
}

.product-detail-header__description {
  margin: 2.15rem 0 3rem;
}

.product-detail-menu {
  display: none;
  justify-content: center;
  margin: auto;
  padding: 2rem 0;
  border-top: 0.1rem solid var(--color-grey-5);
  border-bottom: 0.1rem solid var(--color-grey-5);
  background-color: var(--color-white);
}

.product-detail-menu__item {
  font-size: 1.8rem;
  line-height: 1.9rem;
  color: var(--color-red-3);
  padding: 1.6rem 2rem;
  border-radius: 0.3rem;
  border: none;
  text-align: center;
  text-decoration: none;
}
.product-detail-menu__item:hover {
  background-color: var(--color-grey-8);
}

.product-detail__footer {
  display: none;
  max-width: calc(var(--default-content-width) + var(--side-padding) * 2);
  margin: auto;
  padding: 5rem var(--side-padding);
}

.product-detail-footer__grid-image {
  grid-area: grid-gallery;
  justify-self: end;
}
.product-detail-footer__grid-image > img {
  max-width: 16rem;
  max-height: 28rem;
  margin: auto;
}

.product-detail-footer__grid-title {
  grid-area: grid-title;
  width: 100%;
}
.product-detail-footer__grid-title .product-title, .product-detail-footer__grid-title .product-title__year {
  font-size: 2.7rem;
  line-height: 3.1rem;
}
.product-detail-footer__grid-title .product-title {
  margin: 0.5rem 0 0;
}

.product-detail-footer__grid-details {
  grid-area: grid-details;
}
.product-detail-footer__grid-details > .product-price {
  margin-bottom: 1rem;
}
.product-detail-footer__grid-details > .product-stock {
  margin: 1rem 0 2rem;
}

.product-detail__sticky_footer {
  position: sticky;
  bottom: 0;
  padding: 1rem 1.6rem 1rem;
  background-color: var(--color-grey-2);
}

.sticky-footer__subheader {
  font-size: 1.3rem;
  font-weight: 300;
  color: var(--color-grey-6);
  margin: 0;
}
.sticky-footer__subheader > span, .sticky-footer__subheader a {
  color: var(--color-grey-3);
}

.sticky-footer__title {
  font-size: 1.8rem;
  line-height: 2rem;
  color: var(--color-green-1);
  margin: 0.9rem 0;
}

.sticky-footer__year {
  color: var(--color-black);
}

.sticky-footer__order {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sticky-footer__order .product-price {
  flex: 1;
}
.sticky-footer__order .product-price .product-price__price {
  font-size: 1.6rem;
}
.sticky-footer__order .product-price .product-price__subtitle {
  font-size: 1.4rem;
}
.sticky-footer__order .product-price .product-price__unit {
  font-size: 1.3rem;
}
.sticky-footer__order .product-order-form__amount-input {
  height: auto;
  padding: 0.8rem;
}

.product-detail__back-to-top {
  padding-top: 2rem;
}
.product-detail__back-to-top > .button {
  font-size: 1.6rem;
  margin: auto;
  background-color: var(--color-red-2);
  text-transform: uppercase;
  padding: 0.6rem 1.6rem;
}

.product-detail-header__buy {
  margin-bottom: 2rem;
}

.product-detail-header__pricing {
  grid-area: price;
}

.product-detail-header__order-form {
  margin-top: 1rem;
  grid-area: order;
}

.product-detail-header__stock {
  margin-top: 1rem;
  grid-area: stock;
}

@media (--screen-from-sm) {
  .product-detail-header__buy {
    display: grid;
    grid-template-areas: "price order" "price stock";
    column-gap: 2rem;
    align-items: center;
    margin: 3rem 0 2rem;
  }
}
@media (--screen-from-md) {
  .product-detail__header {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "grid-gallery grid-title" "grid-gallery grid-details";
  }

  .product-detail-header__buy {
    display: block;
  }

  .product-detail__sticky_footer {
    display: none;
  }

  .product-detail__back-to-top {
    display: none;
  }

  .product-detail-menu {
    display: flex;
  }

  .product-detail__footer {
    display: grid;
    grid-gap: 2rem 7rem;
    grid-template-columns: auto 52rem;
    grid-template-areas: "grid-gallery grid-title" "grid-gallery grid-details";
  }
  .product-detail__footer .product-order-form__amount-label {
    flex-basis: auto;
  }
}
@media (--screen-from-lg) {
  .product-detail__header {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 52rem;
    grid-template-areas: "grid-gallery grid-title" "grid-gallery grid-details";
  }

  .product-detail-menu__item {
    font-size: 2.3rem;
  }
}
.sticky-footer__context.-background {
  background-color: #fafafa;
}

.slider {
  margin: 0;
  position: relative;
}

.slider__control {
  display: none;
  width: 3rem;
  height: 3rem;
  transition: background-color 0.1s ease-in;
  color: var(--color-blue-light-1);
  border-radius: 50%;
  background: var(--color-grey-2);
  border: 1px solid var(--color-red-2);
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.slider__control.-prev {
  left: -1.5rem;
  transform: translateY(-50%) rotate(180deg);
}
.slider__control svg {
  height: 1.1rem;
  width: 0.7em;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-green-6);
  transform: translate(-35%, -50%);
}
.slider__control.-next {
  right: -1.5rem;
}
.slider__control:hover {
  background-color: var(--color-grey-5);
}
.slider.-fixed-items .slider__control {
  width: 4.5rem;
  height: 4.5rem;
}
.slider.-fixed-items .slider__control svg {
  height: 1.9rem;
  width: 1.2rem;
}
.slider.-overlay-controls .slider__control {
  border-radius: 1rem 0 0 1rem;
  background-color: var(--color-red-2);
  height: 4.5rem;
  width: 3.5rem;
}
.slider.-overlay-controls .slider__control.-prev {
  left: 0;
}
.slider.-overlay-controls .slider__control.-next {
  right: 0;
}
.slider.-overlay-controls .slider__control svg {
  color: var(--color-white);
}

.slider__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.slider__track {
  width: calc(100% + (2 * 2rem));
  margin-left: -2rem;
  box-sizing: border-box;
  padding: 0 2rem;
  overflow: hidden;
  overflow-x: scroll;
}

.slider__items {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 2rem;
  list-style: none;
}
.slider__items > * {
  margin: 0;
  padding: 0;
}
.slider.-fixed-items .slider__items > * {
  flex: 0 0 17rem;
}

.slider.-fixed-items.-items-1 .slider__items > * {
  flex-basis: calc(((100vw - 2rem * 2) - (2rem * (1 - 1))) / (1 + .3));
}
.slider.-fixed-items.-items-2 .slider__items > * {
  flex-basis: calc(((100vw - 2rem * 2) - (2rem * (2 - 1))) / (2 + .3));
}
.slider.-fixed-items.-items-3 .slider__items > * {
  flex-basis: calc(((100vw - 2rem * 2) - (2rem * (3 - 1))) / (3 + .3));
}
.slider.-fixed-items.-items-4 .slider__items > * {
  flex-basis: calc(((100vw - 2rem * 2) - (2rem * (4 - 1))) / (4 + .3));
}

.slider__item > * {
  height: 100%;
  width: 100%;
}
.slider__item:last-child {
  position: relative;
}
.slider__item:last-child::after {
  position: absolute;
  content: "";
  top: 0;
  right: -2rem;
  width: 2rem;
  height: 100%;
}

@media (--screen-from-md) {
  .slider.-fixed-items.-items-1 .slider__items > * {
    flex-basis: calc((100% - (2rem * (1 - 1))) / 1);
  }
  .slider.-fixed-items.-items-2 .slider__items > * {
    flex-basis: calc((100% - (2rem * (2 - 1))) / 2);
  }
  .slider.-fixed-items.-items-3 .slider__items > * {
    flex-basis: calc((100% - (2rem * (3 - 1))) / 3);
  }
  .slider.-fixed-items.-items-4 .slider__items > * {
    flex-basis: calc((100% - (2rem * (4 - 1))) / 4);
  }

  .slider.-with-controls {
    margin-left: 4rem;
    margin-right: 2rem;
  }
  .slider.-fixed-items {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .slider.-overlay-controls {
    margin-left: 0;
    margin-right: 0;
  }

  .slider__control.-prev {
    left: -4rem;
  }
  .slider.-with-controls .slider__control {
    display: block;
  }
  .slider.-fixed-items .slider__control.-prev {
    left: -2rem;
  }
  .slider.-overlay-controls .slider__control.-prev {
    left: 0;
  }
  .slider.-overlay-controls .slider__control.-next {
    right: 0;
  }

  .slider__track {
    width: auto;
    margin-left: 0;
    padding: 0;
    overflow: hidden;
  }

  .slider__item::after {
    display: none;
  }
}
@media (--screen-from-lg) {
  .slider.-fixed-items .slider__items > * {
    flex-basis: calc((100% - 6rem) / 4);
  }
}
@media (--screen-from-xxl) {
  .slider.-overlay-controls .slider__control.-prev {
    left: 0;
  }
  .slider.-overlay-controls .slider__control.-next {
    right: 0;
  }
  .slider.-fixed-items .slider__control.-prev {
    left: -2.25rem;
  }
  .slider.-fixed-items .slider__control.-next {
    right: -2.25rem;
  }
}
.footer-info-block__back-to-top > .button {
  position: absolute;
  z-index: 1;
  right: 2.5rem;
  bottom: 2.5rem;
  font-size: 1.6rem;
  margin: auto;
  background-color: var(--color-red-2);
  text-transform: uppercase;
  padding: 0.6rem 1.6rem;
  display: none;
}

@media (--screen-from-md) {
  .footer-info-block__back-to-top > .button {
    display: block;
  }
}
.packages-list-layout {
  margin: 0;
  padding: 0;
  list-style: none;
}

.packages-list-layout__item {
  margin: 0 0 2rem;
}
.packages-list-layout__item:last-child {
  margin-bottom: 0;
}

.submenu-slider {
  margin: 0 0 2rem;
}

.submenu-slider__link {
  flex: 1 0 auto;
}

.region-filter-intro {
  margin-bottom: 3rem;
}

.region-filter-intro__header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.region-filter-intro__title {
  flex: 1 1 auto;
  margin: 0;
}

.region-filter-intro__image {
  flex: 0 0 4rem;
}

@media (--screen-from-sm) {
  .region-filter-intro__image {
    flex: 0 0 6rem;
  }
}
.co-browsing {
  padding: 1.5rem;
  max-width: 15rem;
}

.co-browsing-popup {
  width: 100%;
  max-width: 40rem;
}

.co-browsing-popup__buttons {
  display: flex;
  justify-content: center;
}

.co-browsing-popup__support {
  display: none;
}

.co-browsing-popup__support-code-link {
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: black;
  padding: 1.5rem 3rem 1.5rem 3rem;
  border: 0.1rem solid var(--color-white);
}
.co-browsing-popup__support-code-link:hover {
  border: 0.1rem solid var(--color-black);
  border-radius: 0.5rem;
}
.co-browsing-popup__support-code-link:hover .co-browsing-popup__support-code-icon {
  display: block;
}

.co-browsing-popup__support-code {
  display: flex;
  justify-content: center;
}

.co-browsing-popup__support-code-icon {
  display: none;
  position: absolute;
  width: 1.5rem;
  right: 0;
  top: 0.2rem;
}

.cart-account-step {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

@media (--screen-from-md) {
  .cart-account-step {
    --column-gap: 6rem;
    flex-direction: row;
    gap: var(--column-gap);
  }

  .cart-account-step__right {
    position: relative;
  }
  .cart-account-step__right::after {
    content: "";
    position: absolute;
    left: calc(var(--column-gap) / 2 * -1);
    top: 4rem;
    bottom: 4rem;
    border-left: 1px solid var(--color-grey-9);
  }

  .cart-account-step__left, .cart-account-step__right {
    flex-basis: calc(50% - 2rem);
  }
  .cart-account-step__left hr:last-child, .cart-account-step__right hr:last-child {
    display: none;
  }
}
@media (--screen-from-lg) {
  .cart-account-step {
    --column-gap: 10rem;
  }
}
@media (--screen-from-xxl) {
  .cart-account-step {
    --column-gap: 24rem;
  }
}
.account-login-form__actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2rem;
}

.account-login-form__action-reset {
  font-size: 1.6rem;
}

.account-login-form__error {
  color: var(--color-red-6);
  font-size: 1.6rem;
  line-height: 2rem;
}

@media (--screen-from-sm) {
  .account-login-form__actions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.cart-create-account__title {
  margin-top: 0;
  color: var(--color-green-1);
}
.cart-create-account__title b {
  color: var(--color-green-3);
  font-style: normal;
  font-weight: var(--font-weight-normal);
}

.cart-create-account__usps {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: var(--color-green-1);
}

.cart-create-account__usp {
  margin: 0 0 0.3rem;
  padding: 0 0 0 2.5rem;
  position: relative;
}
.cart-create-account__usp svg {
  position: absolute;
  left: 0;
  top: 0.35rem;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-green-3);
}
.cart-create-account__usp b {
  font-weight: var(--font-weight-medium);
}
.cart-create-account__usp:last-child {
  margin-bottom: 0;
}

.cart-create-account__actions {
  margin-top: 3rem;
}

.cart-address-form-popup {
  width: 63.8rem;
  max-width: 100%;
}

.cart-billing-address-selector-popup {
  max-width: 35rem;
}

.cart-billing-address-selector-popup__selector {
  width: 36rem;
  max-width: 100%;
  margin: 0 0 2rem;
  position: relative;
}
.cart-billing-address-selector-popup__empty {
  margin-top: 0;
}

.cart-billing-address-selector-popup__new {
  width: 100%;
}

.billing-address-selector {
  margin: 0;
  padding: 0;
  list-style: none;
}

.billing-address-selector__item {
  margin: 0 0 1rem;
  padding: 0;
  position: relative;
}
.billing-address-selector__item:last-child {
  margin-bottom: 0;
}

.billing-address-selector__option {
  display: block;
  width: 100%;
  margin: 0;
  padding: 1.5rem 4.5rem 1.5rem 1.9rem;
  border: none;
  cursor: pointer;
  text-align: left;
  position: relative;
}
.billing-address-selector__option.-selected {
  border: 1px solid var(--color-green-3);
}

.billing-address-selector__option-icon {
  display: none;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: 1.9rem;
  top: 1.5rem;
  color: var(--color-green-3);
}
.billing-address-selector__option.-selected .billing-address-selector__option-icon {
  display: block;
}

.billing-address-selector-option {
  font-style: normal;
  font-size: 1.4rem;
  font-weight: var(--font-weight-light);
  line-height: 1.5;
  color: var(--default-text-color);
}
.billing-address-selector-option b {
  font-size: 1.6rem;
}

.billing-address-selector-option__item-edit {
  margin-left: 1rem;
  color: var(--color-black);
}
.billing-address-selector-option__item-edit svg {
  width: 1.2rem;
  height: 1.2rem;
}

.cart-popup__close {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
  border-radius: 0;
  outline: none;
  font-family: var(--default-font-family);
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}
.cart-popup__close:hover, .cart-popup__close:focus {
  text-decoration: none;
}

.cart-popup {
  display: none;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(70, 83, 74, 0.7);
  z-index: var(--z-index-modal);
}
.cart-popup.-open {
  display: flex;
}

.cart-popup__body {
  max-height: 100%;
  margin: auto;
  overflow-y: auto;
  background-color: var(--color-white);
  border-radius: 1rem;
}

.cart-popup__header {
  padding: 2rem 5.5rem 2rem 2rem;
  position: relative;
  border-bottom: 1px solid var(--color-grey-5);
}

.cart-popup__title {
  margin: 0;
  color: var(--color-red-1);
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-medium);
  font-size: 2rem;
  line-height: 3rem;
}

.cart-popup__close {
  position: absolute;
  right: 2rem;
  top: 2.75rem;
}
.cart-popup__close svg {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-grey-4);
}

.cart-popup__content {
  padding: 2rem;
}

.cart-popup__footer {
  padding: 0 2rem 2rem;
}

.cart-popup-buttons {
  display: flex;
  gap: 5rem;
  justify-content: flex-start;
  align-items: center;
}
.cart-popup-buttons > [type=submit] {
  margin-left: auto;
}

.delivery-driver-instruction-popup {
  width: 60.9rem;
  max-width: 100%;
}

.keep-me-posted-popup {
  padding: 0;
  max-width: 40rem;
}

.keep-me-posted-popup__description {
  margin: 0 0 1.5rem;
}

@media (--screen-from-sm) {
  .account-layout {
    display: flex;
    column-gap: 4rem;
  }

  .account-layout__navigation {
    flex: 0 0 20rem;
  }

  .account-layout__content {
    flex: 1 1 auto;
  }
}
.account-navigation {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-account-nav);
}
.account-navigation.-open {
  display: block;
}

.account-navigation__backdrop {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(70, 83, 74, 0.5);
  backdrop-filter: blur(0.5rem);
}

.account-navigation__scroll-lock {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  overscroll-behavior: contain;
}
.account-navigation__scroll-lock:after {
  display: block;
  content: "";
  height: calc(100% + 1px);
}

.account-navigation__close {
  position: absolute;
  top: 1.2rem;
  left: calc(100% - 26.5rem - 3.7rem);
}
.account-navigation__close svg {
  color: var(--color-white);
  width: 1.7rem;
  height: 1.7rem;
}

.account-navigation__user {
  border-bottom: 1px solid var(--color-red-2);
}

.account-navigation__menu {
  width: 100%;
  max-width: 26.5rem;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-white);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

@media (--screen-from-sm) {
  .account-navigation {
    display: block;
    position: static;
  }

  .account-navigation__user {
    display: none;
  }

  .account-navigation__backdrop {
    display: none;
  }

  .account-navigation__menu {
    position: static;
    background-color: transparent;
  }

  .account-navigation__section {
    margin-bottom: 1.8rem;
  }
  .account-navigation__section:last-child {
    margin-bottom: 0;
  }

  .account-navigation__close {
    display: none;
  }
}
.account-navigation-user {
  padding-top: 0.5rem;
}

.account-navigation-user__account, .account-navigation-user__logout {
  padding: 1rem 1.6rem 1rem 4rem;
  position: relative;
}
.account-navigation-user__account svg, .account-navigation-user__logout svg {
  position: absolute;
  top: 1rem;
  left: 1.6rem;
}

.account-navigation-user__account {
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--color-green-1);
}
.account-navigation-user__account svg {
  height: 2.3rem;
  width: 1.7rem;
  top: 0.7rem;
}

.account-navigation-user__logout {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: var(--font-weight-medium);
}
.account-navigation-user__logout svg {
  width: 1.6rem;
  height: 1.6rem;
  top: 1.3rem;
  color: var(--color-grey-3);
}

.account-navigation-user__logout-link {
  text-decoration: none;
  color: var(--color-blue-1);
}
.account-navigation-user__logout-link:hover, .account-navigation-user__logout-link:focus, .account-navigation-user__logout-link:active {
  text-decoration: underline;
}

.account-navigation-section__title {
  padding: 1.2rem 1.6rem;
  margin: 0;
  background-color: var(--color-white-darker);
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-medium);
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-black);
  text-transform: uppercase;
}

.account-navigation-section__items {
  padding: 0;
  margin: 0;
  list-style: none;
}

.account-navigation-section__item {
  padding: 1.2rem 1.6rem;
  margin: 0;
  font-size: 1.4rem;
  font-weight: var(--font-weight-light);
  line-height: 2.4rem;
}
.account-navigation-section__item.-active {
  background-color: var(--color-red-7);
}

.account-navigation-section__item-link {
  text-decoration: none;
  color: var(--color-grey-3);
}
.account-navigation-section__item.-active .account-navigation-section__item-link {
  color: var(--color-red-3);
}
.account-navigation-section__item-link:hover, .account-navigation-section__item-link:focus, .account-navigation-section__item-link:active {
  text-decoration: underline;
}

@media (--screen-from-sm) {
  .account-navigation-section__title {
    padding: 0;
    margin: 0 0 1rem;
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 3rem;
    text-transform: none;
  }

  .account-navigation-section__item {
    padding: 0;
    margin-bottom: 0.8rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .account-navigation-section__item.-active {
    background-color: transparent;
  }
}
.account-address-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: stretch;
  grid-gap: 2rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.account-address-list__item {
  min-height: 25rem;
}

@media (--screen-from-md) {
  .account-address-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--screen-from-lg) {
  .account-address-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.account-address-list-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  height: 100%;
  width: 100%;
  color: var(--color-orange-1);
  border: 1px solid var(--color-grey-9);
  text-decoration: none;
}
.account-address-list-add:hover, .account-address-list-add:focus, .account-address-list-add:active {
  text-decoration: underline;
}

.account-address-list-add__icon {
  padding: 1.3rem;
  background-color: var(--color-red-7);
  border-radius: 0.4rem;
}
.account-address-list-add__icon svg {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
}

.account-address-list-add__label {
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  font-weight: var(--font-weight-medium);
}

.account-address-list-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  border: 1px solid var(--color-grey-9);
}

.account-address-list-item__address {
  margin: 0 0 2rem;
  font-size: 1.4rem;
  line-height: 1.6;
  font-style: normal;
}

.account-address-list-item__name {
  display: block;
  margin: 0 0 2.4rem;
  font-weight: var(--font-weight-medium);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.account-address-list-item__defaults {
  margin: 0 0 2rem;
}

.account-address-list-item__default {
  padding-left: 1.6rem;
  margin-bottom: 0.6rem;
  font-family: var(--font-belleza);
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-green-1);
  text-transform: uppercase;
  position: relative;
}
.account-address-list-item__default svg {
  width: 1rem;
  height: 1rem;
  color: var(--color-green-3);
  position: absolute;
  left: 0;
  top: 0.3rem;
}

.account-address-list-item__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: auto;
}

.popup-dialog {
  padding: 0;
  background-color: var(--color-white);
  border-radius: 1rem;
  overflow-y: auto;
  max-height: 100%;
}
.popup-dialog::backdrop {
  position: fixed;
  inset: 0;
  background: rgba(70, 83, 74, 0.7);
  backdrop-filter: blur(2rem);
}

.popup-dialog__header {
  padding: 2rem 5.5rem 2rem 2rem;
  position: relative;
  border-bottom: 1px solid var(--color-grey-5);
}

.popup-dialog__title {
  margin: 0;
  color: var(--color-red-1);
  font-family: var(--font-wotfard);
  font-weight: var(--font-weight-medium);
  font-size: 2rem;
  line-height: 3rem;
}

.popup-dialog__body {
  padding: 2rem;
}

.popup-dialog__footer {
  padding: 0 2rem 2rem;
}

.pac-container {
  z-index: var(--z-index-places-automcomplete);
}

.alert {
  padding: 1rem 3rem 1rem 2rem;
  margin: 0 0 2rem;
  position: relative;
  color: var(--color-white);
  background-color: var(--color-red-1);
}
.alert.-error {
  background-color: var(--color-red);
}
.alert.-success {
  background-color: var(--color-green-3);
}
.alert.-warning {
  background-color: var(--color-orange-1);
}

.alert__close {
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--color-white);
}
.alert__close svg {
  width: 1rem;
  height: 1rem;
}

.alert__message {
  margin: 0;
  padding: 0;
}

.intl-tel-input {
  display: block;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 5px;
}