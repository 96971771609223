.filter-block__title {
    position: relative;

    padding: 1.45rem 1.6rem;
    margin: 0;

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-light);
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--color-grey-3);

    cursor: pointer;

    svg {
        position: absolute;
        right: 1.6rem;
        top: 50%;
        transform: translateY(-50%);

        height: 2.2em;
        width: 1.9rem;
        margin: 0;
        transition: transform 150ms linear;
    }

    &[aria-expanded="true"] {
        background-color: var(--color-red-7);
        color: var(--color-red-3);

        svg {
            transform: translateY(-50%) rotate(180deg);
            color: var(--color-grey-3);
        }
    }
}

.filter-block__content {
    display: block;
    padding: 0 1.6rem 1.2rem;
    margin: 0;

    background-color: var(--color-red-7);
    border-top: 1px solid transparent;

    &[hidden] {
        display: none;
    }

    .filter-block.-group > & {
        background-color: transparent;
        padding: 0;

        .filter-block {
            margin-left: 2rem;
        }
    }
}

.filter-block__option {
    display: block;
    margin: 0;
    padding: .3rem 0 .3rem 2.4rem;

    position: relative;

    font-size: 1.4rem;
    line-height: 1.7rem;
    color: var(--color-grey-3);
    cursor: pointer;

    .count {
        color: var(--color-grey-4);
    }

    &[hidden] {
        display: none;
    }

    &.-disabled {
        color: var(--color-grey-6);
        cursor: initial;

        .count {
            color: var(--color-grey-6);
        }
    }
}

.filter-block__option-input {
    margin: 0 0 .1rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: .4rem;
    border: 1px solid var(--color-grey-7);

    position: absolute;
    left: 0;
    top: .3rem;
}

.filter-block__more {
    display: flex;
    margin: 1.2rem 0 0;

    font-weight: var(--font-weight-normal);
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--color-blue-1);

    span {
    }

    svg {
        margin-right: .7rem;
        position: relative;
        top: .35rem;
        height: 1.3rem;
        width: 1.1rem;
    }

    &:hover, &:focus, &:active {
        span {
            text-decoration: underline;
        }
    }

    &.-open {
        svg {
            transform: rotate(180deg);
        }
    }
}

@media (--screen-from-md) {
    .filter-block__title {
        padding: 0 0 1.1rem;
        border-bottom: .1rem solid var(--color-grey-5);

        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: var(--font-weight-medium);
        color: var(--color-black);

        &[aria-expanded="true"] {
            background-color: transparent;
            color: var(--color-black);
        }

        svg {
            top: calc(50% - (1.1rem / 2));
            right: 0;
            height: 1.4rem;
            width: 1.2rem;
        }
    }

    .filter-block__content {
        padding: 0;
        margin-top: 1.1rem;
        background-color: transparent;

        .filter-block.-group > & {
            padding-left: 2rem;
            margin-top: 2rem;

            .filter-block {
                margin-left: 0;
            }
        }

        .filter-block.-alt & {
            margin-top: 0;
        }
    }

    .filter-block__title {
        .filter-block.-alt & {
            border-bottom: none;
        }
    }
    .filter-block__option {
        .filter-block.-alt & {
            margin-bottom: .7rem;
            padding: .7rem .7rem .6rem 3.1rem;
            background-color: var(--color-grey-8);
        }
    }

    .filter-block__option-input {
        .filter-block.-alt & {
            left: .7rem;
            top: .7rem;
        }
    }
}
