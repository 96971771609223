%button-reset {
    display: inline-block;

    margin: 0;
    padding: 0;

    background-color: transparent;
    border: none;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    /* stylelint-enable property-no-vendor-prefix */
    border-radius: 0;
    outline: none;

    font-family: var(--default-font-family);
    font-weight: normal;

    text-align: center;

    cursor: pointer;

    &:hover, &:focus {
        text-decoration: none;
    }
}
