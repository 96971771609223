@use '../../../atoms/button/reset';

.gallery-base {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.gallery-base__main {
    width: 100%;
    box-sizing: border-box;
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;

    position: relative;
}

.gallery-base__main__items {
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.gallery-base__main__navigation {
    width: 2.9rem;
    height: 2.9rem;

    border: 1px solid var(--color-red-2);
    border-radius: 50%;
    background-color: var(--color-grey-2);
    color: var(--color-red-1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
        height: 1.4rem;
        width: 1.4rem;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover, &:active, &:focus {
        background-color: var(--color-grey-5);
    }

    &.-prev {
        left: 0;

        svg {
            transform: translate(-55%, -50%);
        }
    }

    &.-next {
        right: 0;

        svg {
            transform: translate(-40%, -50%);
        }
    }

    .gallery-base.-hide-controls & {
        display: none;
    }
}

.gallery-base__thumbnails {
    width: 100%;
    margin-top: 2rem;
}

@media (--screen-from-sm) {
    .gallery-base[data-type-id="main"] {
        flex-direction: row-reverse;
    }

    .gallery-base__thumbnails {
        flex: 1;
        margin-top: 0;
    }
}
