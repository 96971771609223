.products-overview-region {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-columns: 4.4rem 1fr;
    grid-template-areas: "por-image por-title" "por-more-info por-more-info";
    padding: 1rem 2rem 0;

    background-color: var(--color-grey-10);
    border-radius: 2rem 2rem 0 0;
}

.products-overview-region__image {
    grid-area: por-image;
}

.products-overview-region__title {
    grid-area: por-title;
    align-self: center;
}

.products-overview-region__title-label {
    display: block;
    color: var(--color-grey-4);
    font-size: 1.2rem;
    line-height: 2rem;
}

.products-overview-region__name {
    margin: 0;

    font-size: 1.9rem;
    line-height: 2rem;
    color: var(--color-red-background);
}

.products-overview-region__more {
    grid-area: por-more-info;
    align-self: center;

    font-size: 1.2rem;
    line-height: 1.5rem;
}

@media (--screen-from-sm) {
    .products-overview-region {
        grid-template-areas: "por-image por-title por-more-info";
        grid-template-columns: 4.4rem 1fr auto;
    }
}
