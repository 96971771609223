.active-filters-list {

}

.active-filters-list__item {
    display: inline-block;
    margin-right: 1rem;
    font-weight: var(--font-weight-medium);
    color: var(--color-grey-3);
    font-size: 1.4rem;
    line-height: 1.7rem;

    &:hover, &:focus, &:active {
        span {
            text-decoration: underline;
            text-underline-position: under;
        }
    }

    svg {
        cursor: pointer;
        width: 1.1rem;
        height: 1.1rem;
        color: var(--color-grey-4);
        position: relative;
        top: .05rem;
    }
}

.active-filters-list__clear {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: var(--font-weight-normal);
    color: var(--color-blue-1);
    text-decoration: underline;
    text-underline-position: under;
}
