/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --font-wotfard: 'Wotfard', sans-serif;
    --font-belleza:  'Belleza', sans-serif;
    --font-icons: 'licatacons';

    --font-weight-normal: 400;
    --font-weight-light: 300;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;

    --default-font-family: var(--font-wotfard);

    --color-black: #000000;
    --color-white-darker: #F1F1F1;
    --color-white: #FFFFFF;

    --color-orange-1: #FF6D00;
    --color-orange-1-opaque: rgba(255, 109, 0, 0.1);
    --color-orange-2: #FF8A33;
    --color-orange-2-opaque: rgba(255, 138, 51, 0.1);
    --color-orange-3: #E56200;

    --color-red: #FF0000;
    --color-red-1: #40171C;
    --color-red-2: #CBBEBB;
    --color-red-3: #6C0E29;
    --color-red-4: #FF5961;
    --color-red-5: #ECEAF8;
    --color-red-6: #D52F34;
    --color-red-7: #EDEBEE;
    --color-red-8: #7D2B3B;
    --color-red-9: #9A0443;
    --color-red-background: #41161D;

    --color-green-1: #46534A;
    --color-green-2: #CED7CA;
    --color-green-3: #37B24D;
    --color-green-4: #E8F4E9;
    --color-green-5: #8F9B11;
    --color-green-6: #626828;

    --color-blue-1: #0E7FD2;
    --color-blue-2: #2A7BE4;

    --color-grey-1: #ECE8E8;
    --color-grey-2: #FAFAFA;
    --color-grey-3: #3A3A3A;
    --color-grey-4: #8A8686;
    --color-grey-5: #E6E6E6;
    --color-grey-6: #C7C9CD;
    --color-grey-7: #9DA8BA;
    --color-grey-8: #EEEAEC;
    --color-grey-9: #AAB2B5;
    --color-grey-10: #F1F1F1;

    --nieuwe-grijs: #EEEEEE;

    --default-text-color: var(--color-grey-3);

    --side-padding: 1.6rem;
    --default-content-width: 94rem;

    --z-index-page: 8900;
    --z-index-masthead-nav: 9000;
    --z-index-masthead: 9001;
    --z-index-modal: 9100;
    --z-index-account-nav: 9500;
    --z-index-places-automcomplete: 10000;
}
