.gallery-item-zoomable {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    border-radius: 1rem;
    overflow: hidden;

    position: relative;

    touch-action: none;

    &.-zoomed {
        &::after {
            background-image: url('../images/zoom-out.png');
        }
    }

    &::after {
        display: none;
        width: 2.5rem;
        height: 2.5rem;
        content: '';

        position: absolute;
        right: 2rem;
        bottom: 2rem;
        z-index: 1;

        background-image: url('../images/zoom-in.png');
        background-size: 100%;
    }

    &.-zoomable {
        cursor: zoom-in;

        &.-zoomed {
            cursor: zoom-out;
        }

        &::after {
            display: block;
        }
    }
}

.gallery-item-zoomable__content {
    width: auto;
    height: auto;
    max-width: 98%;
    max-height: 98%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;

    -webkit-touch-callout: none;

    .gallery-item-zoomable.-zoomed & {
        top: 0;
        left: 0;
        transform: translate(0, 0);
        max-width: none;
        max-height: none;
    }
}
