a {
    --anchor-theme: var(--default-text-color);
    text-decoration: underline;
    text-underline-position: under;
    color: var(--anchor-theme);

    &:hover {
        text-decoration: none;
    }

    &.-blue {
        --anchor-theme: var(--color-blue-1);
    }
}
