@use '../../../atoms/button/reset';

.gallery-lightbox {
    display: none;
    padding: .5rem;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-modal);

    background: rgba(#46534A, .7); // var(--color-green-1)
    backdrop-filter: blur(2rem);

    overflow: auto;

    &.-open {
        display: flex;
    }
}

.gallery-lightbox__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    margin: auto;
    width: 55rem;
    height: 85rem;
    max-width: 100%;
    max-height: 100%;
    min-height: 32rem;
    box-sizing: border-box;

    background-color: var(--color-white);

    border-radius: 1rem;

    position: relative;

    font-family: var(--font-asap);
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.8rem;
    line-height: 3rem;
    color: var(--color-gray-cool-1);
}

.gallery-lightbox__title {
    flex: 0 0 auto;
    margin: 0 0 1rem;
}

.gallery-lightbox__gallery {
    flex: 0 1 auto;
    min-height: 0;
    height: 100%;

    > .gallery-base {
        height: 100%;
    }
}

.gallery-lightbox__close {
    @extend %button-reset;
    display: block;
    padding: .5rem;
    font-size: 2.3rem;

    color: var(--color-grey-4);

    position: absolute;
    right: .5rem;
    top: .5rem;

    svg {
        display: block;
        width: 1.7rem;
        height: 1.7rem;
    }
}

@media (--screen-from-lg) {
    .gallery-lightbox {
        padding: 1.5rem;
    }

    .gallery-lightbox__container {
        padding: 1.5rem;
    }

    .gallery-lightbox__close {
        right: 1rem;
        top: 1rem;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}
