svg * {
    fill: currentColor;
}

.svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}
