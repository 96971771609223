.products-overview-grouped {}

.products-overview-grouped__producer {
    margin-top: 2rem;

    &.-first {
        margin-top: 0;
    }
}

.products-overview-grouped__products {
    margin-bottom: 2rem;
}
