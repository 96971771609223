$item-margin: 2rem;
$side-padding: 2rem;

.slider {
    margin: 0;
    position: relative;
}

.slider__control {
    display: none;
    width: 3rem;
    height: 3rem;

    transition: background-color .1s ease-in;
    color: var(--color-blue-light-1);
    border-radius: 50%;
    background: var(--color-grey-2);
    border: 1px solid var(--color-red-2);

    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    &.-prev {
        left: -1.5rem;
        transform: translateY(-50%) rotate(180deg);
    }

    svg {
        height: 1.1rem;
        width: .7em;
        position: absolute;
        top: 50%;
        left: 50%;
        color: var(--color-green-6);
        transform: translate(-35%, -50%);
    }

    &.-next {
        right: -1.5rem;
    }

    &:hover {
        background-color: var(--color-grey-5);
    }

    .slider.-fixed-items & {
        width: 4.5rem;
        height: 4.5rem;

        svg {
            height: 1.9rem;
            width: 1.2rem;
        }
    }

    .slider.-overlay-controls & {
        border-radius: 1rem 0 0 1rem;
        background-color: var(--color-red-2);
        height: 4.5rem;
        width: 3.5rem;

        &.-prev {
            left: 0;
        }

        &.-next {
            right: 0;
        }

        svg {
            color: var(--color-white);
        }
    }
}

.slider__list {
    display: flex;
    margin: 0;
    padding: 0;

    list-style: none;
}

.slider__track {
    width: calc(100% + (2 * #{$side-padding}));
    margin-left: -$side-padding;
    box-sizing: border-box;
    padding: 0 2rem;

    overflow: hidden;
    overflow-x: scroll;
}

.slider__items {
    display: flex;
    margin: 0;
    padding: 0;
    gap: $item-margin;

    list-style: none;

    > * {
        margin: 0;
        padding: 0;
    }

    .slider.-fixed-items & {
        > * {
            flex: 0 0 17rem;
        }
    }
}

.slider.-fixed-items {
    @for $i from 1 through 4 {
        &.-items-#{$i} .slider__items {
            > * {
                flex-basis: calc(((100vw - #{$side-padding} * 2) - (#{$item-margin} * (#{$i} - 1))) / (#{$i} + .3));
            }
        }
    }
}

.slider__item {
    > * {
        height: 100%;
        width: 100%;
    }

    // Ensures there is additional scroll space at the end of the list.
    &:last-child {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            right: -$side-padding;
            width: $side-padding;
            height: 100%;
        }
    }
}

@media (--screen-from-md) {
    .slider.-fixed-items {
        @for $i from 1 through 4 {
            &.-items-#{$i} .slider__items {
                > * {
                    flex-basis: calc((100% - (#{$item-margin} * (#{$i} - 1))) / #{$i});
                }
            }
        }
    }

    .slider {
        &.-with-controls {
            margin-left: 4rem;
            margin-right: 2rem;
        }

        &.-fixed-items {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        &.-overlay-controls {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .slider__control {
        &.-prev {
            left: -4rem
        }

        .slider.-with-controls & {
            display: block;
        }

        .slider.-fixed-items & {
            &.-prev {
                left: -2rem;
            }
        }

        .slider.-overlay-controls & {
            &.-prev {
                left: 0;
            }

            &.-next {
                right: 0;
            }
        }
    }

    .slider__track {
        width: auto;
        margin-left: 0;
        padding: 0;

        overflow: hidden;
    }

    .slider__item {
        &::after {
            display: none;
        }
    }
}

@media (--screen-from-lg) {
    .slider__items {
        .slider.-fixed-items & {
            > * {
                flex-basis: calc((100% - ($item-margin * 3)) / 4);
            }
        }
    }
}

@media (--screen-from-xxl) {
    .slider__control {
        .slider.-overlay-controls & {
            &.-prev {
                left: 0;
            }

            &.-next {
                right: 0;
            }
        }

        .slider.-fixed-items & {
            &.-prev {
                left: -2.25rem;
            }

            &.-next {
                right: -2.25rem;
            }
        }
    }
}
