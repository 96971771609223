.footer-info-block__back-to-top>.button {
    position: absolute;
    z-index: 1;
    right: 2.5rem;
    bottom: 2.5rem;
    font-size: 1.6rem;
    margin: auto;
    background-color: var(--color-red-2);
    text-transform: uppercase;
    padding: 0.6rem 1.6rem;

    display: none;
}

@media (--screen-from-md) {
    .footer-info-block__back-to-top>.button {
        display: block;
    }
}
