.products-overview-types {
    padding-left: 2.3rem;

    font-family: var(--font-belleza);
    position: relative;
}

.products-overview-types__icon {
    width: 1.3rem;
    height: 1.3rem;

    position: absolute;
    left: 0;
    top: .7rem;
}

.products-overview-types__list {
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-wrap: wrap;
}

.products-overview-types__list-item {
    line-height: 2.2rem;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

.products-overview-types__label, .products-overview-types__anchor {
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-transform: uppercase;
}

.products-overview-types__label {
    color: var(--color-red-background);
}
