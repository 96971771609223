.gallery-items {
    display: flex;
    flex-wrap: nowrap;
    width: 5000%;
    height: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    transition: transform .3s ease;
}

.gallery-items__item {
    display: flex;
    width: 2%;
    flex-shrink: 0;
    flex-grow: 0;

    .gallery-items.-fit-screen & {
        // 30rem is roughly the content above the gallery on mobile.
        height: calc(100vh - 30rem);
        min-height: 35rem;
        max-height: 64rem;
    }
}

@media (--screen-from-md) {
    .gallery-items__item {
        .gallery-items.-fit-screen & {
            height: auto;
            min-height: 0;
        }
    }
}
