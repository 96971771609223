.add-to-cart-popup-package {
    margin-top: 2rem;
}

.add-to-cart-popup-package {
    display: grid;
    grid-template-areas:
        "package-image package-details"
        "package-image package-order"
    ;

    padding: 2rem;
    border: 0.1rem solid var(--color-grey-5);
}

.add-to-cart-popup-package__image-block {
    grid-area: package-image;
    justify-self: center;
    align-self: center;

    > img {
        max-width: 4.5rem;
    }
}

.add-to-cart-popup-package__details-block {
    grid-area: package-details;
}

.add-to-cart-popup-package__name {
    font-size: 1.8rem;
}

.add-to-cart-popup-package__details {
    font-size: 1.6rem;
    color: var(--color-grey-4);
}

.add-to-cart-popup-package__order-block {
    grid-area: package-order;

    .product-order-form__button {
        --theme: var(--color-red-1);

        font-size: 1.8rem;
        padding: 1.1rem 2.4rem;
    }
}

.add-to-cart-popup-package__price {
    font-size: 1.9rem;
    font-weight: 500;
    color: var(--color-red-3);
}

@media (--screen-from-sm) {
    .add-to-cart-popup-package {
        display: flex;
        gap: 1.2rem;
    }

    .add-to-cart-popup-package__details-block {
        flex: 1;
    }

    .add-to-cart-popup-package__price {
        text-align: right;
    }
}
