.form-compound {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;

    &.-inline {
        column-gap: 1.5rem;
        flex-direction: row;
    }
}

.form-compound__option {
    position: relative;
    padding-left: 2rem;
    line-height: 0;

    input[type="radio"], input[type="checkbox"] {
        margin: 0;
        position: absolute;
        left: 0;
        top: .1rem;
    }

    .form-label {
        margin-bottom: 0;
    }
}
