.popup-dialog {
    padding: 0;

    background-color: var(--color-white);
    border-radius: 1rem;
    overflow-y: auto;
    max-height: 100%;

    &::backdrop {
        position: fixed;
        inset: 0;
        background: rgba(#46534A, .7); // var(--color-green-1)
        backdrop-filter: blur(2rem);
    }
}

.popup-dialog__header {
    padding: 2rem 5.5rem 2rem 2rem;

    position: relative;

    border-bottom: 1px solid var(--color-grey-5);
}

.popup-dialog__title {
    margin: 0;

    color: var(--color-red-1);

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
    line-height: 3rem;
}

.popup-dialog__body {
    padding: 2rem;
}

.popup-dialog__footer {
    padding: 0 2rem 2rem;
}
