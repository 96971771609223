@import '../../atoms/button/_reset';

.add-to-cart-popup-base {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;

    display: none;
    width: 100vw;
    height: 100%;

    &.-open {
        display: flex;
    }
}

.add-to-cart-popup-base__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;

    display: block;
    width: 100vw;
    height: 100vh;

    background: rgba(#46534A, .7); // var(--color-green-1)
    backdrop-filter: blur(2rem);
}

.add-to-cart-popup-base__inner {
    position: relative;
    z-index: 9999;
    width: 100%;
    height: 100%;
    margin: auto;
    display: none;

    box-sizing: border-box;

    flex-direction: column;
    max-height: 100vh;
    background: var(--color-white);
    overflow-y: auto;

    .add-to-cart-popup-base.-has-content & {
        display: flex;
    }
}

.add-to-cart-popup-base__close {
    @extend %button-reset;

    padding: 1rem;

    position: absolute;
    right: .5rem;
    top: .5rem;

    svg {
        width: 2rem;
        height: 2rem;

        color: var(--color-grey-4);
    }
}

.add-to-cart-popup-base__loader {
    position: relative;
    z-index: 9999;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;

    .add-to-cart-popup-base.-has-content & {
        display: none;
    }
}

@media (--screen-from-sm) {
    .add-to-cart-popup-base {
        padding: 0 6rem;
    }

    .add-to-cart-popup-base__inner {
        max-width: 71.6rem;
        border-radius: .8rem;
        height: fit-content;
    }
}
