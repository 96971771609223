.account-navigation-user {
    padding-top: .5rem;
}

.account-navigation-user__account, .account-navigation-user__logout {
    padding: 1rem 1.6rem 1rem 4rem;

    position: relative;

    svg {
        position: absolute;
        top: 1rem;
        left: 1.6rem;
    }
}

.account-navigation-user__account {
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--color-green-1);

    svg {
        height: 2.3rem;
        width: 1.7rem;

        top: .7rem;
    }
}

.account-navigation-user__logout {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: var(--font-weight-medium);

    svg {
        width: 1.6rem;
        height: 1.6rem;

        top: 1.3rem;

        color: var(--color-grey-3);
    }
}

.account-navigation-user__logout-link {
    text-decoration: none;
    color: var(--color-blue-1);

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}
