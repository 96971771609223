.gallery-item-image {
    display: flex;
    margin: 0 1.45rem;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-white);
    border: .1rem solid var(--color-grey-5);

    cursor: pointer;
}

.gallery-item-image__inner {
    display: flex;
    margin: auto;
    width: auto;
    height: 100%;
    max-width: 29.5rem;
}

.gallery-item-image__inner__image {
    margin: auto;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    display: block;
    cursor: zoom-in;
}

@media (--screen-from-lg) {
    .gallery-item-image__inner__image {
        max-height: 60rem;
    }
}
