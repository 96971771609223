.simple-order-block {
}

.simple-order-block__form {
    display: flex;
}

input.simple-order-block__amount-input {
    width: 7.9rem;
    height: 3.8rem;
    margin-right: 1rem;
    margin-bottom: 0;

    text-align: center;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.simple-order-block__button {
    padding: .975rem 1.95rem;
    height: 3.8rem;

    line-height: 0;
    color: var(--color-white);
    background-color: var(--color-red-1);
    transition: background-color .2s linear;

    border-radius: .4rem;

    svg {
        width: 2.1rem;
        height: 1.85rem;
    }

    &:hover, &:focus, &:active {
        background-color: var(--color-red-3);
    }
}

.simple-order-block__text {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.9rem;
}
