.account-navigation-section__title {
    padding: 1.2rem 1.6rem;
    margin: 0;
    background-color: var(--color-white-darker);

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-medium);
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--color-black);
    text-transform: uppercase;
}

.account-navigation-section__items {
    padding: 0;
    margin: 0;
    list-style: none;
}

.account-navigation-section__item {
    padding: 1.2rem 1.6rem;
    margin: 0;
    font-size: 1.4rem;
    font-weight: var(--font-weight-light);
    line-height: 2.4rem;

    &.-active {
        background-color: var(--color-red-7);
    }
}

.account-navigation-section__item-link {
    text-decoration: none;
    color: var(--color-grey-3);

    .account-navigation-section__item.-active & {
        color: var(--color-red-3);
    }

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}

@media (--screen-from-sm) {
    .account-navigation-section__title {
        padding: 0;
        margin: 0 0 1rem;

        background-color: transparent;

        font-size: 1.6rem;
        line-height: 3rem;
        text-transform: none;
    }

    .account-navigation-section__item {
        padding: 0;
        margin-bottom: .8rem;

        font-size: 1.5rem;
        line-height: 2rem;

        &.-active {
            background-color: transparent;
        }
    }
}
