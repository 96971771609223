.billing-address-selector {
    margin: 0;
    padding: 0;

    list-style: none;
}

.billing-address-selector__item {
    margin: 0 0 1rem;
    padding: 0;

    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}

.billing-address-selector__option {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1.5rem 4.5rem 1.5rem 1.9rem;
    border: none;

    cursor: pointer;

    text-align: left;

    position: relative;

    &.-selected {
        border: 1px solid var(--color-green-3);
    }
}

.billing-address-selector__option-icon {
    display: none;
    width: 2.4rem;
    height: 2.4rem;

    position: absolute;
    right: 1.9rem;
    top: 1.5rem;

    color: var(--color-green-3);

    .billing-address-selector__option.-selected & {
        display: block;
    }
}
