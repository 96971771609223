.cart-account-step {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}

@media (--screen-from-md) {
    .cart-account-step {
        --column-gap: 6rem;

        flex-direction: row;
        gap: var(--column-gap);
    }

    .cart-account-step__right {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: calc(var(--column-gap) / 2 * -1);
            top: 4rem;
            bottom: 4rem;

            border-left: 1px solid var(--color-grey-9);
        }
    }

    .cart-account-step__left, .cart-account-step__right {
        flex-basis: calc(50% - 2rem);

        hr:last-child {
            display: none;
        }
    }
}

@media (--screen-from-lg) {
    .cart-account-step {
        --column-gap: 10rem;
    }
}

@media (--screen-from-xxl) {
    .cart-account-step {
        --column-gap: 24rem;
    }
}
