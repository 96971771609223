.breadcrumbs {
    margin: 0 auto 1.3rem;
}

.breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.breadcrumbs__item {
    display: none;
    align-items: center;
    margin: 0;
    padding: 0 0 0 1.5rem;

    position: relative;

    svg {
        display: inline-block;
        width: .8rem;
        height: 1.6rem;

        position: absolute;
        left: 0;
        top: calc(50% + .1rem);
        transform: translateY(-50%) rotate(180deg);

        color: var(--color-grey-4);
    }

    &.-for-mobile {
        display: block;
    }
}

.breadcrumbs__item__label {
    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-regular);
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: var(--color-grey-3);
    text-decoration: none;
}

a.breadcrumbs__item__label {
    text-decoration: underline;
    text-underline-position: auto;
    text-underline-offset: .3rem;

    &:hover, &:focus {
        text-decoration: none;
    }
}

@media (--screen-from-lg) {
    .breadcrumbs {
        margin: 2.3rem 0;
    }

    .breadcrumbs__item {
        display: inline;
        padding: 0;
        margin: 0 .6rem .4rem 0;

        svg {
            margin-left: .6rem;
            vertical-align: -.2em;
            position: static;
            transform: none;
        }

        &:last-child {
            svg {
                display: none;
                margin-right: 0;
            }
        }

        &.-for-mobile {
            display: inline;
        }
    }
}

