@use './reset';

button {
    @extend %button-reset;
}

.button {
    @extend button;

    --theme: var(--color-orange-1);
    --text-color: var(--color-white);
    --icon-margin: 1.6rem;

    box-sizing: border-box;
    font-family: var(--default-font-family);
    font-size: 1.7rem;
    line-height: 1.9rem;
    font-weight: 300;
    padding: 1.45rem 3rem;
    background-color: var(--theme);
    color: var(--text-color);
    fill: var(--text-color);
    border-radius: .4rem;
    outline: var(--theme) solid 0;
    border: none;
    cursor: pointer;
    text-decoration: none;

    &.-icon {
        &.-link > .button__icon {
            margin-top: .1rem;
        }
    }

    &.-icon-only {
        position: relative;
        font-size: 1.8rem;
        padding: 0;
        width: 4.8rem;
        height: 4.8rem;

        > .button__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }

        &.-small {
            width: 2.9rem;
            height: 2.9rem;
        }

        &.-large {
            width: 5.5rem;
            height: 5.5rem;
            padding: 1.8rem 3rem;
        }
    }

    &.-rounded {
        border-radius: 3rem;
    }

    &.-large {
        font-size: 1.9rem;
    }

    &.-small {
        font-size: 1.4rem;
        padding: .5rem 1.3rem;
        --icon-margin: 1rem;
    }

    &.-white {
        --theme: var(--color-white);
        --text-color: var(--color-black);
    }

    &.-dark {
        --theme: var(--color-green-1);
        --text-color: var(--color-white);

        &:hover, &:focus {
            --theme: var(--color-green-2);

            &.-outline {
                --theme: var(--color-green-1);
                background-color: var(--color-green-2);
            }
        }

        &[disabled] {
            background-color: var(--color-grey-4);
        }
    }

    &.-red {
        --theme: var(--color-red-1);
        --text-color: var(--color-white);

        &:hover, &:focus {
            --theme: var(--color-red-3);
        }
    }

    &.-blue {
        --theme: var(--color-blue-1);
        --text-color: var(--color-white);
    }

    &.-red-2 {
        --theme: var(--color-red);
        --text-color: var(--color-red);

        &:hover, &:focus, &:active {
            --theme: var(--color-red);
            --text-color: var(--color-white);

            &.-outline {
                background-color: var(--color-red);
            }
        }
    }

    &.-outline {
        outline-width: .1rem;
        outline-offset: -.1rem;
        --text-color:  var(--theme);
        background-color: transparent;
    }

    &.-link {
        --text-color:  var(--theme);
        --icon-margin: 1rem;
        padding: 0;
        background-color: transparent;

        .button__text {
            text-decoration: none;
        }

        &:hover, &:focus {
            --text-color: var(--color-blue-1);
            background-color: transparent;

            .button__text {
                text-decoration: underline;
            }
        }
    }

    &.-full-width {
        width: 100%;
    }

    &:hover, &:focus {
        --theme: var(--color-orange-3);
        background-color: var(--theme);
        text-underline: none;
    }
}

.button__icon {
    margin-right: var(--icon-margin);

    .svg-icon {
        vertical-align: -.125em;
        width: 1em;
        height: 1em;
    }

    .-icon-right > & {
        margin-right: 0;
        margin-left: var(--icon-margin);
    }
}
