.pagination {
    font-size: 1.5rem;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 3.8rem;
    width: 100%;
    margin-top: 2rem;

    color: var(--color-green-1);

    a {
        color: var(--color-green-1);
        text-decoration: none;

        &[href]:hover, &[href]:focus, &[href]:active {
            border-color: var(--color-green-1);
            background-color: var(--color-grey-8);
            z-index: 1;
        }
    }
}

.pagination__summary {
    width: auto;
    padding: 0 .5rem;
    margin: 0;
    align-self: center;
    text-align: center;
    font-size: 1.5rem;

    span {
        color: var(--color-green-1);
    }
}

.pagination__list {
    display: none;
    align-items: stretch;
    margin: 0;
    padding: 0;

    list-style: none;
}

.pagination__previous, .pagination__next {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;

    border: .1rem solid var(--color-grey-6);
    border-radius: .4rem;

    .svg-icon {
        margin: auto;
        font-size: 1.3rem;
        flex: 1 1 1.3rem;
    }

    span {
        flex: 1 0 auto;
    }
}

.pagination__previous {
    padding: 0 1.3rem 0 1.2rem;
    border-radius: .4rem 0 0 .4rem;

    .svg-icon {
        margin-right: 1.5rem;
    }
}

.pagination__next {
    padding: 0 1.2rem 0 1.3rem;
    margin-left: -.1rem;
    border-radius: 0 .4rem .4rem 0;

    .svg-icon {
        margin-left: 1.5rem;
        transform: scaleX(-1);
    }
}

.pagination-item {
    display: flex;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.pagination-item__link, .pagination-item__spacer {
    display: inline-block;
    box-sizing: border-box;
    margin-left: -.1rem;
    width: 4rem;

    text-align: center;
    line-height: 3.5rem;

    border: .1rem solid var(--color-grey-6);

    .pagination-item.-current & {
        background-color: var(--color-green-2);
        border-color: var(--color-green-6);
        z-index: 2;
    }
}

@media (--screen-from-md) {
    .pagination {
        width: auto;
        margin: 2rem auto 0;
        justify-content: center;
    }

    .pagination__summary {
        display: none;
    }

    .pagination__list {
        display: flex;
    }

    .pagination__previous, .pagination__next {
        width: auto;

        span {
            display: none;
        }

        .svg-icon {
            margin: 0;
        }

        &:not([href]) {
            display: none;
        }
    }
}
