.account-address-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: stretch;
    grid-gap: 2rem;
    margin: 0;
    padding: 0;

    list-style: none;
}

.account-address-list__item {
    min-height: 25rem;
}

@media (--screen-from-md) {
    .account-address-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (--screen-from-lg) {
    .account-address-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
