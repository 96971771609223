.filter-seo {
    margin-bottom: 2rem;
}

.filter-seo__title {
    font-size: 3rem;
    color: var(--color-green-1);
}

.filter-seo__intro-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin: -.7rem 0 2rem;

    b {
        font-size: 1.5rem;
        font-weight: 500;
    }

    > *:last-child {
        margin: 0;
    }
}
