.products-overview-list {
    margin: 0;
    padding: 0;

    list-style: none;
}

.products-overview-list__item {
    width: 100%;
    margin: 0 0 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}
