@use '../base/page/body';

/*
 * Resetting everything to it's initial value,
 * so we don't have to bother handling the original style property-by-property
 *
 * Also we're including the styling for the body, as the context is our pseudo-body
 */

.transition-context, .transition-context:before, .transition-context:after {
    all: initial;
    @include body.body-base;
}

.transition-context {
    display: contents;
}

.transition-context * , .transition-context *:before, .transition-context *:after {
    all: revert;
    box-sizing: border-box;
    line-height: inherit;
}

p {
    max-width: initial;
}

