.form-label {
    margin-bottom: .9rem;
    line-height: 1.2;
    font-size: 1.4rem;
    font-weight: var(--font-weight-light);
    color: var(--color-black);

    .-invalid & {
        color: var(--color-red-6);
    }
}
