.product-detail__producer {
    background-color: var(--color-red-7);
}

.product-producer {
    max-width: 140rem;
    margin: auto;
    padding: 7rem 2rem;
}

.product-producer__media {
    flex: 1;

    .gallery-item-video {
        max-width: 100%;
        padding: 0;
    }
}

.product-producer__images-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);

    > img {
        width:  100%;

        &:first-child {
            grid-column: 1 / 5;
            grid-row: 1 / 4;
        }

        &:last-child {
            grid-column: 3 / 7;
            grid-row: 2 / 5;
        }
    }
}

.product-producer__text {
    text-align: justify;

    > .button {
        display: inline-block;
        margin-top: 3rem;
        padding-left: 1.7rem;
        padding-right: 1.7rem;
    }
}

.product-producer__title {
    text-align: initial;
    font-size: 4rem;
    line-height: 7rem;
    color: var(--color-red-1);
    margin: 1.8rem 0 2.3rem;
}

.product-producer__intro {
    font-size: 2.1rem;
    line-height: 3rem;
    color: var(--color-red-3);
    margin: 2.3rem 0;
}

.product-producer__description {
    font-size: 1.8rem;
    line-height: 3rem;
    color: var(--color-grey-3);
}

@media (--screen-from-md) {

    .product-producer__title {
        font-size: 6.5rem;
    }

    .product-producer__media {
        min-width: 40rem;
        flex: 1;
    }
}

@media (--screen-from-lg) {
    .product-producer {
        display: flex;
        gap: 6%;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .product-producer__title {
        margin-top: 0;
    }

    .product-producer__media {
        max-width: 78.7rem;
    }

    .product-producer__text {
        max-width: 54rem;
    }
}
