.product-pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.product-pricing__price {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 500;
    color: var(--color-red-3);
    white-space: nowrap;

    .product-pricing.-compact & {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
}

.product-pricing__unit {
    font-size: 1.7rem;
    font-weight: 300;
    color: var(--color-grey-3);
    vertical-align: bottom;
    margin-left: .5rem;

    .product-pricing.-compact & {
        font-size: 1.3rem;
    }
}

.product-pricing__discount {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--color-red);
}

.product-pricing__original-price {
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-grey-4);
    vertical-align: bottom;
    text-decoration: line-through;

    .product-pricing.-compact & {
        font-size: 1.4rem;
    }
}

.product-pricing__subtitle {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 400;
    margin: .5rem 0;
    color: var(--color-white);
    background-color: var(--color-red-4);
    padding: .4rem .8rem .5rem;
    border-radius: 1.1rem;

    &.-alt {
        font-weight: 500;
        color: var(--color-red-6);
        background-color: var(--color-red-7);
    }
}

.product-pricing__on-request {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--color-red-3);
}
