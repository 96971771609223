.filter-results {
    .banner {
        margin: 2.5rem 0;
    }
}

.filter-results__settings {
    display: grid;
    grid-template-areas:
    "settings-count settings-layout"
    ;
    grid-template-columns: 1fr auto;
    gap: 2.5rem 1rem;
    padding-bottom: 2.2rem;
    margin: 0 0 2.2rem;
    border-bottom: solid .1rem var(--color-grey-5);

    .filter-results.-has-sort & {
        grid-template-areas:
        "settings-count settings-layout"
        "settings-sorting settings-sorting"
        ;
    }
}

.filter-results__count {
    font-size: 1.9rem;
    font-weight: 400;
    color: var(--color-red-1);
    grid-area: settings-count;
    margin: 0;
    flex: 1 0 auto;
}

.filter-results__layout-options {
    grid-area: settings-layout;
}

.filter-results__active-filters {
    margin-bottom: 2rem;
}

.results-sorting {
    grid-area: settings-sorting;
}

.results-sorting__label {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0;
}

.results-sorting__select {
    width: 100%;
    min-width: 18rem;
}

@media (--screen-from-sm) {
    .filter-results {
        .banner {
            height: auto;
            min-height: 5.4rem;
        }

        .banner__image {
            max-width: 15rem;
            max-height: 4.5rem;
        }
    }

    .filter-results__settings {
        display: flex;
        align-items: center;
        gap: 1rem;

        .filter-results.-has-sort & {
            padding-top: 2.5rem;
        }
    }

    .results-sorting__select {
        width: auto;
    }
}

@media (--screen-xs-lg) {
    .results-sorting {
        position: relative;
    }

    .results-sorting__label {
        position: absolute;
        top: -2.2rem;
    }
}
