.wine-labels {
    --size: 1.5rem;

    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    font-size: calc(var(--size));
    font-weight: 500;
    text-transform: uppercase;
    line-height: var(--size);
    color: var(--color-green-5);

    &.-compact {
        --size: 1.1rem;
    }

    > *:not(:last-child) {
        margin-right: px-2-rem(5);
    }
}

.wine-labels__item {
    display: flex;

    a {
        color: var(--color-green-5);
        text-decoration: none;
        border: none;

        &:hover {
            color: var(--color-green-1);
        }
    }
}

.wine-labels__type-icon {
    height: var(--size);
    margin-right: .3rem;
    width: auto;
    float: left;
}

.wine-labels__info-icon {
    font-size: 1.2rem;
    color: var(--color-black);
    border-bottom: none;
    align-self: center;
    margin-left: .5rem;
}
