.account-address-list-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 2rem;

    border: 1px solid var(--color-grey-9);
}

.account-address-list-item__address {
    margin: 0 0 2rem ;
    font-size: 1.4rem;
    line-height: 1.6;
    font-style: normal;
}

.account-address-list-item__name {
    display: block;
    margin: 0 0 2.4rem;
    font-weight: var(--font-weight-medium);
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.account-address-list-item__defaults {
    margin: 0 0 2rem;
}

.account-address-list-item__default {
    padding-left: 1.6rem;
    margin-bottom: .6rem;

    font-family: var(--font-belleza);
    font-size: 1rem;
    line-height: 1.6;
    color: var(--color-green-1);
    text-transform: uppercase;

    position: relative;

    svg {
        width: 1rem;
        height: 1rem;
        color: var(--color-green-3);

        position: absolute;
        left: 0;
        top: .3rem;
    }
}

.account-address-list-item__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: auto;

}
