@import '../../atoms/button/reset';

.co-browsing-popup {
    width: 100%;
    max-width: 40rem;
}

.co-browsing-popup__buttons {
    display: flex;
    justify-content: center;
}

.co-browsing-popup__support {
    display: none;
}

.co-browsing-popup__support-code-link {
    font-weight: var(--font-weight-semibold);
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: black;
    padding: 1.5rem 3rem 1.5rem 3rem;
    border: 0.1rem solid var(--color-white);

    &:hover {
        border: 0.1rem solid var(--color-black);
        border-radius: 0.5rem;

        .co-browsing-popup__support-code-icon {
            display: block;
        }
    }
}

.co-browsing-popup__support-code {
    display: flex;
    justify-content: center;
}

.co-browsing-popup__support-code-icon {
    display: none;
    position: absolute;
    width: 1.5rem;
    right: 0;
    top: 0.2rem;
}
