.account-navigation {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-account-nav);

    &.-open {
        display: block;
    }
}

.account-navigation__backdrop {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(70, 83, 74, .5);
    backdrop-filter: blur(.5rem);
}

.account-navigation__scroll-lock {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    overscroll-behavior: contain;

    &:after {
        display: block;
        content: '';
        height: calc(100% + 1px);
    }
}

.account-navigation__close {
    position: absolute;
    top: 1.2rem;
    left: calc(100% - 26.5rem - 3.7rem);

    svg {
        color: var(--color-white);
        width: 1.7rem;
        height: 1.7rem;
    }
}

.account-navigation__user {
    border-bottom: 1px solid var(--color-red-2);
}

.account-navigation__menu {
    width: 100%;
    max-width: 26.5rem;

    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: var(--color-white);
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

@media (--screen-from-sm) {
    .account-navigation {
        display: block;

        position: static;
    }

    .account-navigation__user {
        display: none;
    }

    .account-navigation__backdrop {
        display: none;
    }

    .account-navigation__menu {
        position: static;

        background-color: transparent;
    }

    .account-navigation__section {
        margin-bottom: 1.8rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .account-navigation__close {
        display: none;
    }
}
