.form-widget.-text,
.form-widget.-textarea,
.form-widget.-select {
    font-weight: 300;
    font-family: var(--font-wotfard);
    border: .1rem solid var(--color-grey-6);
    border-radius: .4rem;
    padding: .6rem 1.3rem;
    background-color: var(--color-white);
    margin: 0;
    box-shadow: none;
    height: 3.6rem;

    &::placeholder {
        color: var(--color-grey-9);
    }

    .-invalid & {
        border-color: var(--color-red-6);
    }
}

[type="checkbox"] {
    vertical-align: text-bottom;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}
