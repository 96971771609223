.account-login-form__actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2rem;
}

.account-login-form__action-reset {
    font-size: 1.6rem;
}

.account-login-form__error {
    color: var(--color-red-6);
    font-size: 1.6rem;
    line-height: 2rem;
}

@media (--screen-from-sm) {
    .account-login-form__actions {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
