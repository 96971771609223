.loader.-bottle {
    margin-bottom: -20px;
    width: 40px;
    height: 98px;
    display: inline-block;
    position: relative;
    border: 2px solid #fff;
    box-sizing: border-box;
    color: var(--color-red-1);
    border-radius: 20px 20px 4px 4px;
    background: #fff;
    animation: fill 2s linear infinite alternate;
}
.loader.-bottle::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50% , -95%);
    border: 2px solid #FFF;
    border-bottom: none;
    background: #fff;
    width: 15px;
    height: 35px;
    animation: fillNeck 2s linear infinite alternate;
}

@keyframes fill {
    0% { box-shadow: 0 0  inset }
    50% , 100% { box-shadow: 0 -98px inset }
}


@keyframes fillNeck {
    0% , 50%{ box-shadow: 0 0  inset }
    100% { box-shadow: 0 -20px inset }
}

.loader-container {
    padding: 15px;
    background-color: rgba(70,84,58, 1);
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
