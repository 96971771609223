.product-search-card-extended {
    display: grid;
    grid-template-areas: "psce-image psce-body" "psce-footer psce-footer";
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 9.5rem 1fr;
    width: 100%;
    padding: 1.7rem .5rem 3rem;
    border: 1px solid var(--color-grey-8);
    word-break: break-word;
    background-color: var(--color-white);

    position: relative;
}

.product-search-card-extended__label {
    padding: .4rem .6rem;

    font-family: var(--font-belleza);
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: var(--font-weight-normal);
    letter-spacing: .1rem;
    color: var(--color-red-7);
    text-transform: uppercase;
    background-color: var(--color-red-3);
    border-radius: .3rem;

    position: absolute;
    left: 1.3rem;
    top: 1.7rem;

    .product-search-card.-superpromo & {
        background-color: var(--color-green-1);
    }
}

.product-search-card-extended__reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: .3rem 0 0;
    padding: 0;
    list-style: none;
}

.product-search-card-extended__review-item {
    margin: 0 .5rem .2rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: -.03rem;
    color: var(--color-green-1);
    text-align: right;

    abbr {
        border: none;
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.product-search-card-extended__review-score {
    display: inline-block;
    margin-left: .2rem;
    color: var(--color-grey-3);
    font-weight: var(--font-weight-normal);
}

.product-search-card-extended__anchor {
    text-decoration: none;
}

.product-search-card-extended__top {
    grid-area: psce-image;
    margin: 0 auto 1.4rem;
    max-width: 14.9rem;
}

.product-search-card-extended__body {
    grid-area: psce-body;
}

.product-search-card-extended__title, .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-light);
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: var(--color-black);
}

.product-search-card-extended__title {
    margin: .7rem 0 0;

    .product-search-card-extended__title-year {
        font-size: 1.7rem;
        line-height: 2.1rem;
        font-weight: var(--font-weight-medium);
    }
}

.product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
    margin: .7rem 0 0;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--color-grey-4);
    letter-spacing: -.03rem;
}

.product-search-card-extended__labels {
    display: flex;
    justify-content: flex-start;
    margin: .7rem 0 0;

    // SAD :-(
    > .wine-labels {
        justify-content: flex-start;
    }
}

.product-search-card-extended__info-toggle {
    display: inline-block;
    margin: 1.1rem 0 0;

    font-weight: var(--font-weight-light);
    color: var(--color-blue-1);
    font-size: 1.4rem;
    line-height: 1.9rem;
}

.product-search-card-extended__short-description {
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: var(--color-grey-4);
}

.product-search-card-extended__short-details {
    margin: 1.1rem 0 0;
    padding-top: .5rem;

    border-top: 1px solid var(--color-grey-5);
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--color-green-1);
}

.product-search-card-extended__short-detail-item {
    margin-bottom: .5rem;

    label {
        color: var(--color-grey-4);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.product-search-card-extended__footer {
    grid-area: psce-footer;
    padding-top: 2rem;
    margin-top: auto;
}

.product-search-card-extended__pricing {
    display: flex;
    justify-content: center;
}

.product-search-card-extended__order {
    display: flex;
    justify-content: center;
    margin: 1.1rem 0 0;
}

.product-search-card-extended__stock {
    display: flex;
    justify-content: center;
    margin: 1.1rem 0 0;
}

@media (--screen-from-sm) {
    .product-search-card-extended {
        display: grid;
        grid-template-columns: 13.8rem;
        grid-column-gap: 1rem;
        grid-row-gap: 2.5rem;
        grid-template-areas: "psce-image psce-body" "psce-image psce-footer";
        grid-template-rows: min-content 1fr;

        align-items: flex-start;
        padding: 2.1rem 1rem;
    }

    .product-search-card-extended__top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }

    .product-search-card-extended__image {
        display: inline-block;
        margin: 0 auto;

        img {
            margin: auto;
            max-height: 20.2rem;
        }
    }

    .product-search-card-extended__reviews {
        margin: .3rem -.5rem 0;
    }

    .product-search-card-extended__title, .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
        margin: 0;
        font-size: 1.7rem;
        line-height: 2.1rem;
    }

    .product-search-card-extended__subtitle, .product-search-card-extended__subtitle a {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }

    .product-search-card-extended__labels {
        margin: .5rem 0 0;
    }

    .product-search-card-extended__short-description {
        margin: 1.1rem 0 0;
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .product-search-card-extended__short-details {
        font-size: 1.3rem;
        line-height: 1.6rem;
    }

    .product-search-card-extended__info {
        display: block;
    }

    .product-search-card-extended__footer {
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-areas: "psce-footer-price psce-footer-order" "psce-footer-stock psce-footer-stock";
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    .product-search-card-extended__pricing {
        grid-area: psce-footer-price;
    }

    .product-search-card-extended__order {
        grid-area: psce-footer-order;
        margin: 0;
    }

    .product-search-card-extended__stock {
        grid-area: psce-footer-stock;
        justify-content: flex-start;
        margin: 0;
    }
}

@media (--screen-from-sm) {
    .product-search-card-extended {
        padding-right: 2.4rem;
        grid-row: 1.1rem;
        grid-template-areas: "psce-image psce-body psce-footer";
        grid-template-rows: min-content;
    }

    .product-search-card-extended__footer {
        grid-template-areas: "psce-footer-price" "psce-footer-order" "psce-footer-stock";
        justify-content: flex-end;
    }

    .product-search-card-extended__pricing {
        justify-content: flex-end;
    }

    .product-search-card-extended__order {
        justify-content: flex-end;
    }
}
