.product-card {
    box-sizing: border-box;
    word-break: break-word;
    display: grid;
    gap: 1rem;
    grid-template-columns: 5.7rem auto;
    grid-template-rows: auto min-content;
    grid-template-areas:
    "card-grid-image card-grid-info"
    "card-grid-order card-grid-order";
    background-color: var(--color-white);
    border: .1rem solid var(--color-red-2);
    padding: 2rem 1rem 3rem;
    position: relative;

    &.-portrait {
        text-align: center;
        grid-template-columns: auto;
        gap: 1.4rem;
        grid-template-areas:
            "card-grid-image"
            "card-grid-info"
            "card-grid-order ";
        grid-template-rows: min-content auto min-content;
        padding: 1.7rem 1.6rem 3rem;
        position: relative;
    }
}

.product-card__anchor {
    text-decoration: none;

    img {
        width: auto;
    }
}

.product-card__image {
    grid-area: card-grid-image;

    .product-card.-portrait & {
        height: 25.2rem;
    }

    img {
        max-width: 100%;

        .product-card.-portrait & {
            max-height: 25.2rem;
        }
    }
}

.product-card__info {
    grid-area: card-grid-info;

    margin-top: 3.9rem;

    .product-card.-portrait & {
        margin: 0;
    }
}

.product-card__reviews {
    position: absolute;
    right: 1.4rem;
    top: .95rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    justify-content: flex-end;

    .product-card.-portrait & {
        top: 1.4rem;
        flex-direction: column;
        align-items: flex-end;
    }
}

.product-card__review-item {
    font-size: 1.3rem;
    font-weight: 300;
}

.product-card__review-score {
    font-weight: 400;
}

.product-card__main-title {
    margin-bottom: .7rem;
    font-size: 1.8rem;
    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-light);
    line-height: 1.2;
}

.product-card__title-year {
    font-weight: var(--font-weight-medium);
}

.product-card__subtitle {
    font-size: 1.6rem;
    line-height: 1.3;
    color: var(--color-grey-4);
    margin: 1rem 0 0 0;
}

.product-card__order-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-area: card-grid-order;
    justify-self: flex-start;

    .product-card.-portrait & {
        justify-self: center;
        justify-content: center;
    }
}

.product-card__pricing {
    line-height: 1;
}

.product-card__order {
    margin-top: 1.2rem;
}

.product-card__labels {
    display: flex;
    margin-top: 1rem;

    .product-card.-portrait & {
        justify-content: center;
    }

    .product-order-form__amount-input {
        height: auto;
    }
}

@media (--screen-from-md) {
    .product-card {
        padding: 1rem 1rem 4rem;
        grid-template-areas:
        "card-grid-image card-grid-info"
        "card-grid-image card-grid-order";
        grid-template-columns: 10.6rem auto;

        &.-portrait {
            padding: 1.4rem 1rem 4rem;
        }
    }

    .product-card__reviews {
        left: 0;
        top: -4.5rem;

        justify-content: start;

        .product-card.-portrait & {
            position: absolute;
        }
    }

    .product-card__info {
        margin-top: 7.4rem;
        position: relative;

        .product-card.-portrait & {
            position: static;
        }
    }

    .product-card__main-title {
        font-size: 1.9rem;
    }
}
