.professional-review-badge {
    --size: 5rem;
    padding: 0 var(--size) 0 0;
    gap: 0;
    cursor: default;
    display: flex;
    align-items: center;
    max-width: fit-content;
    width: 0;
    height: var(--size);
    background-color: var(--color-black);
    color: var(--color-white);
    border-radius: 14rem;
    transition-property: width, gap, padding;
    transition-duration: 300ms, 100ms, 300ms;
    transition-delay: 0ms;
    transition-timing-function: ease-in, ease-out;
    justify-content: space-between;
    position: relative;

    &.-large {
        --size: 8.4rem;
    }

    &.-has-name:hover {
        width: 100%;
        gap: 2.5rem;
        transition-delay: 0ms, 300ms;
        padding: 0 var(--size) 0 calc(var(--size) / 2);
    }

    &:not(.-has-name) {
        padding: 0;
        min-width: var(--size);
        width: 100%;
        justify-content: center;

        > .professional-review-badge__score {
            width: auto;
        }
    }
}

.professional-review-badge__name {
    overflow: hidden;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
    white-space: nowrap;

    .professional-review-badge:hover & {
        opacity: 1;
    }
}

.professional-review-badge__score {
    font-weight: 400;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    right: 2.5rem;
}

.professional-review-badge__score-top {
    font-size: 2.1rem;
    line-height: 2rem;
    letter-spacing: -0.25rem;
    margin-left: -0.25rem;

    .professional-review-badge.-large & {
        font-size: 3.3rem;
    }
}

.professional-review-badge__score-bottom {
    font-size: 1.3rem;
    line-height: 1rem;
    font-weight: 300;

    .professional-review-badge.-large & {
        font-size: 2.3rem;
        line-height: 2rem;
    }
}
