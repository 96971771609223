.products-filter-banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 1.5rem;
    margin-bottom: 2rem;
    background-color: var(--color-red-7);
    border-radius: 1rem;
}

.products-filter-banner__image {
    margin: 0 0 1rem;
    width: 100%;

    img {
        display: block;
    }
}

.products-filter-banner__title {
    margin: 0;
    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-light);
    font-size: 1.7rem;
    line-height: 2rem;
    color: var(--color-red-3);
}

.products-filter-banner__description {
    margin: .4rem 0 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: var(--color-red-1);
    text-transform: uppercase;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.products-filter-banner__link {
    margin: .5rem 0 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--color-red-9);
}

@media (--screen-from-sm) {
    .products-filter-banner {
        padding: 1rem 2rem;
        align-items: center;
        flex-direction: row;
    }

    .products-filter-banner__image {
        flex: 0 0 11.1rem;
        margin: 0 2.2rem 0 0;
    }

    .products-filter-banner__text {
        margin: 0;
        flex: 1 1 auto;
    }

    .products-filter-banner__link {
        flex: 0 0 auto;
        margin: 0 0 0 4rem;
        font-size: 1.6rem;
        line-height: 2rem;
    }
}
