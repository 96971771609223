.product-card-stock {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: 300;
}

.product-card-stock__label {
    display: inline-block;
    padding: .5rem 1.3rem;
    width: fit-content;
    border-radius: .1rem;
    position: relative;

    > .svg-icon {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: .5rem;
        position: absolute;
        top: calc(.5rem + .2em);
        left: 1.3rem;
    }

    &.-ok {
        padding: .5rem 1.3rem .5rem 3.4rem;
        color: var(--color-green-3);
        background-color: var(--color-green-4);
    }

    &.-warning {
        padding: .5rem 1.3rem .5rem 3.4rem;
        color: var(--color-orange-1);
        background-color: var(--color-orange-2-opaque);
    }

    &.-error {
        color: var(--color-red);
        background-color: var(--color-grey-1);
    }

    &.-temp-error {
        color: var(--color-red);
        background-color: var(--color-orange-2-opaque);
    }
}

.product-card-stock__deliverable-from {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.85rem;
    margin: 0 0 .5rem;
    text-align: center;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        top: .2em;
    }
}

@media (--screen-from-sm) {
    .product-card-stock {
        &.-extended {
            align-items: flex-end;
        }
    }

    .product-card-stock__deliverable-from {
        .product-card-stock.-extended & {
            text-align: right;
        }
    }
}
