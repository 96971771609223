.product-years {
    background-color: var(--color-white);
}

.product-years__inner {
    max-width: 94rem;
    margin: auto;
    padding: 4.5rem .2rem 6rem;
}

.product-years__title {
    font-size: 2.4rem;
    line-height: 3rem;
    padding: 0 3.3rem;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-green-1);
    margin: 0 0 2rem;
}

.product-years__product {
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 2rem .9rem;
    grid-template-areas:
        "grid-image grid-name"
        "grid-image grid-year"
        "grid-image grid-content"
        "grid-image grid-price"
        "grid-image grid-link"
    ;
    grid-template-rows: auto auto auto 1fr;
    justify-content: center;
}

.product-years__product-image {
    grid-area: grid-image;
    max-height: 16rem;

    > img {
        width: 5rem;
        margin-right: 1.8rem;
        height: auto;
    }
}

.product-years__product-name {
    grid-area: grid-name;
    font-size: 1.9rem;
}

.product-years__product-subtitle {
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    color: var(--color-grey-4);
}

.product-years__product-year {
    grid-area: grid-year;
    font-size: 1.9rem;
    line-height: 2.4rem;
}

.product-years__product-content {
    grid-area: grid-content;
    font-size: 1.9rem;
    line-height: 2.4rem;
    text-align: center;
}

.product-years__product-price {
    grid-area: grid-price;
    margin: 1rem 0;
    align-self: center;

    .product-price__price {
        font-size: 2rem;
        line-height: 2.8rem;
    }

    .product-price__subtitle {
        margin: .3rem 0;
    }

    .product-price__on-request {
        font-size: 1.8rem;
    }

    .product-price__discount {
        font-size: 1.6rem;
    }
}

.product-years__product-link {
    grid-area: grid-link;
    width: fit-content;
}

.product-years__product-separator {
    margin: .2rem 0;
    border-style: solid;
    border-color: var(--color-red-2);
    border-bottom-width: 0;
}

.product-years__subscribe-form {
    text-align: center;
    margin-top: 2rem;

    * {
        font-family: var(--font-belleza);
        text-transform: uppercase;
    }
}

.product-years-subscribe-button {
    font-size: 1.4rem;
    line-height: 2.2rem;
    cursor: pointer;
    background-color: var(--color-white-darker);
    border: none;
    border-radius: 6rem;
    padding: .1rem 2rem;

    &:hover {
        background-color: var(--color-grey-4);
        text-decoration: none;

        > .product-years-subscribe-button__content-text {
            text-decoration: none;
            color: var(--color-white-darker)
        }
    }

    &.-is-subscribed {
        background-color: var(--color-green-4);
        padding: .4rem 1.2rem;

        > .product-years-subscribe-button__content {
            color: var(--color-green-1);

            > .product-years-subscribe-button__content-text {
                text-decoration: none;
            }

            > .svg-icon {
                color: var(--color-green-3);
                margin-right: .5rem;
            }
        }

        > .product-years-subscribe-button__content.-hover {
            > .svg-icon {
                color: var(--color-red-3);
            }
        }

        &:hover {
            background-color: var(--color-grey-8);

            > .product-years-subscribe-button__content.-hover {
                display: initial;
            }

            > .product-years-subscribe-button__content:not(.-hover) {
                display: none;
            }
        }
    }
}

.product-years-subscribe-button__content-text {
    text-decoration: underline;
}

.product-years-subscribe-button__content {
    &.-hover {
        display: none;
    }
}

@media (--screen-from-sm) {
    .product-years__product {
        column-gap: 2.7rem;
        grid-template-columns: auto auto auto;
        grid-template-areas:
        "grid-image grid-name grid-link"
        "grid-image grid-year grid-link"
        "grid-image grid-content grid-link"
        "grid-image grid-price grid-link"
        ;
        grid-template-rows: auto auto 1fr;
    }

    .product-years__product-price {
        margin-bottom: 0;
    }
}

@media (--screen-sm-md) {
    .product-years__product-image {
        max-height: 13rem;
    }

    .product-years__product-link {
        align-self: center;
    }
}

@media (--screen-from-md) {
    .product-years__inner {
        padding: 6rem .2rem;
    }

    .products-year__title {
        line-height: 6.4rem;
        margin: 0;
    }

    .product-years__product {
        padding: 2rem 0;
        grid-template-columns: 4.5rem auto min-content auto 15rem;
        grid-template-areas: "grid-image grid-name grid-year grid-content grid-price grid-link";
        grid-template-rows: 1fr;
        align-items: center;
        justify-content: unset;
    }

    .product-years__product-subtitle {
        line-height: 1rem;
    }

    .product-years__product-image {
        > img {
            width: 100%;
            height: auto;
        }
    }

    .product-years__product-price {
        justify-self: flex-end;
        margin: 0;

        .product-price__subtitle {
            margin-left: auto;
        }
    }
}
