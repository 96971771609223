.gallery-thumbnail-video {
    height: 100%;
    width: 100%;
    position: relative;
}

.gallery-thumbnail-video__icon {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    content: '';

    border-radius: 50%;
    background-color: var(--color-blue-light-5);

    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    right: 0;
    bottom: 0;

    svg {
        display: block;
        width: 2.4rem;
        height: 2.4rem;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-44%, -49%);

        color: var(--color-blue-light-1);
    }
}
