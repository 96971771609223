.cart-billing-address-selector-popup {
    max-width: 35rem;
}

.cart-billing-address-selector-popup__selector {
    width: 36rem;
    max-width: 100%;
    margin: 0 0 2rem;

    position: relative;

    &.-is-updating {

    }
}

.cart-billing-address-selector-popup__empty {
    margin-top: 0;
}

.cart-billing-address-selector-popup__new {
    width: 100%;
}

