.suggested-products {
    --slider-max-width: 116.7rem;
    margin: auto;
    padding: 4rem 2rem 4.5rem;
    background-color: var(--color-red-7);
     > * {
         max-width: var(--slider-max-width);
     }
}

.suggested-products__title {
    font-size: 2.1rem;
    line-height: 2.4;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-red-3);
    margin: 0 auto 1.9rem;
}

.suggested-products__slider {
    margin: 0 auto;
    max-width: 140rem;
}

@media (--screen-from-md) {
    .suggested-products {
        padding: 5rem 0 6rem;
    }

    .suggested-products__title {
        font-size: 2.4rem;
        line-height: 1;
        margin: 0 auto 3.9rem;
    }

    .suggested-products__slider {
        gap: 3.6rem;
    }
}
