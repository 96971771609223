.product-short-details {
    display: grid;
    grid-template-columns: 4.1rem auto;
    grid-gap: 0.9rem;
    align-items: start;
    padding: .9rem 0;
    border: 0 solid var(--color-grey-5);
    border-top-width: .1rem;
    border-bottom-width: .1rem;
}

.product-short-details__icon-wrapper {
    align-self: flex-start;
    margin: .55rem auto 0;
}

.product-short-details__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-grey-4);
    text-align: center;
    margin: auto;
}

.product-short-details__content {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.95rem;
    color: var(--color-green-1);
    text-transform: capitalize;

    > label {
        display: block;
        font-size: 1.3rem;
        line-height: 1.58rem;
        color: var(--color-grey-4);
    }
}

.grape-type {
    white-space: nowrap;
    line-height: 1.3;

    > a {
        color: var(--color-green-1);
    }
}

@media (--screen-from-md) {
    .product-short-details {
        max-width: 40rem;
    }
}
