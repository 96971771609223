.gallery-plus-tile {
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    position: relative;
}

.gallery-plus-tile__inner {
    display: flex;
    align-items: center;
    justify-content: center;

    border: .1rem solid var(--color-gray-cool-6);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span {
        display: block;

        font-family: var(--font-asap);
        font-weight: var(--font-weight-bold);
        font-size: 3.3rem;
        line-height: 1;
        text-align: center;
        color: var(--color-gray-cool-4);
    }
}
