.product-detail__header {
    max-width: calc(var(--default-content-width) + (var(--side-padding) * 2));
    margin: auto;
    padding: 0 var(--side-padding);
}

.product-detail-header__grid-gallery {
    grid-area: grid-gallery;
    position: relative;
}

.product-detail-header__grid-title {
    grid-area: grid-title;
}

.product-title__customer-reviews-link {
    color: blue;
}

.product-title__reviews {
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.product-detail-header__grid-details {
    grid-area: grid-details;

    > .wine-labels {
        margin-top: 2.15rem;
    }

    > .product-short-details {
        margin: 3rem 0;
    }
}

.product-detail-header__favorite-button {
    &.-desktop {
        > .button {
            --theme: var(--color-red-7);

            > .button__icon {
                top: calc(50% + .1rem);
            }

            &:hover, &:focus {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 512 512'><path fill='%239A0443' d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'></path></svg>");
                background-repeat: no-repeat;
                background-position: center;
                color: inherit;

                &.-is-favorite {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 512 512'><path fill='%239A0443' d='M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z'></path></svg>");

                    > .button__icon {
                        display: none;
                    }
                }
            }
        }
    }


    &.-mobile {
        > .button {
            --theme: var(--color-grey-3);
            font-weight: 300;
            margin: 0 auto 2.25rem;

            > .button__text {
                text-decoration: none;
                border-bottom: .1rem solid transparent;
            }

            &:hover, &:focus {
                background-color: inherit;

                > .button__text {
                    border-bottom-color: var(--text-color);
                }
            }
        }
    }

    > .button {
        --theme: var(--color-red-7);
    }

    .button__icon {
        color: var(--color-red-9);
    }
}

.product-detail-header__professional-reviews {
    position: absolute;
    top: 1rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    gap: .6rem;
    z-index: var(--z-index-page);
    align-content: flex-end;
    align-items: flex-end;
}

.product-detail-header__description {
    margin: 2.15rem 0 3rem;
}

.product-detail-menu {
    display: none;
    justify-content: center;
    margin: auto;
    padding: 2rem 0;
    border-top: .1rem solid var(--color-grey-5);
    border-bottom: .1rem solid var(--color-grey-5);
    background-color: var(--color-white);
}

.product-detail-menu__item {
    font-size: 1.8rem;
    line-height: 1.9rem;
    color: var(--color-red-3);
    padding: 1.6rem 2rem;
    border-radius: .3rem;
    border: none;
    text-align: center;
    text-decoration: none;

    &:hover {
        background-color: var(--color-grey-8);
    }
}

.product-detail__footer {
    display: none;
    max-width: calc(var(--default-content-width) + (var(--side-padding) * 2));
    margin: auto;
    padding: 5rem var(--side-padding);
}

.product-detail-footer__grid-image {
    grid-area: grid-gallery;
    justify-self: end;

    > img {
        max-width: 16rem;
        max-height: 28rem;
        margin: auto;
    }
}

.product-detail-footer__grid-title {
    grid-area: grid-title;
    width: 100%;

    .product-title, .product-title__year {
        font-size: 2.7rem;
        line-height: 3.1rem;
    }

    .product-title {
        margin: .5rem 0 0;
    }
}

.product-detail-footer__grid-details {
    grid-area: grid-details;

    > .product-price {
        margin-bottom: 1rem;
    }

    > .product-stock {
        margin: 1rem 0 2rem;
    }
}

.product-detail__sticky_footer {
    position: sticky;
    bottom: 0;
    padding: 1rem 1.6rem 1rem;
    background-color: var(--color-grey-2);
}

.sticky-footer__subheader {
    font-size: 1.3rem;
    font-weight: 300;
    color: var(--color-grey-6);
    margin: 0;

    > span, a {
        color: var(--color-grey-3);
    }
}

.sticky-footer__title {
    font-size: 1.8rem;
    line-height: 2rem;
    color: var(--color-green-1);
    margin: .9rem 0;
}

.sticky-footer__year {
    color: var(--color-black);
}

.sticky-footer__order {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-price {
        flex: 1;

        .product-price__price {
            font-size: 1.6rem;
        }

        .product-price__subtitle {
            font-size: 1.4rem;
        }

        .product-price__unit {
            font-size: 1.3rem;
        }
    }

    .product-order-form__amount-input {
        height: auto;
        padding: .8rem;
    }
}

.product-detail__back-to-top {
    padding-top: 2rem;

    > .button {
        font-size: 1.6rem;
        margin: auto;
        background-color: var(--color-red-2);
        text-transform: uppercase;
        padding: 0.6rem 1.6rem;
    }
}

.product-detail-header__buy {
    margin-bottom: 2rem;
}

.product-detail-header__pricing {
    grid-area: price;
}

.product-detail-header__order-form {
    margin-top: 1rem;
    grid-area: order;
}

.product-detail-header__stock {
    margin-top: 1rem;
    grid-area: stock;
}

@media (--screen-from-sm) {
    .product-detail-header__buy {
        display: grid;
        grid-template-areas:
        "price order"
        "price stock"
        ;
        column-gap: 2rem;
        align-items: center;
        margin: 3rem 0 2rem;
    }
}

@media (--screen-from-md) {
    .product-detail__header {
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            "grid-gallery grid-title"
            "grid-gallery grid-details";
    }

    .product-detail-header__buy {
        display: block;
    }

    .product-detail__sticky_footer {
        display: none;
    }

    .product-detail__back-to-top {
        display: none;
    }

    .product-detail-menu {
        display: flex;
    }

    .product-detail__footer {
        display: grid;
        grid-gap: 2rem 7rem;
        grid-template-columns: auto 52rem;
        grid-template-areas:
        "grid-gallery grid-title"
        "grid-gallery grid-details";

        .product-order-form__amount-label {
            flex-basis: auto;
        }
    }
}

@media (--screen-from-lg) {
    .product-detail__header {
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: 1fr 52rem;
        grid-template-areas:
            "grid-gallery grid-title"
            "grid-gallery grid-details";
    }

    .product-detail-menu__item {
        font-size: 2.3rem;
    }
}

.sticky-footer__context.-background {
    background-color: #fafafa;
}
