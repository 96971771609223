.intl-tel-input {
    display: block;
    &.allow-dropdown.separate-dial-code {
        .selected-flag {
            display: flex;
        }

        .selected-dial-code {
            padding-left: 5px;
        }
    }
}
