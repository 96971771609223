.product-order-form {
    display: flex;
    flex-wrap: wrap;
    gap: .9rem 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    align-items: center;
}

.product-order-form__text {
    font-size: 1.6rem;
    font-weight: 300;
}

.product-order-form__footer-text {
    font-size: 1.3rem;
    font-weight: 300;
}

.product-order-form__gift-option {
    display: none;
    font-size: 1.6rem;
    font-weight: 300;
    flex-basis: 100%;

    .product-order-form:not(.-compact) > & {
        display: initial;
    }
}

.product-order-form__amount-label {
    flex-basis: 100%;
    display: none;
}

.product-order-form__amount-input {
    box-shadow: none;
    box-sizing: content-box;
    text-align: center;
    width: 100%;
    max-width: 4.2rem;
    height: auto;
    margin: 0;
    padding-right: .1rem;
    align-self: stretch;
}

.product-order-form__button {
    padding: 1.45rem 3rem;
}

@media not all and (any-hover: hover) {
    .product-order-form__amount-input {
        padding-right: 1.6rem;
    }
}

@media (--screen-xs-sm) {
    .product-order-form__button {
        padding: 1.4rem 1.55rem;
    }
}

@media (--screen-from-md) {
    .product-order-form__button {
        padding: 1.45rem 2rem;
    }
}

@media (--screen-from-lg) {
    .product-order-form__amount-label {
        flex-basis: auto;
        display: block;
    }
}
