@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-bolditalic-webfont.eot');
    src: url('./wotfard/wotfard-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-bolditalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-bolditalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-extralightitalic-webfont.eot');
    src: url('./wotfard/wotfard-extralightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-extralightitalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-extralightitalic-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-lightitalic-webfont.eot');
    src: url('./wotfard/wotfard-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-lightitalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-lightitalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-mediumitalic-webfont.eot');
    src: url('./wotfard/wotfard-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-mediumitalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-mediumitalic-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-regularitalic-webfont.eot');
    src: url('./wotfard/wotfard-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-regularitalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-regularitalic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-semibolditalic-webfont.eot');
    src: url('./wotfard/wotfard-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-semibolditalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-thinitalic-webfont.eot');
    src: url('./wotfard/wotfard-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-thinitalic-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-thinitalic-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-bold-webfont.eot');
    src: url('./wotfard/wotfard-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-bold-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-bold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-extralight-webfont.eot');
    src: url('./wotfard/wotfard-extralight-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-extralight-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-extralight-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-light-webfont.eot');
    src: url('./wotfard/wotfard-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-light-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-medium-webfont.eot');
    src: url('./wotfard/wotfard-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-medium-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-regular-webfont.eot');
    src: url('./wotfard/wotfard-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-regular-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-semibold-webfont.eot');
    src: url('./wotfard/wotfard-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-semibold-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-semibold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Wotfard';
    src: url('./wotfard/wotfard-thin-webfont.eot');
    src: url('./wotfard/wotfard-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('./wotfard/wotfard-thin-webfont.woff2') format('woff2'),
    url('./wotfard/wotfard-thin-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
