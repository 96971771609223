.product-search-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.7rem 1rem 3rem;
    border: 1px solid var(--color-grey-8);
    word-break: break-word;
    background-color: var(--color-white);

    position: relative;
}

.product-search-card__label {
    padding: .4rem .6rem;

    font-family: var(--font-belleza);
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: var(--font-weight-normal);
    letter-spacing: .1rem;
    color: var(--color-red-7);
    text-transform: uppercase;
    background-color: var(--color-red-3);
    border-radius: .3rem;

    position: absolute;
    left: 1.3rem;
    top: 1.7rem;

    .product-search-card.-superpromo & {
        background-color: var(--color-green-1);
    }
}

.product-search-card__reviews {
    margin: 0;
    padding: 0;
    list-style: none;

    position: absolute;
    right: 1.3rem;
    top: 1.7rem;
}

.product-search-card__review-item {
    margin: 0 0 .2rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: -.03rem;
    color: var(--color-green-1);
    text-align: right;

    abbr {
        border: none;
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.product-search-card__review-score {
    display: inline-block;
    margin-left: .2rem;
    color: var(--color-grey-3);
    font-weight: var(--font-weight-normal);
}

.product-search-card__anchor {
    text-decoration: none;
}

.product-search-card__image {
    margin: 0 auto 1.4rem;
    max-width: 14.9rem;
}

.product-search-card__title, .product-search-card__subtitle , .product-search-card__subtitle a {
    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-light);
    text-align: center;
    font-size: 1.7rem;
    line-height: 2.1rem;
    color: var(--color-black);
}

.product-search-card__title {
    margin: .7rem 0 0;

    .product-search-card__title-year {
        font-size: 1.7rem;
        line-height: 2.1rem;
        font-weight: var(--font-weight-medium);
    }
}

.product-search-card__subtitle, .product-search-card__subtitle a {
    margin: .7rem 0 0;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: var(--color-grey-4);
    letter-spacing: -.03rem;
}

.product-search-card__labels {
    display: flex;
    justify-content: center;
    margin: .7rem 0 0;
}

.product-search-card__footer {
    padding-top: 2rem;
    margin-top: auto;
}

.product-search-card__pricing {
    display: flex;
    justify-content: center;
}

.product-search-card__order {
    display: flex;
    justify-content: center;
    margin: 1.1rem 0 0;
}

.product-search-card__stock {
    display: flex;
    justify-content: center;
    margin: 1.1rem 0 0;
}
