.gallery-thumbnail-list {
    margin: 0;
    padding: 0;

    overflow: hidden;
}

.gallery-thumbnail-list__items {
    --thumbs-gap: .6rem;
    display: flex;
    gap: var(--thumbs-gap);
    margin: 0;
    padding: 0;

    list-style: none;

    transition: transform .3s ease;
}

.gallery-thumbnail-list__items__item {
    width: 9.7rem;
    height: 9.7rem;
    box-sizing: border-box;
    padding: .5rem;
    flex-shrink: 0;

    border: .1rem solid var(--color-grey-5);
    border-radius: .5rem;
    background-color: var(--color-white);

    transition: border-color .2s ease;

    position: relative;

    &:hover {
        cursor: pointer;
    }

    .gallery-thumbnail-list.-fit-items & {
        --items-shown: 5;
        --gap-sum: calc(var(--thumbs-gap) * (var(--items-shown) - 1));
        width: calc((100% - var(--gap-sum)) / var(--items-shown));
    }

    &.-active:not(.-overflow-info) {
        border-color: var(--color-red-8);
    }

    &.-overflow-info::after {
        display: flex;
        align-items: center;
        justify-content: center;
        content: '+' attr(data-remaining);

        border-radius: .5rem;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        // var(--color-gray-cool-1)
        background-color: rgba(33, 41, 52, .7);

        font-family: var(--font-belleza);
        font-weight: 700;
        font-size: 1.5rem;
        color: var(--color-white);
    }
}

@media (--screen-from-sm) {
    .gallery-thumbnail-list {
        max-height: 60rem;
    }

    .gallery-thumbnail-list__items {
        --thumbs-gap: .8rem;

        .gallery-base[data-type-id="main"] & {
            flex-direction: column;
            width: 6.7rem;
        }
    }

    .gallery-thumbnail-list__items__item {
        &.-overflow-info::after {
            font-size: 1.8rem;
        }

        .gallery-thumbnail-list.-fit-items & {
            --items-shown: 6;
            height: calc((60rem - var(--gap-sum)) / var(--items-shown));
            width: auto;
        }
    }
}
