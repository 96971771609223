.packages-list-layout {
    margin: 0;
    padding: 0;

    list-style: none;
}

.packages-list-layout__item {
    margin: 0 0 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}
