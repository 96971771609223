.filter-layout-options {
    display: flex;
    gap: 1rem;
    position: relative;
}

.filter-layout-options__item {
    display: block;
    cursor: pointer;

    &:hover {
        > .filter-layout-options__text {
            display: block;
        }
    }
}

.filter-layout-options__text {
    font-size: 1.1rem;
    line-height: 1.7rem;
    color: var(--color-white);
    background-color: var(--color-red-1);
    padding: 0 .8rem .2rem;
    border-radius: .3rem;
    position: absolute;
    width: max-content;
    top: -2.1rem;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    &:after {
        content: "";
        position: absolute;
        bottom: -.6rem;
        border-top: solid .9rem var(--color-red-1);
        border-left: solid .5rem transparent;
        border-right: solid .5rem transparent;
        left: 1.05rem;
    }

    .filter-layout-options__item:nth-child(2) &:after {
        left: 3.95rem;
    }

    .filter-layout-options__item:nth-child(3) &:after {
        left: 7.3rem;
    }
}

.filter-layout-options__input {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    &:checked ~ .filter-layout-options__icon {
        color: var(--color-blue-1);
    }
}

.filter-layout-options__icon {
    display: block;
    height: 1.6rem;
    width: 2rem;
}
