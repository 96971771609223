.filter__fieldset {
    margin: 0;
    padding: 0;
}

@media (--screen-from-md) {
    .filter__fieldset {
        margin: 0 0 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
