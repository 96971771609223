.product-information {
    max-width: calc(var(--default-content-width) + (var(--side-padding) * 2));
    margin: auto;
    padding: 6.2rem var(--side-padding);
}

.product-information__toggle-bar {
    border-bottom: .1rem solid var(--color-grey-5);

    &:first-child {
        border-top: .1rem solid var(--color-grey-5);
    }
}

.product-information__title {
    font-size: 2.4rem;
    list-style: none;
    cursor: pointer;
    padding: 1.9rem 0;
    color: var(--color-green-1);

    &::-webkit-details-marker {
        display: none;
    }
}

.product-information__title-inner {
    display: inline-block;
    padding-right: 3rem;
    position: relative;

    svg {
        width: 1.8rem;
        height: 2.1rem;
        color: var(--color-black);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 200ms ease-in-out;

        [open] & {
            transform: translateY(-50%) rotate(180deg);
        }
    }
}

.product-information__content {
    border: .1rem solid var(--color-white);
    margin-bottom: 1.9rem;
    border-radius: .4rem;
}

.product-information__other {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1.5rem 0;
}

.product-information__other-item {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.product-information__temperature-info, .product-information__print {
    font-size: 1.4rem;
    color: var(--color-black);
    text-decoration: none;

    span {
        text-decoration: underline;
    }

    svg {
        color: var(--color-red-8);
        vertical-align: text-bottom;
    }

    &:hover, &:focus, &:active {
        span {
            text-decoration: none;
        }
    }
}

.product-specifications {
    width: 100%;
    border-collapse: collapse;

    tr {
        &:not(:last-child) {
            border-bottom: .1rem solid var(--color-red-7);
        }

        > td {
            width: 50%;
            background-color: var(--color-white);
            padding: 1.6rem 1.8rem;
        }

        &:nth-child(2n) > td {
            background-color: transparent;
        }
    }
}

.product-specifications__note {
    padding: 1.5rem;
}

@media (--screen-from-sm) {
    .product-information__other {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .product-information__other-item {
        margin: 0 2rem 0 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

@media (--screen-from-lg) {
    .product-information {
        padding: 6.2rem 0;
    }
}
