.product-card-pricing {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.product-card-pricing__price {
    font-size: 2.1rem;
    line-height: 2.1rem;
    font-weight: 500;
    color: var(--color-red-3);
    white-space: nowrap;

    .product-card-pricing.-grey-3 & {
        color: var(--color-grey-3);
    }
}

.product-card-pricing__unit {
    font-size: 1.7rem;
    font-weight: 300;
    color: var(--color-grey-3);
    vertical-align: bottom;
    margin-left: .5rem;
}

.product-card-pricing__discount {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 400;
    color: var(--color-red);
}

.product-card-pricing__original-price {
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--color-grey-4);
    vertical-align: bottom;
    text-decoration: line-through;
}

.product-card-pricing__subtitle {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 400;
    margin: .5rem 0 0;
    color: var(--color-white);
    background-color: var(--color-red-4);
    padding: .4rem .8rem .5rem;
    border-radius: 1.1rem;

    &.-alt {
        font-weight: 500;
        color: var(--color-red-6);
        background-color: var(--color-red-7);
    }
}

.product-card-pricing__on-request {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-red-3);
}

@media (--screen-from-sm) {
    .product-card-pricing {
        &.-extended {
            align-items: flex-end;
        }
    }
}

