.billing-address-selector-option {
    font-style: normal;
    font-size: 1.4rem;
    font-weight: var(--font-weight-light);
    line-height: 1.5;
    color: var(--default-text-color);

    b {
        font-size: 1.6rem;
    }
}

.billing-address-selector-option__item-edit {
    margin-left: 1rem;

    color: var(--color-black);

    svg {
        width: 1.2rem;
        height: 1.2rem;
    }
}
