.food-recommendations {
    background: var(--color-white) url("./images/background.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left 50% top 0;
    padding: 6rem 2rem;
}

.food-recommendations__inner {
    max-width: 140rem;
    margin: auto;
}

.food-recommendations__text {
    font-size: 1.8rem;
    line-height: 3rem;

    > h2 {
        color: var(--color-red-1);
        font-size: 4rem;
        line-height: 5rem;
        margin: 0 0 2.3rem;
    }

    > p {
        clear: left;
    }
}

.food-recommendations__bottle-image {
    max-height: 25rem;
    float: left;
    width: auto;
    margin: 0 20px 20px 0;
}

.food-recommendations__dishes {
    clear: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem 3rem;
    justify-content: center;
    margin-top: 3.8rem;
}

.dish {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: .6rem;
}

.dish__icon-wrapper {
    display: flex;
    width: 9rem;
    height: 9rem;
    background-color: var(--color-red-1);
    color: var(--color-grey-8);
    border-radius: 9rem;
}

.dish__icon {
    object-fit: contain;
    width: 3rem;
    height: 3rem;
    margin: auto;
}

.dish__text {
    font-size: 1.3rem;
    line-height: 1.3;
    color: var(--color-red-3);
    text-transform: uppercase;
}

@media (--screen-from-sm) {
    .food-recommendations__bottle-image {
        max-height: 35rem;
    }

    .food-recommendations__dishes {
        grid-template-columns: repeat(4, 12.2rem);
        gap: 4rem 1.5rem;
    }
    .food-recommendations__text {
        > p {
            clear: none;
        }
    }
}

@media (--screen-from-md) {
    .food-recommendations {
        background-position: left 0 top 0;
    }

    .food-recommendations__inner {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        justify-content: center;
    }

    .food-recommendations__text {
        flex: 0 1 56.3rem;
        order: 3;
        margin-top: 8rem;
    }

    .food-recommendations__bottle-image {
        float: none;
        margin: 0;
        max-width: 16.8rem;
        max-height: none;
        flex: 0 0 16.8rem;
        order: 2;
    }

    .food-recommendations__dishes {
        grid-template-columns: repeat(2, 12.8rem);
        justify-content: end;
        gap: 4rem 3rem;
        flex: 0 1 44.8rem;
        order: 1;
        margin-top: 6rem;
        max-width: 45rem;

        &.-col-1 {
            grid-template-columns: 12.8rem;
        }
    }
}

@media (--screen-from-lg) {
    .food-recommendations__inner {
        gap: 5rem;
    }

    .food-recommendations__dishes {
        grid-template-columns: repeat(3, 12.8rem);

        &.-col-2 {
            grid-template-columns: repeat(2, 12.8rem);
        }
    }
}

@media (--screen-from-xl) {
    .food-recommendations__text {
        > h2 {
            width: 50%;
        }
    }
}
