form {
    width: 100%;
    accent-color: var(--color-blue-2);

    --column-gap: 1rem;
}

.form__row {
    display: flex;
    flex-direction: column;
    column-gap: var(--column-gap);
    width: 100%;
}

@media (--screen-from-sm) {
    .form__row {
        flex-direction: row;

        > * {
            flex-basis: 30rem;
        }

        &.-two > * {
            flex: 0 0 calc(50% - (var(--column-gap) / 2));
        }
    }
}
