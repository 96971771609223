.customer-rating__star {
    --star-full: var(--color-red-3);
    --star-empty: var(--color-red-2);

    &.-full {
        color: var(--star-full);
    }
    &.-half, &.-half .svg-icon > * {
        //TODO: use fontawesome twotone icon
    }
    &.-empty {
        color: var(--star-empty);
    }
}
