.cart-create-account {
}

.cart-create-account__title {
    margin-top: 0;

    color: var(--color-green-1);

    b {
        color: var(--color-green-3);
        font-style: normal;
        font-weight: var(--font-weight-normal);
    }
}

.cart-create-account__usps {
    margin: 0;
    padding: 0;

    list-style: none;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--color-green-1);
}

.cart-create-account__usp {
    margin: 0 0 .3rem;
    padding: 0 0 0 2.5rem;

    position: relative;

    svg {
        position: absolute;
        left: 0;
        top: .35rem;

        width: 1.5rem;
        height: 1.5rem;

        color: var(--color-green-3);
    }

    b {
        font-weight: var(--font-weight-medium);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cart-create-account__actions {
    margin-top: 3rem;
}
