.product-guarantees {
    font-size: 1.5rem;
    line-height: 1.955rem;
    font-weight: 400;
    text-transform: capitalize;
    border: 0 solid var(--color-grey-5);
    border-top-width: .1rem;
}

.product-guarantees__list {
    padding: 0;
}

.product-guarantees__list-item {
    margin: 1.6rem 0;
    padding: 0 0 0 2.9rem;
    color: var(--color-green-1);
    list-style: none;
    position: relative;

    > b {
        font-weight: inherit;
        color: var(--color-green-3);
    }
}

.product-guarantees__list-item-marker {
    width: 1.3rem;
    height: 1.3rem;
    color: var(--color-green-3);

    position: absolute;
    left: 1rem;
    top: .125rem;
}

@media (--screen-from-md) {
    .product-guarantees {
        font-size: 1.6rem;
        font-weight: 300;
    }
}
