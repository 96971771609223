.account-address-list-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    height: 100%;
    width: 100%;

    color: var(--color-orange-1);

    border: 1px solid var(--color-grey-9);
    text-decoration: none;

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}

.account-address-list-add__icon {
    padding: 1.3rem;
    background-color: var(--color-red-7);
    border-radius: .4rem;

    svg {
        display: block;
        width: 1.7rem;
        height: 1.7rem;
    }
}

.account-address-list-add__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    font-weight: var(--font-weight-medium);
}
