@import '../../../../atoms/button/_reset';

.cart-popup {
    display: none;
    padding: 2rem;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(#46534A, .7);
    z-index: var(--z-index-modal);

    &.-open {
        display: flex;
    }
}

.cart-popup__body {
    max-height: 100%;
    margin: auto;

    overflow-y: auto;

    background-color: var(--color-white);
    border-radius: 1rem;
}

.cart-popup__header {
    padding: 2rem 5.5rem 2rem 2rem;

    position: relative;

    border-bottom: 1px solid var(--color-grey-5);
}

.cart-popup__title {
    margin: 0;

    color: var(--color-red-1);

    font-family: var(--font-wotfard);
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
    line-height: 3rem;
}

.cart-popup__close {
    @extend %button-reset;

    position: absolute;
    right: 2rem;
    top: 2.75rem;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        color: var(--color-grey-4);
    }
}

.cart-popup__content {
    padding: 2rem;
}

.cart-popup__footer {
    padding: 0 2rem 2rem;
}
