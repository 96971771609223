.cart-popup-buttons {
    display: flex;
    gap: 5rem;
    justify-content: flex-start;
    align-items: center;

    > [type="submit"] {
        margin-left: auto;
    }
}
