textarea {
    padding: 1.6rem 1.3rem;
    width: 100%;
    min-height: 13.7rem;
    box-sizing: border-box;

    appearance: none;
    border: 1px solid var(--color-grey-6);
    border-radius: .3rem;
    font-size: 1.6rem;
    line-height: 1.4;
}
