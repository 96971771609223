.alert {
    padding: 1rem 3rem 1rem 2rem;
    margin: 0 0 2rem;

    position: relative;

    color: var(--color-white);
    background-color: var(--color-red-1);

    &.-error {
        background-color: var(--color-red);
    }

    &.-success {
        background-color: var(--color-green-3);
    }

    &.-warning {
        background-color: var(--color-orange-1);
    }
}

.alert__close {
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;

    color: var(--color-white);

    svg {
        width: 1rem;
        height: 1rem;
    }
}

.alert__message {
    margin: 0;
    padding: 0;
}
