.gallery-item-video {
    width: 100%;
    padding: 2%;
    max-width: 59rem;
    margin: auto;
    box-sizing: border-box;

    &.-lightbox {
        height: 100%;
        max-width: 100%;
    }
}

.gallery-item-video__container {
    width: 100%;
    height: 100%;
}

.gallery-item-video__inner {
    margin: auto;
    width: 100%;
    height: 100%;
}

.gallery-item-video__ratio {
    height: 0;
    padding-bottom: (9 / 16) * 100%;

    position: relative;

    cursor: pointer;
}

.gallery-item-video__placeholder {
    object-fit: cover;
}

.gallery-item-video__iframe, .gallery-item-video__placeholder {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
}

.gallery-item-video__iframe {
    display: none;

    .gallery-item-video.-player-loaded & {
        display: block;
    }
}

.gallery-item-video__placeholder {
    .gallery-item-video.-player-loaded & {
        visibility: hidden;
    }
}

.gallery-item-video__icon {
    display: block;
    width: 5rem;
    height: 5rem;
    content: '';

    //border-radius: 50%;
    color: var(--color-white);

    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    transition: background-color .2s ease;

    svg {
        display: block;
        width: 3rem;
        height: 3rem;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-44%, -47%);

        color: var(--color-blue-light-1);
    }

    .gallery-item-video.-player-loaded & {
        display: none;
    }

    .gallery-item-video__inner:hover &, .gallery-item-video__inner:focus &, .gallery-item-video__inner:active & {
        background-color: var(--color-blue-light-4);
    }
}

.gallery-item-video__not_permitted {
    text-align: center;
    overflow: visible;
    position: absolute;
    padding: 15px;
    background-color: var(--color-red-2);
    color: var(--color-red-1);
    /* margin: 75px; */
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 15px;
    bottom: 15%;
    font-size: 15px;
}

div[data-permitted-container] {
    display: none;
}

div[data-blocked-container] {
    display: none;
}
