.gallery-thumbnail-image {
    position: relative;
    height: 100%;
    width: 100%;
}

.gallery-thumbnail-image__image {
    display: block;
    margin: auto;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
