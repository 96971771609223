.product-reviews {
    color: var(--color-white);
    background-color: var(--color-green-1);
    padding: 6rem 1.5rem;
    background-image: url("image/background.svg");
    background-repeat: no-repeat;
    background-size: auto 87.2rem;
    background-position: top 10rem left 6.5rem;
}

.product-reviews__professionals-header {
    padding: 0 .5rem;
}

.professionals-header__text {
    grid-area: professional-header-text;
}

.professionals-header__text-title {
    font-size: 4rem;
    margin: 0 0 2.3rem;
}

.professionals-header__text-paragraph {
    font-size: 1.8rem;
    color: var(--color-green-2);

    > a {
        --anchor-theme: var(--color-green-2);
    }
}

.professionals-header__badges {
    --row-gap: 1rem;
    --col-gap: 2rem;
    grid-area: professional-header-badges;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: var(--row-gap) var(--col-gap);
    margin-top: 7rem;

    > * {
        text-align: center;
    }
}

.professional-badge {
    width: 8.4rem;
    height: 8.4rem;
    background-color: var(--color-black);
    border-radius: 14rem;
    margin: 0 auto 1.5rem;
}

.professional-badge__score {
    color: var(--color-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.professional-badge__score-top {
    font-size: 3.3rem;
    font-weight: 400;
    line-height: 2.3rem;
    margin-bottom: 0.6rem;
    letter-spacing: -0.25rem;
    margin-left: -0.25rem;
}

.professional-badge__score-bottom {
    font-size: 2.3rem;
    line-height: 1.8rem;
    border-top: .1rem solid var(--color-white);
    padding-top: 0.3rem;
}

.professional-logo {
    width: 100%;
    max-width: 12rem;
}

.product-reviews__customer-header {
    text-align: center;
}

.customer-header__title {
    font-size: 4rem;
    margin: 0 0 2.3rem;
}

.customer-header__subtitle {
    font-size: 1.8rem;
    color: var(--color-green-2);
}

.product-reviews__reviews {
    margin-top: 4rem;
}

.review-section__desktop-trigger {
    cursor: pointer;
    padding: .9rem 2.2rem;
    border-top: .4rem solid transparent;

    &.-active-section {
        color: var(--color-green-1);
        background-color: var(--color-white);
        border-top-color: var(--color-green-2);

        > b {
            font-weight: 300;
        }
    }
}

.review-section__desktop-tabs {
    display: none;
}

.review-section__mobile-trigger {
    font-size: 1.8rem;
    width: 100%;
    background-color: var(--color-green-2);
    color: var(--color-green-1);
    padding: 1.5rem 2rem;
    border-top: .4rem solid transparent;

    > b {
        font-weight: 500;
    }

    &.-active-section {
        color: var(--color-red-3);
        background-color: var(--color-white);
        border-top-color: var(--color-red-3);

        > .review-section__chevron {
            color: var(--color-green-1);
            transform: rotate(180deg);
        }
    }
}

.review-section__chevron {
    font-size: 1.9rem;
    margin-left: 2rem;
    transition: transform 300ms linear;
}

.review-section__content {
    color: var(--color-black);
    background-color: var(--color-grey-2);
    max-height: 200rem;
    overflow: auto;
    padding: 3rem 1rem 4rem;
    box-shadow: 0 1.2rem .9rem 1rem rgb(65 88 65 / 19%), inset 0 0 .5rem 0 rgb(0 0 0 / 9%);

    &.-hidden {
        max-height: 0;
        padding: 0;
        overflow: hidden;
    }

    &.-is-animating {
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
        transition-property: max-height, padding-top, padding-bottom;
    }

    > .review {
        padding: 2rem;
    }
}

.review {
    &:not(:last-child) {
        border-bottom: .1rem solid var(--color-grey-5);
    }

    > .customer-rating__star {
        font-size: 1.8rem;
    }
}

.professional-review__badge {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-around;

    .professional-badge {
        width: 6.4rem;
        height: 6.4rem;
        margin: 0;
    }

    .professional-badge__score-top {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 2.3rem;
        margin-bottom: 0.1rem;
    }

    .professional-badge__score-bottom {
        font-size: 1.8rem;
        line-height: 1.8rem;
        border-top: .1rem solid var(--color-white);
        padding: 0 .4rem;
    }
}

.review__title {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-family: var(--font-wotfard);
    margin: 0 0 1rem;
    font-weight: 300;
}

.review__rating {
    margin: 1rem 0
}

.review__content {
    font-size: 1.6rem;
    line-height: 3rem;
    color: var(--color-grey-3);
    margin: 0 0 .8rem;
}

.review__info {
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--color-grey-4);
}

.review-section__review-button {
    display: block;
    padding: 1.5rem 4rem;
    margin: 3rem auto 0;

    &.-icon {
        padding: 1.5rem 3rem;
    }
}

.review-section__customer-title {
    text-align: center;

    > .customer-rating__star {
        font-size: 1.8rem;
    }
}

.review-section__title-no-reviews {
    font-size: 2.4rem;
    line-height: 4rem;
    color: var(--color-green-1);
    margin-bottom: 1.3rem;
}

.review-dialog {
    position: fixed;
    width: 100%;
    max-width: 80rem;
    height: 100%;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0 0 0 auto;
    padding: 0;
    z-index: 9002;
    overflow-y: auto;
}

dialog[open] + .review-dialog__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9001;
    background: rgba(#46534A, .7); // var(--color-green-1)
    backdrop-filter: blur(2rem);
}

.review-dialog__header {
    display: flex;
    background-color: var(--color-red-1);
    color: var(--color-red-7);
    padding: 2rem;

    > h1 {
        font-size: 2.4rem;
        line-height: 1;
        text-transform: uppercase;
        flex: 1;
        margin: 0;
    }
}

.review-dialog__close {
    cursor: pointer;

    svg {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.review-dialog__subject-product {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    padding: 1rem 1.5rem;

    > img {
        max-width: 4.5rem;
    }
}

.review-dialog__subject-info {
    font-size: 1.9rem;
    color: var(--color-grey-3);
    display: flex;
    flex-direction: column;
}

.review-dialog__subject-title {
    font-weight: 500;
}

.review-dialog__separator {
    border-top: 0;
    border-color: var(--color-red-2);
    margin: 4rem 0 2.5rem;
}

.review-dialog__form-container {
    padding: 0 1.5rem 1.5rem;

    .g-recaptcha {
        transform: scale(0.95);
        transform-origin: 0 0;

        iframe[title="reCAPTCHA"] {
            border: none;
            width: 100%;
        }
    }

    button[type="submit"] {
        --theme: var(--color-red-3);

        &:hover {
            --theme: var(--color-red-8);
        }
    }
}

@media (--screen-from-sm) {
    .review.-professional {
        display: flex;
        gap: 1rem;

        .professional-review__badge {
            flex: 0 0 15rem;
        }
    }

    .professionals-header__badges {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .review-section__content {
        padding: 4rem;
    }

    .review-section__review-button {
        &.-bottom {
            margin: 3rem 0 0;
        }
    }
}

@media (--screen-from-md) {
    .product-reviews__professionals-header {
        max-width: 140rem;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "professional-header-badges professional-header-text";
    }

    .review.-professional {
        .professional-review__badge {
            flex: 0 0 20rem;
        }
    }

    .professionals-header__text-title {
        width: 50%;
        font-size: 5rem;
        line-height: 6rem;
    }

    .professionals-header__badges {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0;

        > * {
            flex: 0 0 calc(50% - (var(--col-gap) / 2));
        }
    }
}

@media (--screen-from-lg) {
    .product-reviews {
        background-size: auto;
        background-position: top 41rem left 20rem;
        padding: 10rem 1.5rem 26rem;
    }

    .product-reviews__reviews {
        padding-top: 11rem;
    }

    .professionals-header__badges {
        padding-right: 6.5rem;

        > * {
            flex: 0 0 calc(25% - (3 * var(--col-gap) / 4));
        }
    }

    .professionals-header__text-title {
        font-size: 6.5rem;
        line-height: 7rem;
    }

    .customer-header__title {
        font-size: 6.5rem;
        line-height: 7rem;
    }

    .review-section {
        max-width: 94rem;
        margin: auto;
    }

    .review-section__mobile-trigger {
        display: none;
    }

    .review-section__desktop-tabs {
        display: flex;
        justify-content: center;
    }

    .review-section__content {
        border-radius: .6rem;
    }
}

// One-time media query for background positioning (1800 = background image width (1600) + left margin (200))
@media screen and (min-width: 1800px) {
    .product-reviews {
        background-position: top 41rem right 0;
    }
}
