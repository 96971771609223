@media (--screen-from-sm) {
    .account-layout {
        display: flex;
        column-gap: 4rem;
    }

    .account-layout__navigation {
        flex: 0 0 20rem;
    }

    .account-layout__content {
        flex: 1 1 auto;
    }
}
